import { connect } from 'react-redux';
import searchResultTable from '../../components/searchResultTable';
import RestrictionRow from './restrictionRow';

function mapStateToProps({ searchRestrictions }) {
  return { rowObjects: searchRestrictions.restrictions };
}

export default connect(mapStateToProps, null)(searchResultTable(
  ['commonDescription', 'commonCode', 'restrictionAllowAllocation'],
  RestrictionRow,
));
