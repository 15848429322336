import _ from 'lodash';
import { FETCH_CHANGE_REASON, SAVE_CHANGE_REASON } from '../constants';

export default function fetchChangeReason(state = [], action) {
  if (action.error) {
    return state;
  }
  switch (action.type) {
    case FETCH_CHANGE_REASON:
      return action.payload.data;

    case SAVE_CHANGE_REASON: {
      const newState = _.concat([], state);
      const index = _.findIndex(
        state,
        l => l.id === action.payload.savedObject.id,
      );
      if (index > -1) {
        newState[index] = action.payload.savedObject;
      }
      return newState;
    }

    default:
      return state;
  }
}
