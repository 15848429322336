import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types';
import LocationDistanceRow from './locationDistanceRow';
import { LOCATION_DISTANCE_SCREEN_EDIT } from '../../kc_roles';
import { hasLicence } from '../../components/licenced_components';
import '../location.css';
import messages from '../../messages';

class LocationTable extends Component {
  constructor(props) {
    super(props);
    this.render = this.render.bind(this);
  }

  renderItem() {
    return _.map(this.props.locationDistance, item => (
      <LocationDistanceRow
        key={item.id}
        locationDistance={item}
      />
    ));
  }

  render() {
    return (
      <div>
        <table
          id={hasLicence(LOCATION_DISTANCE_SCREEN_EDIT) ? 'clickable' : ''}
          className="compactFirstColumn table text-nowrap locDistHead"
        >
          <thead>
            <tr>
              <th>{messages.commonFrom}</th>
              <th>{messages.commonTo}</th>
              <th>{messages.commonVia}</th>
              <th>{messages.commonDistance}</th>
            </tr>
          </thead>
          <tbody>{this.renderItem()}</tbody>
        </table>
      </div>
    );
  }
}

LocationTable.propTypes = {
  locationDistance: PropTypes.arrayOf(PropTypes.shape({})),
};
LocationTable.defaultProps = {
  locationDistance: [],
};

function mapStateToProps({ locationDistance }) {
  return { locationDistance };
}

export default connect(mapStateToProps)(LocationTable);
