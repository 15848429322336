import React from 'react';
import FilterPanel from './components/filterPanel';
import LocationsTable from './components/locationsTable';
import { LOCATIONS_SCREEN_EDIT } from '../kc_roles';
import messages from '../messages';

const Locations = () => (
  <div>
    <div className="col-xs-12 filtersColumn">
      <div className="table-responsive">
        <FilterPanel
          addPopuptitle={messages.locationAddPopupTitle}
          addPopupformModal={{ fillColour: '#FFFFFF', textColour: '#000000' }}
          editLicence={LOCATIONS_SCREEN_EDIT}
        />
      </div>
    </div>
    <div className="col-xs-12 location">
      <div className="table-responsive">
        <LocationsTable
          editPopuptitle={messages.locationEditPopupTitle}
          editLicence={LOCATIONS_SCREEN_EDIT}
          extraTableCss="compactFirstColumn"
        />
      </div>
    </div>
  </div>
);

export default Locations;
