import React from 'react';
import ChangeReasonTable from './components/changeReasonTable';
import Filters from './components/filters';
import './changeReason.css';

class ChangeReason extends React.Component {
  constructor(props) {
    super(props);

    this.render = this.render.bind(this);
    this.handleHide = this.handleHide.bind(this);
    this.handleClick = this.handleClick.bind(this);

    this.state = {
      show: false,
    };
  }

  handleClick() {
    if (!this.state.show) {
      this.setState({ show: true });
    } else {
      this.handleHide();
    }
  }

  handleHide() {
    this.setState({ show: false });
  }
  render() {
    return (
      <div>
        <div className="col-xs-12 filter">
          <div className="table-responsive">
            <Filters
              show={this.state.show}
              handleHide={this.handleHide}
              handleClick={this.handleClick}
            />
          </div>
        </div>
        <div className="col-xs-12 location">
          <div className="table-responsive">
            <ChangeReasonTable />
          </div>
        </div>
      </div>
    );
  }
}

export default ChangeReason;
