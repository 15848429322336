import { GetWithToken } from '../../utils/ajax';
import { ROOT_URL, FETCH_REASON } from '../constants';

export default function fetchReason(reason, token) {
  const response = GetWithToken(`${ROOT_URL}/reason/reasonname/${reason}`, token);
  return {
    type: FETCH_REASON,
    payload: response,
  };
}

