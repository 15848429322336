import { FETCH_CLIENT_SETTINGS } from '../constants';

export default function clientSettings(state = {}, action) {
  if (action.error) {
    return state;
  }
  switch (action.type) {
    case FETCH_CLIENT_SETTINGS:
      return action.payload.data;
    default:
      return state;
  }
}
