import React from 'react';
import { connect } from 'react-redux';
import LocationModal from './locationModal';
import { refreshSearch } from '../actions/searchLocations';
import deleteLocation from '../actions/deleteLocation';
import restoreLocation from '../actions/restoreLocation';
import searchResultTableRow from '../../components/searchResultTableRow';
import { TrimbleButton } from '../../components/FormComponents';
import messages from '../../messages';

const BaseTableRow = searchResultTableRow(
  [
    { propertyName: 'description' },
    { propertyName: 'code' },
    { propertyName: 'tiploc' },
    { propertyName: 'intervention' },
    { propertyName: 'deleted', checkEditLicence: true },
  ],
  LocationModal,
);

class LocationRow extends BaseTableRow {
  onDelete(e) {
    this.props.deleteLocation(this.props.rowObject, () => this.props.refreshSearch());
    e.stopPropagation();
  }

  onRestore(e) {
    this.props.restoreLocation(this.props.rowObject, () => this.props.refreshSearch());
    e.stopPropagation();
  }

  getRowStyle() {
    const location = this.props.rowObject;
    return {
      color: location.textColour,
      backgroundColor: location.fillColour,
    };
  }

  isEditable() {
    return !this.props.rowObject.deleted && super.isEditable();
  }

  renderColumnValue(config, index, rowObject) {
    if (config.propertyName === 'intervention') {
      return rowObject.intervention ? messages.commonYes : messages.commonNo;
    } else if (config.propertyName === 'deleted') {
      if (rowObject.deleted) {
        return (
          <TrimbleButton label={messages.commonRestore} onClick={e => this.onRestore(e)} />
        );
      }
      return (
        <TrimbleButton label={messages.commonDelete} onClick={e => this.onDelete(e)} />
      );
    }
    return rowObject[config.propertyName];
  }
}

export default connect(
  null,
  {
    onAfterEdit: refreshSearch, deleteLocation, refreshSearch, restoreLocation,
  },
)(LocationRow);
