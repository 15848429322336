import messages from '../messages';

export function validateMandatoryField(field, name, maxLength, errors = []) {
  if (!field) {
    errors.push(messages.formatString(messages.commonValidationFieldMandatory, name));
  } else if (maxLength && field.length > maxLength) {
    errors.push(messages.formatString(messages.commonValidationFieldMaxLength, name, maxLength));
  }
}

export function validateMandatoryFields(obj = {}, mandatoryFields = {}, errors = []) {
  Object.keys(mandatoryFields).forEach((key) => {
    validateMandatoryField(
      obj[key],
      mandatoryFields[key].name,
      mandatoryFields[key].maxlength,
      errors,
    );
  });
}
