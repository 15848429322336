import React, { Component } from 'react';
import 'react-bootstrap-modal/lib/css/rbm-patch.css';
import PropTypes from 'prop-types';
import DistanceModal from './distanceModal';
import { LOCATION_DISTANCE_SCREEN_EDIT } from '../../kc_roles';
import { hasLicence } from '../../components/licenced_components';
import '../location.css';

class LocationDistanceRow extends Component {
  static clickSearch() {
    document.getElementById('locationSearchMagnifier').click();
  }

  constructor(props) {
    super(props);

    this.handleHide = this.handleHide.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.render = this.render.bind(this);

    this.state = {
      show: false,
    };
  }

  handleClick() {
    if (!this.state.show) {
      this.setState({ show: true });
    } else {
      this.handleHide();
    }
  }

  handleHide() {
    this.setState({ show: false });
  }

  render() {
    const { locationDistance } = this.props;
    return (
      <tr
        key={locationDistance.id}
        id={locationDistance.id}
        className="locationDistance"
      >
        <td className="clickable" role="gridcell" onClick={this.handleClick} onKeyDown={this.handleClick}> {locationDistance.fromLocation}</td>
        <td className="clickable" role="gridcell" onClick={this.handleClick} onKeyDown={this.handleClick}> {locationDistance.toLocation}</td>
        <td className="clickable" role="gridcell" onClick={this.handleClick} onKeyDown={this.handleClick}> {locationDistance.viaLocation}</td>
        <td className="clickable" role="gridcell" onClick={this.handleClick} onKeyDown={this.handleClick}> {locationDistance.value}</td>
        {hasLicence(LOCATION_DISTANCE_SCREEN_EDIT) &&
          <DistanceModal
            locationDistance={locationDistance}
            show={this.state.show}
            save={false}
            handleHide={this.handleHide}
            onAfterSave={LocationDistanceRow.clickSearch}
          />
        }
      </tr>
    );
  }
}

LocationDistanceRow.propTypes = {
  locationDistance: PropTypes.shape({
    id: PropTypes.number,
    fromLocation: PropTypes.string,
    toLocation: PropTypes.string,
    viaLocation: PropTypes.string,
    value: PropTypes.number,
  }),
};
LocationDistanceRow.defaultProps = {
  locationDistance: null,
};

export default LocationDistanceRow;
