import { hasLicence } from '../components/licenced_components';
import { ALLOCATION_SCREEN_EDIT, ALLOCATION_SCREEN_UNIT_DETAILS_EDIT } from '../kc_roles';

export function isEditingAllowed() {
  return hasLicence(ALLOCATION_SCREEN_EDIT);
}

export function isNoteEditingAllowed() {
  return hasLicence(ALLOCATION_SCREEN_UNIT_DETAILS_EDIT);
}

export function isRestrictionEditingAllowed() {
  return hasLicence(ALLOCATION_SCREEN_UNIT_DETAILS_EDIT);
}

export default function getDiagramKey(diagram) {
  return `${diagram.type}-${diagram.diagramId}`;
}

