import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FuelLevelMovementModal from './fuelLevelMovementModal';
import { ROOT_URL } from '../constants';
import { Get } from '../../utils/ajax';

class EodFuel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showFuelLevelMovements: false,
      eodFuelLevelInfo: {},
    };
  }

  showMovementModal(show) {
    if (show) {
      Get(`${ROOT_URL}/asset/${this.props.asset.externalId}/${this.props.asset.code}/eodFuelLevelDetails`)
        .then((response) => {
          this.setState({
            showFuelLevelMovements: show,
            eodFuelLevelInfo: response.data,
          });
        });
    } else {
      this.setState({ showFuelLevelMovements: show });
    }
  }

  render() {
    return (
      <span>
        <span
          role="button"
          className="clickableAsset"
          onClick={() => this.showMovementModal(true)}
          onKeyDown={() => this.showMovementModal(true)}
          tabIndex={0}
        >
          {this.props.asset.displayEodFuel}
        </span>
        <FuelLevelMovementModal
          show={this.state.showFuelLevelMovements}
          asset={this.props.asset}
          eodFuelLevelInfo={this.state.eodFuelLevelInfo}
          handleHide={() => this.showMovementModal(false)}
        />
      </span>
    );
  }
}

EodFuel.propTypes = {
  asset: PropTypes.shape({
    externalId: PropTypes.number,
    code: PropTypes.string,
    displayEodFuel: PropTypes.number,
  }),
};
EodFuel.defaultProps = {
  asset: null,
};

export default EodFuel;
