import { ROOT_URL as BASE_ROOT_URL } from '../constants';

export const ROOT_URL = BASE_ROOT_URL;

export const FETCH_LOCATION = 'FETCH_LOCATION';
export const FETCH_DISTANCE = 'FETCH_DISTANCE';
export const FETCH_DISTANCES = 'FETCH_DISTANCES';
export const FETCH_ALL_DISTANCES = 'FETCH_ALL_DISTANCES';
export const CREATE_DISTANCE = 'CREATE_DISTANCE';
export const UPDATE_DISTANCE = 'UPDATE_DISTANCE';

export const MIN_DISTANCE = 0;
export const MAX_DISTANCE = 99999;
