import ROOT_URL from '../constants';
import { Post } from '../../utils/ajax';
import { showInformation, showWarning } from '../../actions/YesCancel';
import fetchCurrentDiagramDate from '../../actions/currentDiagramDate';
import messages from '../../messages';

function performEodValidation(dispatch) {
  Post(
    `${ROOT_URL}/diagram/performEodValidation`,
    {},
    { validateStatus: status => status === 200 || status === 400 },
  ).then((response) => {
    if (response) {
      if (response.status === 200) {
        dispatch(showInformation(messages.eodValidationSuccessMessage));
      } else if (response.status === 400 && response.data) {
        dispatch(showWarning(response.data));
      }
    }
    dispatch(fetchCurrentDiagramDate());
  }).catch(() => dispatch(fetchCurrentDiagramDate()));
}

function doEodValidation() {
  return performEodValidation;
}
export default doEodValidation;
