import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { expandDiagram, sortDiagrams } from '../actions/diagram';
import DiagramRow from './diagramRow';
import DiagramLegRow from './diagramLegRow';
import { makeDnDAware } from '../../utils/drag-n-drop';
import { allocateAsset } from '../actions/allocateAsset';
import { reallocateAsset } from '../actions/reallocateAsset';
import fetchAssetList from '../actions/asset';
import getDiagramKey from '../util';
import renderSortableColumnHeader from './componentUtil';
import messages from '../../messages';
import '../allocation.css';

class AllocationTable extends Component {
  constructor(props) {
    super(props);
    this.render = this.render.bind(this);
    this.columnLabels = [
      'allocationDiagram',
      'allocationDiagramClass',
      'allocationDiagramNoOfVehicles',
      'allocationDiagramStart',
      'allocationDiagramFinish',
      'allocationDiagramIntervention',
      'commonHeadCode',
      'commonUnit',
      'allocationUnitEodFuel',
      'allocationUnitRestrictions',
      'allocationUnitNotes',
    ];
    this.columnSort = [
      'name',
      'class',
      'vehicle_count',
      'start',
      'finish',
      'intervention',
      'headcode',
      null,
      null,
      null,
      null,
    ];
  }

  static renderUnitNamesColumn(unitList) {
    let units = unitList;
    if (!unitList) {
      units = [];
    }
    return units
      .map(unit => unit.code)
      .reduce((names, name) => `${names}${names ? ',' : ''}${name}`, '');
  }

  successCallBack() {
    this.props.onSuccessCallBack(
      this.props.searchText,
      this.props.onlyAvailable,
    );
  }

  renderColumnHeader(labelKey, sortColumn) {
    return renderSortableColumnHeader(
      messages[labelKey],
      sortColumn,
      this.props.diagramFilter.sort,
      sortedColumn => this.props.sortDiagrams(sortedColumn),
    );
  }

  renderItem() {
    return this.props.diagram.map(item => [
      <DiagramRow
        key={getDiagramKey(item)}
        diagram={item}
        onAssetDrop={this.props.onAssetDrop}
        onExpandDiagram={this.props.onExpandDiagram}
        fnaFormationTypes={this.props.fnaFormationTypes}
        onSuccessCallBack={() => this.successCallBack()}
      />,
      <tr
        key={`legContainerRow-${getDiagramKey(item)}`}
        className={`legContainerRow collapse ${item.expanded ? 'in' : ''}`}
      >
        <td colSpan="11">
          <table className="allocationLegs compactColumnSides table text-nowrap">
            <tbody>
              <tr
                className={`diagramLegRow collapse ${
                  item.expanded ? 'in' : ''
                }`}
              >
                <th>{messages.commonLocation}</th>
                <th>{messages.allocationDiagramLegArrival}</th>
                <th>{messages.allocationDiagramLegDeparture}</th>
                <th>{messages.commonHeadCode}</th>
                <th>{messages.allocationDiagramLegPerform}</th>
                <th>{messages.allocationDiagramLegFormation}</th>
                <th className="allocatedAssetColumn">{messages.commonUnit}</th>
                <th>{messages.commonDistance}</th>
                <th>{messages.commonTotalDistance}</th>
              </tr>
              {this.renderItemDetail(item)}
            </tbody>
          </table>
        </td>
      </tr>,
    ]);
  }

  renderItemDetail(diagram) {
    if (!diagram.expanded) {
      return null;
    }
    return diagram.diagramLegs.map(leg => (
      <DiagramLegRow
        key={leg.id}
        diagram={diagram}
        diagramLeg={leg}
        onAssetDrop={this.props.onAssetDrop}
        onAssetReallocation={this.props.onAssetReallocation}
        onSuccessCallBack={() => this.successCallBack()}
      />
    ));
  }

  render() {
    return (
      <div>
        <table
          id="allocation"
          className="compactFirstColumn compactColumnSides table text-nowrap"
        >
          <thead>
            <tr>
              {this.columnLabels.map((v, i) =>
                this.renderColumnHeader(v, this.columnSort[i]))}
            </tr>
          </thead>
          <tbody>{this.renderItem()}</tbody>
        </table>
      </div>
    );
  }
}

AllocationTable.propTypes = {
  diagram: PropTypes.arrayOf(PropTypes.shape({})),
  onAssetDrop: PropTypes.func,
  onAssetReallocation: PropTypes.func,
  onExpandDiagram: PropTypes.func,
  sortDiagrams: PropTypes.func,
  onSuccessCallBack: PropTypes.func,
  fnaFormationTypes: PropTypes.arrayOf(PropTypes.string),
  diagramFilter: PropTypes.shape({
    sort: PropTypes.string,
  }),
  searchText: PropTypes.string,
  onlyAvailable: PropTypes.bool,
};
AllocationTable.defaultProps = {
  diagram: [],
  onAssetDrop: null,
  onAssetReallocation: null,
  onExpandDiagram: null,
  sortDiagrams: null,
  onSuccessCallBack: null,
  fnaFormationTypes: null,
  diagramFilter: {},
  searchText: '',
  onlyAvailable: true,
};

function mapStateToProps(state) {
  return {
    diagram: state.diagram.diagrams,
    diagramFilter: state.diagram.filter,
    fnaFormationTypes: state.clientSettings.fuelNotApplicableFormationTypes,
    onlyAvailable: state.asset.filter.onlyAvailable,
    searchText: state.asset.filter.searchText,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      sortDiagrams,
      onAssetDrop: allocateAsset,
      onAssetReallocation: reallocateAsset,
      onExpandDiagram: expandDiagram,
      onSuccessCallBack: fetchAssetList,
    },
    dispatch,
  );
}

export default makeDnDAware(connect(mapStateToProps, mapDispatchToProps)(AllocationTable));
