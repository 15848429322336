import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import messages from '../../messages';
import '../allocation.css';

class LocationSearch extends Component {
  constructor(props) {
    super(props);
    this.render = this.render.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.locationInput = createRef();
    this.locationButton = createRef();
  }

  handleSearch(event) {
    event.preventDefault();
    const location = this.locationInput.current.value.trim();
    this.props.onSearch(location);
  }

  handleChange(event) {
    let location;
    event.preventDefault();
    location = this.locationInput.current.value.trim();
    if (location === '') {
      location = 'undefined';
      this.locationButton.current.className = 'hiddenButton';
    } else {
      this.locationButton.current.className = 'fa fa-search';
    }
    this.props.onChange(location);
  }

  render() {
    return (
      <div id="locationFilterDiv">
        <form onSubmit={this.handleSearch}>
          <input
            type="text"
            id="locationFilter"
            placeholder={messages.commonLocation}
            ref={this.locationInput}
            onChange={this.handleChange}
          />
          <button
            type="submit"
            id="locationFilterMagnifier"
            ref={this.locationButton}
            className="hiddenButton"
            onClick={this.handleSearch}
          />
        </form>
      </div>
    );
  }
}

LocationSearch.propTypes = {
  onSearch: PropTypes.func,
  onChange: PropTypes.func,
};
LocationSearch.defaultProps = {
  onSearch: null,
  onChange: null,
};

export default LocationSearch;
