import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import messages from '../../messages';
import '../allocation.css';

class QuickSearch extends Component {
  constructor(props) {
    super(props);
    this.render = this.render.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.quickSearch = createRef();
    this.quickSearchButton = createRef();
  }

  handleSearch(event) {
    event.preventDefault();
    const filter = this.quickSearch.current.value.trim();
    this.props.onSearch(filter);
  }

  handleChange(event) {
    event.preventDefault();
    const filter = this.quickSearch.current.value.trim();
    if (filter === '') {
      this.quickSearchButton.current.className = 'hiddenButton';
    } else {
      this.quickSearchButton.current.className = 'fa fa-search';
    }
    this.props.onChange(filter);
  }

  render() {
    return (
      <div id="quickSearchDiv">
        <form onSubmit={this.handleSearch}>
          <input
            type="text"
            id="quickSearchFilter"
            placeholder={messages.commonQuickSearch}
            ref={this.quickSearch}
            onChange={this.handleChange}
          />
          <button
            type="submit"
            id="quickSearchMagnifier"
            ref={this.quickSearchButton}
            className="hiddenButton"
            onClick={this.handleSearch}
          />
        </form>
      </div>
    );
  }
}

QuickSearch.propTypes = {
  onChange: PropTypes.func,
  onSearch: PropTypes.func,
};
QuickSearch.defaultProps = {
  onChange: null,
  onSearch: null,
};

export default QuickSearch;
