import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import PropTypes from 'prop-types';
import 'react-datepicker/dist/react-datepicker.css';
import '../allocation.css';


class Calendar extends Component {
  constructor(props) {
    super(props);

    this.render = this.render.bind(this);
    this.state = {
      startDate: moment(),
      dateChanged: false,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(date) {
    this.setState({
      startDate: date,
      dateChanged: true,
    });
    this.props.setDate(date);
    this.props.calendarSearch(date);
  }

  render() {
    const selectedDate = this.state.dateChanged ? this.state.startDate : this.props.initialDate;
    return (
      <div className="calendarDiv">
        <DatePicker
          dateFormat="DD/MM/YY"
          placeholderText="Today"
          selected={selectedDate}
          onChange={this.handleChange}
        />
      </div>
    );
  }
}

Calendar.propTypes = {
  setDate: PropTypes.func,
  calendarSearch: PropTypes.func,
  initialDate: PropTypes.shape({}),
};
Calendar.defaultProps = {
  setDate: null,
  calendarSearch: null,
  initialDate: null,
};

export default Calendar;
