import { FETCH_LOCATION, FETCH_AVAILABLE_ASSETS, FETCH_DISTANCE, FETCH_VALID_END_LOCATION, FETCH_VALID_VIA_LOCATION, FETCH_VALID_START_LOCATION } from '../constants';

export default function fetchLocation(state = [], action) {
  if (action.error) {
    return state;
  }
  switch (action.type) {
    case FETCH_LOCATION:
      return action.payload.data ? action.payload.data : [];
    case FETCH_VALID_END_LOCATION:
      return action.payload.data ? action.payload.data : [];
    case FETCH_VALID_VIA_LOCATION:
      return action.payload.data ? action.payload.data : [];
    case FETCH_VALID_START_LOCATION:
      return action.payload.data ? action.payload.data : [];
    case FETCH_AVAILABLE_ASSETS:
      return action.payload.data ? action.payload.data : [];
    case FETCH_DISTANCE:
      return action.payload.data ? action.payload.data : [];
    default:
      return state;
  }
}
