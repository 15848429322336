import 'react-bootstrap-modal/lib/css/rbm-patch.css';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { DraggableItemTypes, makeDragSource } from '../../utils/drag-n-drop';
import AssetRestrictions from './assetRestriction';
import '../allocation.css';
import NoteModal from './noteModal';
import { isEditingAllowed } from '../util';
import EodFuel from './eodFuel';
import Location from './location';

const assetRowDragSource = {
  beginDrag(props) {
    return { asset: props.asset };
  },
  canDrag() {
    return isEditingAllowed();
  },
};

class AssetRow extends Component {
  static shortNote(note) {
    if (note.length > 14) {
      return `${note.substring(0, 14)}...`;
    }
    return note;
  }

  static getNoteColour(noteDescription, checked) {
    if (noteDescription === null || noteDescription === '') {
      return '';
    } else if (checked) {
      return 'green';
    }
    return 'red';
  }

  static getRestrictions(asset) {
    let restrictions = [];
    if (asset && asset.restrictions) {
      ({ restrictions } = asset);
    }
    return (
      <AssetRestrictions
        id={`allres-${asset.externalId}`}
        restrictions={restrictions}
      />
    );
  }

  constructor(props) {
    super(props);

    this.handleHide = this.handleHide.bind(this);

    this.state = {
      show: false,
    };
  }

  getFleetStyle() {
    const style = {};
    const fleet = this.props.asset.fleetConfiguration;
    if (fleet) {
      style.color = fleet.textColour;
      style.backgroundColor = fleet.fillColour;
    }
    return style;
  }

  handleHide() {
    this.setState({ show: false });
  }

  isRenderEodFuel(asset) {
    const { formationType } = asset;
    if (formationType) {
      const naFormationTypes = this.props.fnaFormationTypes;
      if (naFormationTypes && naFormationTypes.includes(formationType)) {
        return false;
      }
    }
    return true;
  }

  renderRow() {
    const { isDragging, asset } = this.props;
    const className = `assetRow draggable ${isDragging ? 'dragging' : ''}`;
    return (
      <tr className={className} key={asset.externalId}>
        <td>
          <span
            className="clickableAsset available"
            onClick={() => this.setState({ show: true })}
            onKeyDown={() => this.setState({ show: true })}
            style={this.getFleetStyle()}
            role="gridcell"
            tabIndex={0}
          >
            {asset.code}
          </span>
        </td>
        <td className="td-veh">{asset.noVehicles}</td>
        <td>{asset.status}</td>
        <td>{this.isRenderEodFuel(asset) && <EodFuel asset={asset} />}</td>
        <td>{AssetRow.getRestrictions(asset)}</td>
        <td title={asset.noteDescription}>
          <span
            style={{
              backgroundColor: AssetRow.getNoteColour(
                asset.noteDescription,
                asset.checked,
              ),
              padding: 2,
              paddingRight: 5,
              paddingLeft: 5,
              fontWeight: 'bold',
              color: 'white',
            }}
          >
            {asset.noteDescription ? AssetRow.shortNote(asset.noteDescription) : ''}
          </span>
        </td>
        <td>
          <Location location={asset.sodLocation} />
        </td>
        <td>
          <Location location={asset.eodLocation} />
        </td>
        <NoteModal
          noteDescription={asset.noteDescription}
          asset={asset}
          show={this.state.show}
          handleHide={this.handleHide}
        />
      </tr>
    );
  }

  render() {
    const { connectDragSource } = this.props;
    return connectDragSource(this.renderRow());
  }
}

AssetRow.propTypes = {
  asset: PropTypes.shape({
    externalId: PropTypes.number,
    code: PropTypes.string,
    noVehicles: PropTypes.number,
    status: PropTypes.string,
    noteDescription: PropTypes.string,
    checked: PropTypes.bool,
    sodLocation: PropTypes.shape({}),
    eodLocation: PropTypes.shape({}),
    fleetConfiguration: PropTypes.shape({
      textColour: PropTypes.string,
      fillColour: PropTypes.string,
    }),
  }),
  isDragging: PropTypes.bool,
  fnaFormationTypes: PropTypes.arrayOf(PropTypes.string),
  connectDragSource: PropTypes.func,
};
AssetRow.defaultProps = {
  asset: {},
  isDragging: false,
  fnaFormationTypes: [],
  connectDragSource: null,
};

function mapStateToProps(state) {
  return {
    fnaFormationTypes: state.clientSettings.fuelNotApplicableFormationTypes,
  };
}

const draggableAvailableAsset = makeDragSource(
  AssetRow,
  DraggableItemTypes.ASSET,
  assetRowDragSource,
);

export default connect(mapStateToProps)(draggableAvailableAsset);
