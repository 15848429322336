import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { makeDnDAware } from '../../utils/drag-n-drop';
import AssetRow from './assetRow';
import { sortAssets } from '../actions/asset';
import renderSortableColumnHeader from './componentUtil';
import messages from '../../messages';
import '../allocation.css';

class AvailabilityTable extends Component {
  constructor(props) {
    super(props);
    this.render = this.render.bind(this);
    this.columnLabels = ['commonUnit', 'allocationUnitNoOfVehicles', 'allocationUnitStatus', 'allocationUnitEODFuel',
      'allocationUnitRestrictions', 'allocationUnitNotes', 'allocationUnitSODLocation', 'allocationUnitEODLocation'];
    this.columnSort = ['unit', 'vehicle_count', 'status', 'eod_fuel', 'restrictions',
      'notes', 'sod_location', 'eod_location'];
  }

  renderItem() {
    return this.props.asset.map(item => (
      <AssetRow
        key={item.externalId}
        asset={item}
      />
    ));
  }

  renderColumnHeader(labelKey, column, index) {
    return renderSortableColumnHeader(
      messages[labelKey],
      column,
      this.props.filter.sort,
      sortColumn => this.props.sortAssets(sortColumn),
      index,
    );
  }

  render() {
    return (
      <table className="compactFirstColumn compactColumnSides table text-nowrap" id="availability">
        {
          this.props.outdated &&
          <caption className="availabilityCaption">
            {messages.allocationUnitsFromCache}
          </caption>
        }
        <thead>
          <tr>
            {this.columnLabels.map((v, i) => this.renderColumnHeader(v, this.columnSort[i], i))}
          </tr>
        </thead>
        <tbody>{this.renderItem()}</tbody>
      </table>
    );
  }
}

AvailabilityTable.propTypes = {
  asset: PropTypes.arrayOf(PropTypes.shape({})),
  filter: PropTypes.shape({
    sort: PropTypes.string,
  }),
  sortAssets: PropTypes.func,
  outdated: PropTypes.bool,
};
AvailabilityTable.defaultProps = {
  asset: [],
  filter: {},
  sortAssets: null,
  outdated: false,
};

function mapStateToProps({ asset }) {
  return { asset: asset.assets, outdated: asset.outdated, filter: asset.filter };
}

export default makeDnDAware(connect(mapStateToProps, { sortAssets })(AvailabilityTable));
