import { FETCH_ALL_DISTANCES, FETCH_DISTANCES } from '../constants';

export default function fetchAllDistances(state = [], action) {
  if (action.error) {
    return state;
  }
  switch (action.type) {
    case FETCH_ALL_DISTANCES:
      return action.payload.data;
    case FETCH_DISTANCES:
      return action.payload.data;
    default:
      return state;
  }
}
