import { connect } from 'react-redux';
import searchResultTable from '../../components/searchResultTable';
import FleetRow from './fleetRow';

function mapStateToProps({ searchFleets }) {
  return { rowObjects: searchFleets.fleets };
}

export default connect(mapStateToProps, null)(searchResultTable(
  ['commonCode', 'fleetFormationType'],
  FleetRow,
));
