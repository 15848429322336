import React from 'react';
import { connect } from 'react-redux';
import { Link, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import kc from '../index';
import messages from '../messages';

export function hasLicence(kcRole) {
  return kc.hasResourceRole(kcRole, 'd2m-ui');
}

export const LicencedRoute = ({ kcRole, path, uiComponent }) => {
  if (hasLicence(kcRole)) {
    return (
      <Route path={path} component={uiComponent} />
    );
  }
  return '';
};
LicencedRoute.propTypes = {
  kcRole: PropTypes.string,
  path: PropTypes.string,
  uiComponent: PropTypes.func,
};
LicencedRoute.defaultProps = {
  kcRole: null,
  path: null,
  uiComponent: null,
};

export const LicencedLink = ({ kcRole, path, linkLabel }) => {
  if (hasLicence(kcRole)) {
    return (
      <li data-sidebar-action="sidebar-linkClick">
        <Link to={path}>{messages[linkLabel] || linkLabel}</Link>
      </li>
    );
  }
  return '';
};
LicencedLink.propTypes = {
  kcRole: PropTypes.string,
  path: PropTypes.string,
  linkLabel: PropTypes.string,
};
LicencedLink.defaultProps = {
  kcRole: null,
  path: null,
  linkLabel: null,
};

export const LicencedActionLink = ({
  kcRole,
  path,
  linkLabel,
  clickAction,
}) => {
  if (hasLicence(kcRole)) {
    const link = ({ linkPath, label, onClickAction }) => (
      <li data-sidebar-action="sidebar-linkClick">
        <Link to={linkPath} onClick={() => onClickAction()}>
          {messages[label] || label}
        </Link>
      </li>
    );
    const ConnectedLink = connect(null, { onClickAction: clickAction })(link);
    return <ConnectedLink linkPath={path} label={linkLabel} />;
  }
  return '';
};
LicencedActionLink.propTypes = {
  kcRole: PropTypes.string,
  path: PropTypes.string,
  linkLabel: PropTypes.string,
  clickAction: PropTypes.func,
};
LicencedActionLink.defaultProps = {
  kcRole: null,
  path: PropTypes.string,
  linkLabel: PropTypes.string,
  clickAction: PropTypes.func,
};
