import { Get } from '../../utils/ajax';
import { ROOT_URL, FETCH_DISTANCE, FETCH_ALL_DISTANCES, FETCH_DISTANCES } from '../constants';

export function locationDistance(from, to, via) {
  const response = Get(`${ROOT_URL}/distance/${from}/${to}/${via}`);
  return {
    type: FETCH_DISTANCE,
    payload: response,
  };
}

export function fetchAllDistances() {
  const response = Get(`${ROOT_URL}/distance/all`);
  return {
    type: FETCH_ALL_DISTANCES,
    payload: response,
  };
}

export function fetchDistances(filter) {
  const response = Get(`${ROOT_URL}/distance/filtered/${filter}`, {
    filter,
  });
  return {
    type: FETCH_DISTANCES,
    payload: response,
  };
}
