import React from 'react';
import reactCSS from 'reactcss';
import { SketchPicker } from 'react-color';
import PropTypes from 'prop-types';

/**
 * code taken from:
 *
 * https://casesandberg.github.io/react-color/
 *
 */

class ColorPicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayColorPicker: false,
      color: this.externalArgToColor(this.props.color),
    };
  }

  externalArgToColor(arg) {
    if (this.props.useHex) {
      return { hex: arg };
    }
    return {};
  }

  colorToExternalArg(color) {
    if (this.props.useHex) {
      return color.hex;
    }
    return '';
  }

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false });
  };

  handleChange = (color) => {
    this.setState({ color });
  };

  handleChangeComplete = (color) => {
    if (this.props.onColorSelect) {
      this.props.onColorSelect(this.colorToExternalArg(color));
    }
  };

  render() {
    const styles = reactCSS({
      default: {
        color: {
          width: '36px',
          height: '14px',
          borderRadius: '2px',
          background: `${this.state.color.hex}`,
        },
        swatch: {
          padding: '5px',
          background: '#fff',
          borderRadius: '1px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer',
        },
        popover: {
          position: 'absolute',
          zIndex: '2',
        },
        cover: {
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
        },
      },
    });

    return (
      <div>
        <div style={styles.swatch} onClick={this.handleClick} onKeyDown={this.handleClick} role="presentation">
          <div style={styles.color} />
        </div>
        {this.state.displayColorPicker ? (
          <div style={styles.popover}>
            <div style={styles.cover} onClick={this.handleClose} onKeyDown={this.handleClose} role="presentation" />
            <SketchPicker
              color={this.state.color.hex || ''}
              onChange={this.handleChange}
              onChangeComplete={this.handleChangeComplete}
              disableAlpha={
                this.props.disableAlpha ? this.props.disableAlpha : true
              }
            />
          </div>
        ) : null}
      </div>
    );
  }
}

ColorPicker.propTypes = {
  color: PropTypes.string,
  useHex: PropTypes.bool,
  onColorSelect: PropTypes.func,
  disableAlpha: PropTypes.bool,
};
ColorPicker.defaultProps = {
  color: null,
  useHex: false,
  onColorSelect: null,
  disableAlpha: false,
};

export default ColorPicker;
