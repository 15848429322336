import { GetWithToken } from '../../utils/ajax';
import { ROOT_URL, FETCH_AVAILABLE_ASSETS } from '../constants';

export default function fetchAvailableAssets(assetName, token) {
  const response = GetWithToken(`${ROOT_URL}/asset/available/special/${assetName}`, token);
  return {
    type: FETCH_AVAILABLE_ASSETS,
    payload: response,
  };
}

