import axios from 'axios';
import Alert from 'react-s-alert';
import kc, { updateLocalStorage, hasStoredToken, appInitParams } from '../index';
import LoadingSpinner from '../components/LoadingSpinner';
import { ERROR_ACCESS, INTERNAL_SERVER_ERROR } from '../constants';

let requestCount = 0;

function decrementRequestCount() {
  requestCount -= 1;
  if (requestCount === 0) {
    LoadingSpinner.hide();
  }
}

// need to wrap the KC "promise object" into a real Promise object
const refreshToken = (minValidity = 5) => (
  new Promise((resolve, reject) => {
    kc.updateToken(minValidity)
      .success(() => resolve())
      .error(error => reject(error));
  })
);

axios.interceptors.request.use((config) => {
  // verify user haven't signOut.
  if (hasStoredToken()) {
    return refreshToken().then(() => {
      updateLocalStorage();
      const theConfig = config;
      theConfig.headers.Authorization = `Bearer ${kc.token}`;
      theConfig.headers.d2mUsername = kc.idTokenParsed.preferred_username;
      if (appInitParams && appInitParams.locale) {
        theConfig.headers['accept-language'] = appInitParams.locale;
      }
      return Promise.resolve(theConfig);
    }).catch(() => {
      kc.login();
    });
  }

  kc.login();
  return config;
});

axios.interceptors.request.use((config) => {
  requestCount += 1;
  LoadingSpinner.show();
  return config;
});

axios.interceptors.response.use(response => response, (error) => {
  if (error.response.status === 403) {
    Alert.warning(ERROR_ACCESS);
    error.globallyHandled = true;
  } else if (error.response.status === 500) {
    Alert.error(INTERNAL_SERVER_ERROR, { timeout: 'none' });
    error.globallyHandled = true;
  }
  return Promise.reject(error);
});

axios.interceptors.response.use((response) => {
  decrementRequestCount();
  return response;
}, (error) => {
  decrementRequestCount();
  return Promise.reject(error);
});

export function Get(url, config) {
  if (config) {
    return axios.get(url, config);
  }
  return axios.get(url);
}

export function GetWithToken(url, token) {
  if (token) {
    return Get(url, { cancelToken: token });
  }
  return Get(url);
}

export function Post(url, data, config) {
  if (config) {
    return axios.post(url, data, config);
  }
  return axios.post(url, data);
}

export function Put(url, data) {
  return axios.put(url, data);
}

export function Delete(url) {
  return axios.delete(url);
}
