import React from 'react';

import AllocationTable from './components/allocationTable';
import AvailabilityTable from './components/availabilityTable';
import AvailabilityFilter from './components/availabilityFilter';
import FilterBar from './components/filterBar';
import './allocation.css';

class Allocation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showAvailabilitySection: true,
    };
  }

  getSectionClassName(isAllocationSection) {
    if (isAllocationSection) {
      return this.state.showAvailabilitySection ? 'col-xs-8' : 'col-xs-12 fullWidth';
    }
    return this.state.showAvailabilitySection ? 'col-xs-4' : 'd-none';
  }

  toggleAvailabilitysection() {
    const showAvailabilitySection = !this.state.showAvailabilitySection;
    this.setState({ showAvailabilitySection });
  }

  render() {
    return (
      <div>
        <div className={`${this.getSectionClassName(true)} filter`}>
          <div className="table-responsive">
            <FilterBar
              toggleAvailabilitysection={() => this.toggleAvailabilitysection()}
              showAvailabilitySection={this.state.showAvailabilitySection}
            />
          </div>
        </div>
        <div className={`${this.getSectionClassName(false)} filterAvail`}>
          <div className="table-responsive">
            <div className="assetSearch">
              <AvailabilityFilter />
            </div>
          </div>
        </div>
        <div className={`${this.getSectionClassName(true)} allocation`}>
          <div className="table-responsive">
            <AllocationTable />
          </div>
        </div>
        <div className={`${this.getSectionClassName(false)} availability`}>
          <div className="table-responsive vertScroll">
            <AvailabilityTable />
          </div>
        </div>
      </div>
    );
  }
}

export default Allocation;
