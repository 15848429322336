import {
  ROOT_URL,
  SAVE_FLEET,
  MAX_FLEET_CODE_LENGTH,
} from '../constants';
import { validateMandatoryFields } from '../../utils/validation';
import { createSaveAction } from '../../utils/actionHelper';
import messages from '../../messages';

function validateFleet(fleet) {
  const errors = [];
  validateMandatoryFields(
    fleet,
    {
      code: { name: messages.commonCode, maxLength: MAX_FLEET_CODE_LENGTH },
    },
    errors,
  );
  return errors;
}

export default function saveFleet(fleet, successCallback) {
  return createSaveAction({
    saveObject: fleet,
    validationFunction: validateFleet,
    validationFailedMsag: messages.fleetSaveOrUpdateValidationFailedMessage,
    saveUrl: `${ROOT_URL}/fleet/saveOrUpdate`,
    errorInSavingMsg: messages.fleetSaveOrUpdateFailureMessage,
    successfullySavedMsg:
      messages.formatString(messages.fleetSaveOrUpdateSuccessMessage, fleet.code || ''),
    dispatchType: SAVE_FLEET,
    successCallback,
  });
}
