import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'react-tooltip-lite';

class Restriction extends Component {
  getRestrictions() {
    let result = this.props.restrictions;
    if (!result) {
      result = [];
    }
    return result;
  }

  renderRestrictionContent(suffix) {
    const uniquePrefix = `restrict-${this.props.id}`;
    return this.getRestrictions().map((r, index) => (
      <span
        id={`${uniquePrefix}${r.id}`}
        key={`${uniquePrefix}${r.id}`}
        style={{ color: 'white', backgroundColor: `${r.color}` }}
      >
        {index > 0 && suffix}{r.description}
      </span>
    ));
  }

  render() {
    return (
      <div className="assetRestrictions">
        <Tooltip
          content={this.renderRestrictionContent((<br />))}
          tipContentClassName="assetRestrictionToolTip"
          padding="5px"
          tagName="span"
        >
          {this.renderRestrictionContent(',')}
        </Tooltip>
      </div>
    );
  }
}

Restriction.propTypes = {
  id: PropTypes.string,
  restrictions: PropTypes.arrayOf(PropTypes.shape({})),
};
Restriction.defaultProps = {
  id: null,
  restrictions: [],
};

export default Restriction;
