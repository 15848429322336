import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DraggableItemTypes, makeDropTarget } from '../../utils/drag-n-drop';
import AllocatedAssetList from './allocatedAssetList';
import AssetRestrictions from './assetRestriction';
import {
  ALLOCATION_TYPE_DIAGRAM,
  EOD_FUEL_ERROR_LEVEL,
  TOOLTIP_EOD_FUEL_ERROR,
  EOD_FUEL_LEVEL_THRESHOLDTYPE_ERROR,
} from '../constants';
import ExtreameWarningIcon from './warningIcon';
import { isEditingAllowed } from '../util';
import EodFuel from './eodFuel';
import Location from './location';

const diagramTarget = {
  drop(props, monitor) {
    props.onAssetDrop(monitor.getItem().asset, props.diagram, null, props.onSuccessCallBack, false);
  },
  canDrop(props) {
    return !props.diagram.readOnly && props.diagram.type === ALLOCATION_TYPE_DIAGRAM
      && isEditingAllowed();
  },
};

class DiagramRow extends Component {
  static shortNote(note) {
    if (note.length > 14) {
      return `${note.substring(0, 14)}...`;
    }
    return note;
  }
  static getNoteColour(note, checked) {
    if (note === null || note === '') {
      return '';
    } else if (checked) {
      return 'green';
    }
    return 'red';
  }
  static getEODColour(eodFuel) {
    if (eodFuel < 0) {
      return 'red';
    }
    return '';
  }
  static warningIcon(unit) {
    const { eodFuel } = unit;
    let errorThreshold = EOD_FUEL_ERROR_LEVEL;
    if (unit.thresholdList !== null) {
      for (let i = 0; i < unit.thresholdList.length; i += 1) {
        if (
          unit.thresholdList[i].thresholdTypeId ===
          EOD_FUEL_LEVEL_THRESHOLDTYPE_ERROR
        ) {
          errorThreshold = unit.thresholdList[i].value;
        }
      }
    }
    if (eodFuel <= errorThreshold) {
      return (
        <ExtreameWarningIcon
          iconClassName="eodFuelError"
          iconTooltip={TOOLTIP_EOD_FUEL_ERROR}
        />
      );
    }
    return '';
  }

  static getFirstThreeCharOrEmpty(location) {
    if (location) {
      return location.length >= 3 ? location.substring(0, 3) : location;
    }
    return '';
  }

  hasApplicableFomationType(unit) {
    if (unit.fleet) {
      const naFormationTypes = this.props.fnaFormationTypes;
      const { formationType } = unit.fleet;
      if (naFormationTypes && naFormationTypes.includes(formationType)) {
        return false;
      }
    }
    return true;
  }

  renderUnitEOD() {
    let { unitList } = this.props.diagram;
    if (!unitList) {
      unitList = [];
    }
    return unitList.map(unit => (
      <div style={{ height: 22, marginLeft: 5 }} key={unit.id}>
        <span
          key={unit.id}
          style={{
            color: DiagramRow.getEODColour(unit.eodFuel),
          }}
        >
          {' '}
          {this.renderWarningIconAndEodFuel(unit)}
          <br />
        </span>
      </div>
    ));
  }

  renderWarningIconAndEodFuel(unit) {
    if (this.hasApplicableFomationType(unit)) {
      return [DiagramRow.warningIcon(unit), <EodFuel asset={unit} key={unit.id} />];
    }
    return '';
  }

  renderUnitNotes() {
    let { unitList } = this.props.diagram;
    if (!unitList) {
      unitList = [];
    }
    return unitList.map(unit => (
      <div style={{ height: 22 }} key={unit.id}>
        <span
          title={unit.noteDescription}
          style={{
            backgroundColor: DiagramRow.getNoteColour(
              unit.noteDescription,
              unit.checked,
            ),
            padding: 2,
            paddingRight: 5,
            paddingLeft: 5,
            fontWeight: 'bold',
            color: 'white',
            margin: 1,
          }}
        >
          {unit.noteDescription ? DiagramRow.shortNote(unit.noteDescription) : ''}
          <br />
        </span>
      </div>
    ));
  }

  renderUnitRestrictions() {
    let { unitList } = this.props.diagram;
    if (!unitList) {
      unitList = [];
    }
    return unitList.map(unit => (
      <AssetRestrictions key={unit.id} restrictions={unit.restrictions} />
    ));
  }

  renderDiagramRow() {
    const { diagram, isOver, canDrop } = this.props;
    const className = `diagramRow ${canDrop ? 'droppable' : ''} ${
      isOver ? 'over' : ''
    }`;
    return (
      <tr className={className}>
        <td>
          <span
            className="clickableAsset"
            onClick={() => this.props.onExpandDiagram(diagram)}
            onKeyDown={() => this.props.onExpandDiagram(diagram)}
            role="button"
            tabIndex={0}
          >
            {diagram.diagramName}
          </span>
        </td>
        <td className="td-class">{diagram.className}</td>

        <td className="td-veh">{diagram.vehicleCount}</td>
        <td>
          {diagram.start ? diagram.start.slice(0, -3) : ''}
          <Location
            location={{
              fillColour: diagram.startLocationFillColour,
              textColour: diagram.startLocationTextColour,
              description: diagram.startLocation,
            }}
          />
        </td>
        <td>
          {diagram.finish ? diagram.finish.slice(0, -3) : ''}
          <Location
            location={{
              fillColour: diagram.finishLocationFillColour,
              textColour: diagram.finishLocationTextColour,
              description: diagram.finishLocation,
            }}
          />
        </td>
        <td className="td-int">
          <span
            className="interventionBox"
            style={{
              backgroundColor: diagram.interventionFillColour,
              color: diagram.interventionTextColour,
            }}
          >
            {DiagramRow.getFirstThreeCharOrEmpty(diagram.interventionLocation)}
          </span>
        </td>
        <td>{diagram.headCode}</td>
        <td className="allocatedAssetColumn">
          <AllocatedAssetList
            diagram={diagram}
            unitList={diagram.unitList}
            date={this.props.date}
            location={this.props.location}
            start={this.props.start}
            end={this.props.end}
            quick={this.props.quick}
            filter={this.props.filter}
          />
        </td>
        <td>
          <span>{this.renderUnitEOD()}</span>
        </td>
        <td>{this.renderUnitRestrictions()}</td>
        <td>
          <span>{this.renderUnitNotes()}</span>
        </td>
      </tr>
    );
  }

  render() {
    const { connectDropTarget } = this.props;
    return connectDropTarget(this.renderDiagramRow());
  }
}

DiagramRow.propTypes = {
  diagram: PropTypes.shape({
    unitList: PropTypes.arrayOf(PropTypes.shape({})),
    diagramName: PropTypes.string,
    className: PropTypes.string,
    vehicleCount: PropTypes.number,
    start: PropTypes.string,
    startLocationFillColour: PropTypes.string,
    startLocation: PropTypes.string,
    finish: PropTypes.string,
    finishLocationFillColour: PropTypes.string,
    finishLocation: PropTypes.string,
    interventionFillColour: PropTypes.string,
    interventionLocation: PropTypes.string,
    headCode: PropTypes.string,
    startLocationTextColour: PropTypes.string,
    finishLocationTextColour: PropTypes.string,
    interventionTextColour: PropTypes.string,
  }),
  fnaFormationTypes: PropTypes.arrayOf(PropTypes.string),
  isOver: PropTypes.bool,
  canDrop: PropTypes.bool,
  onExpandDiagram: PropTypes.func,
  date: PropTypes.string,
  location: PropTypes.string,
  start: PropTypes.string,
  end: PropTypes.string,
  quick: PropTypes.string,
  filter: PropTypes.func,
  connectDropTarget: PropTypes.func,
};
DiagramRow.defaultProps = {
  diagram: null,
  fnaFormationTypes: [],
  isOver: false,
  canDrop: false,
  onExpandDiagram: null,
  date: null,
  location: '',
  start: null,
  end: null,
  quick: null,
  filter: null,
  connectDropTarget: null,
};

export default makeDropTarget(
  DiagramRow,
  DraggableItemTypes.ASSET,
  diagramTarget,
);
