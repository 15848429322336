import { ROOT_URL as BASE_ROOT_URL } from '../constants';

export const ROOT_URL = BASE_ROOT_URL;

export const SEARCH_LOCATIONS = 'SEARCH_LOCATIONS';
export const SAVE_LOCATION = 'SAVE_LOCATION';
export const DELETE_LOCATION = 'DELETE_LOCATION';
export const RESTORE_LOCATION = 'RESTORE_LOCATION';

export const LOCATION_CODE_LENGTH = 3;
export const LOCATION_DESCRIPTION_LENGTH = 50;
export const LOCATION_TIPLOC_LENGTH = 7;

