import _ from 'lodash';
import {
  FETCH_DIAGRAM,
  ALLOCATE_ASSET,
  DEALLOCATE_ASSET,
  REALLOCATE_ASSET,
  ADD_NOTE,
  EXPAND_DIAGRAM,
  ASSIGN_RESTRICTIONS,
  ALLOCATION_TYPE_DIAGRAM,
} from '../constants';
import getDiagramKey from '../util';

function updateDiagramAllocations(diagrams, allocationsByTypeId) {
  diagrams.forEach((diagramIn) => {
    const diagram = diagramIn;
    const allocationsById = allocationsByTypeId[diagram.type];
    if (allocationsById) {
      const allocations = allocationsById[diagram.diagramId];
      if (allocations) {
        diagram.unitList = allocations.diagramAllocations;
        diagram.diagramLegs.forEach((leg) => {
          const diagramLeg = leg;
          let newUnits = allocations.allocationsByLegId[diagramLeg.id];
          if (!newUnits) {
            newUnits = [];
          }
          diagramLeg.units = newUnits;
        });
        diagram.hasUnallocatedLegs = allocations.hasUnallocatedLegs;
      }
    }
  });
}

function updateUnits(diagrams, updateUnitFunction) {
  diagrams.forEach((diagram) => {
    if (diagram.unitList) {
      diagram.unitList.forEach(updateUnitFunction);
      if (diagram.diagramLegs && diagram.diagramLegs.units) {
        diagram.diagramLegs.units.forEach(updateUnitFunction);
      }
    }
  });
}

export default function fetchDiagram(state = { diagrams: [], filter: {} }, action) {
  if (action.error) {
    return state;
  }
  switch (action.type) {
    case FETCH_DIAGRAM: {
      const newDiagrams = action.payload.data ? action.payload.data : [];
      newDiagrams.forEach((newDiagram) => {
        if (newDiagram.expanded) {
          // any expaned diagrams from server have legs loaded
          newDiagram.legsLoaded = true;
        }
      });
      return {
        diagrams: newDiagrams,
        filter: action.meta.filter,
      };
    }
    case ALLOCATE_ASSET:
    case DEALLOCATE_ASSET:
    case REALLOCATE_ASSET: {
      const { diagram, allocations } = action.payload;
      updateDiagramAllocations(state.diagrams, allocations);
      if (action.type === DEALLOCATE_ASSET && diagram && diagram.type !== ALLOCATION_TYPE_DIAGRAM) {
        _.remove(state.diagrams, d => d === diagram);
      }
      return {
        diagrams: _.concat([], state.diagrams),
        filter: state.filter,
      };
    }
    case ADD_NOTE: {
      const { asset, note, checked } = action.payload;
      const updateUnit = (unitIn) => {
        if (asset.externalId === unitIn.externalId) {
          const unit = unitIn;
          unit.noteDescription = note;
          unit.checked = checked;
        }
      };
      updateUnits(state.diagrams, updateUnit);
      return {
        diagrams: _.concat([], state.diagrams),
        filter: state.filter,
      };
    }
    case EXPAND_DIAGRAM: {
      const diagram = Object.assign({}, action.meta.diagram);
      diagram.expanded = !diagram.expanded;
      if (!diagram.legsLoaded && action.payload) {
        diagram.diagramLegs = action.payload.data;
        diagram.legsLoaded = true;
      }
      const diagramKey = getDiagramKey(diagram);
      for (let i = 0; i < state.diagrams.length; i += 1) {
        const currentDiagram = state.diagrams[i];
        if (getDiagramKey(currentDiagram) === diagramKey) {
          state.diagrams[i] = diagram;
          break;
        }
      }
      return {
        diagrams: _.concat([], state.diagrams),
        filter: state.filter,
      };
    }
    case ASSIGN_RESTRICTIONS: {
      const { restrictions, asset } = action.payload;
      const updateUnit = (unitIn) => {
        const unit = unitIn;
        if (asset.externalId === unit.externalId) {
          unit.restrictions = restrictions;
        }
      };
      updateUnits(state.diagrams, updateUnit);
      return {
        diagrams: _.concat([], state.diagrams),
        filter: state.filter,
      };
    }
    default:
      return state;
  }
}
