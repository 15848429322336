import React from 'react';
import LocationTable from './components/locationTable';
import TopBar from './components/topBar';
import './location.css';

class LocationDistances extends React.Component {
  constructor(props) {
    super(props);

    this.render = this.render.bind(this);
  }

  render() {
    return (
      <div>
        <div className="col-xs-12 filter">
          <div className="table-responsive topbarDistance">
            <TopBar />
          </div>
        </div>
        <div className="col-xs-12 location">
          <div className="table-responsive">
            <LocationTable />
          </div>
        </div>
      </div>
    );
  }
}

export default LocationDistances;
