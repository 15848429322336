import _ from 'lodash';
import { SEARCH_RESTRICTIONS, SAVE_RESTRICTION } from '../constants';

export default function searchRestrictions(state = { restrictions: [] }, action) {
  if (action.error) {
    return state;
  }
  switch (action.type) {
    case SEARCH_RESTRICTIONS:
      return { restrictions: action.payload.data, searchText: action.meta.searchText };
    case SAVE_RESTRICTION: {
      const newRestrictions = _.concat([], state.restrictions);
      const index = _.findIndex(newRestrictions, r => r.id === action.payload.savedObject.id);
      if (index > -1) {
        newRestrictions[index] = action.payload.savedObject;
      }
      return Object.assign({}, state, { restrictions: newRestrictions });
    }
    default:
      return state;
  }
}
