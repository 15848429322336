import React from 'react';
import currentDiagramDate from '../actions/currentDiagramDate';
import searchLocations from '../locations/actions/searchLocations';
import searchRestrictions from '../restrictions/actions/searchRestrictions';
import searchFleets from '../fleets/actions/searchFleets';
import { LicencedLink, LicencedActionLink } from './licenced_components';
import {
  ALLOCATION_SCREEN,
  SPECIAL_MOVEMENT_SCREEN,
  EOD_VALIDATION_SCREEN,
  LOCATIONS_SCREEN_VIEW,
  LOCATION_DISTANCE_SCREEN,
  CHANGE_REASON_SCREEN,
  RESTRICTIONS_SCREEN_VIEW,
  FLEETS_SCREEN_VIEW,
} from '../kc_roles';

const Side = () => (
  <aside id="sidebar" className="sidebar c-overflow hidebar">
    <ul className="main-menu">
      <LicencedActionLink
        kcRole={ALLOCATION_SCREEN}
        path="/allocations"
        linkLabel="sideMenuAllocation"
        clickAction={currentDiagramDate}
      />
      <LicencedLink
        kcRole={SPECIAL_MOVEMENT_SCREEN}
        path="/specialMovements"
        linkLabel="sideMenuSpecialTrafficMovement"
      />
      <LicencedLink
        kcRole={CHANGE_REASON_SCREEN}
        path="/changeReason"
        linkLabel="sideMenuChangeReason"
      />
      <LicencedActionLink
        kcRole={EOD_VALIDATION_SCREEN}
        path="/eodValidation"
        linkLabel="sideMenuEODValidation"
        clickAction={currentDiagramDate}
      />
      <LicencedLink
        kcRole={LOCATION_DISTANCE_SCREEN}
        path="/locationDistances"
        linkLabel="sideMenuLocationDistances"
      />
      <LicencedActionLink
        kcRole={LOCATIONS_SCREEN_VIEW}
        path="/locations"
        linkLabel="sideMenuLocations"
        clickAction={searchLocations}
      />
      <LicencedActionLink
        kcRole={RESTRICTIONS_SCREEN_VIEW}
        path="/restrictions"
        linkLabel="sideMenuRestrictions"
        clickAction={searchRestrictions}
      />
      <LicencedActionLink
        kcRole={FLEETS_SCREEN_VIEW}
        path="/fleets"
        linkLabel="sideMenuFleets"
        clickAction={searchFleets}
      />
    </ul>
  </aside>
);

export default Side;
