import LocalizedStrings from 'react-localization';
import enMessages from './messages_en';
import frMessages from './messages_fr';

const messages = new LocalizedStrings({
  en: enMessages,
  fr: frMessages,
});

export default messages;
