import { Get } from '../../utils/ajax';
import { ROOT_URL, FETCH_ASSET } from '../constants';

function fetchAssets(asset, onlyAvailable, sortColumn) {
  let response;
  const sort = sortColumn || '+unit';
  if (asset === '') {
    response = Get(`${ROOT_URL}/asset/available/undefined/${onlyAvailable}/${sort}`);
  } else {
    response = Get(`${ROOT_URL}/asset/available/${asset}/${onlyAvailable}/${sort}`);
  }
  return {
    type: FETCH_ASSET,
    payload: response,
    meta: {
      onlyAvailable,
      asset,
      sort,
    },
  };
}

export default function fetchAssetList(asset, onlyAvailable) {
  return (dispatch, getState) => {
    dispatch(fetchAssets(asset, onlyAvailable, getState().asset.filter.sort));
  };
}

export function sortAssets(sort) {
  return (dispatch, getState) => {
    const state = getState();
    dispatch(fetchAssets(state.asset.filter.searchText, state.asset.filter.onlyAvailable, sort));
  };
}
