import { Get } from '../../utils/ajax';
import { ROOT_URL, SEARCH_FLEETS } from '../constants';

export default function search(searchText) {
  const searchValue = searchText || '';
  const response = Get(`${ROOT_URL}/fleet/search?quickSearch=${searchValue}`);
  return {
    type: SEARCH_FLEETS,
    payload: response,
    meta: {
      searchText,
    },
  };
}

function dispatchPreviousSearch(dispatch, getState) {
  dispatch(search(getState().searchFleets.searchText));
}

export function refreshSearch() {
  return dispatchPreviousSearch;
}
