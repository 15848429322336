import { Get } from '../../utils/ajax';
import { ROOT_URL, FETCH_APPLICATION_VERSION } from '../../constants';

export default function applicationReleaseVersion() {
  const response = Get(`${ROOT_URL}/appReleaseVersion/version`);
  return {
    type: FETCH_APPLICATION_VERSION,
    payload: response,
  };
}
