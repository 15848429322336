import { connect } from 'react-redux';
import searchResultTable from '../../components/searchResultTable';
import LocationRow from './locationRow';

function mapStateToProps({ searchLocations }) {
  return { rowObjects: searchLocations.locations };
}

export default connect(mapStateToProps, null)(searchResultTable(
  ['commonDescription', 'locationShortCode', 'locationTiploc', 'locationIntervention', ''],
  LocationRow,
  null,
  null,
  null,
  [null, null, null, null, { checkEditLicence: true }],
));
