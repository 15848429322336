import { GetWithToken } from '../../utils/ajax';
import { ROOT_URL, FETCH_DISTANCE } from '../constants';

export default function fetchDistance(from, to, via, token) {
  const response = GetWithToken(`${ROOT_URL}/distance/${from}/${to}/${via}`, token);
  return {
    type: FETCH_DISTANCE,
    payload: response,
  };
}

