import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SearchField from './SearchField';
import modalOpenerButton from './modalOpenerButton';
import { TrimbleButton } from './FormComponents';
import { hasLicence } from './licenced_components';
import messages from '../messages';

/**
 * Simple search filter with a search field and an Add button
 * to open a popup for adding new entries.
 *
 * @param {*} AddModal - React component representing Add pop-up
 * @param {*} ExtraSearchPanel - Search Panel containing extra search fields
 */
export default function simpleSearchFilter(AddModal, ExtraSearchPanel) {
  const AddModalButton = AddModal ? modalOpenerButton(AddModal) : null;
  const SimpleSearchFilter = class SimpleSearchFilter extends Component {
    constructor(props) {
      super(props);
      this.searchField = null;

      this.setSearchField = (e) => {
        this.searchField = e;
      };
    }

    getPropsSubset(prefix) {
      const result = {};
      Object.keys(this.props).forEach((key) => {
        if (key.startsWith(prefix)) {
          result[key.substring(prefix.length)] = this.props[key];
        }
      });
      return result;
    }

    getAddModalProps() {
      return this.getPropsSubset('addPopup');
    }

    getSearchFieldProps() {
      const result = this.getPropsSubset('simpleSearchField');
      if (!result.hideClearSearch && ExtraSearchPanel) {
        result.hideClearSearch = true;
      }
      return result;
    }

    getExtraSearchPanelProps() {
      return this.getPropsSubset('extraSearchPanel');
    }

    doSearch(filter) {
      this.props.doSearch(filter);
    }

    doNewSearch() {
      if (this.props.doNewSearch) {
        this.props.doNewSearch();
        if (this.searchField) {
          this.searchField.clearFilter();
        }
      } else if (this.searchField) {
        this.searchField.doNewSearch();
      }
    }

    render() {
      return (
        <div className="filtersPanel">
          <SearchField
            doSearch={f => this.doSearch(f)}
            ref={this.setSearchField}
            {...this.getSearchFieldProps()}
          />
          {
            ExtraSearchPanel &&
            <ExtraSearchPanel
              {...this.getExtraSearchPanelProps()}
            />
          }
          {
            ExtraSearchPanel &&
            <TrimbleButton
              label={messages.commonClearSearch}
              extraCss="clearSearchField searchPanelButton"
              style={{ float: 'none' }}
              onClick={() => this.doNewSearch()}
            />
          }
          {
          AddModalButton && hasLicence(this.props.editLicence) &&
          <AddModalButton
            onAfterSave={() => this.doNewSearch()}
            {...this.getAddModalProps()}
          />
          }
        </div>
      );
    }
  };

  SimpleSearchFilter.propTypes = {
    doSearch: PropTypes.func,
    doNewSearch: PropTypes.func,
    editLicence: PropTypes.string,
  };
  SimpleSearchFilter.defaultProps = {
    doSearch: null,
    doNewSearch: null,
    editLicence: null,
  };

  return SimpleSearchFilter;
}
