import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { hasLicence } from './licenced_components';
import messages from '../messages';

/**
 * Renders a Table containing searched results.
 *
 * @param headers  - Array of message keys or strings representing header columns.
 *  - if no message found for the passed in value, raw value would be used.
 * @param RowComponent - React component that will render a row given a 'rowObject' prop
 * @param HeaderColumnComponent - Optional - React component that will render a
 *  header column given a header value
 * @param FooterRowComponent - Optional - React component that will render a footer row given a
 *  'footerRowObject' prop
 * @param ColgroupComponent - Optional - React component that will render 'colgroup' for the table.
 * @param extraHeaderConfigs - Array of Objects containing extra header configurations like below
 *  - checkEditLicence - whether edit licence should be present to show the column
 */
export default function searchResultTable(
  headers, RowComponent, HeaderColumnComponent,
  FooterRowComponent, ColgroupComponent, extraHeaderConfigs,
) {
  const SearchResultTable = class SearchResultTable extends Component {
    hasEditLicence() {
      return !this.props.editLicence || hasLicence(this.props.editLicence);
    }

    renderHeaderColumn(headerValue) {
      if (HeaderColumnComponent) {
        return (
          <th key={headerValue}>
            <HeaderColumnComponent value={headerValue} {...this.props} />
          </th>
        );
      }
      return (
        <th key={messages[headerValue] || headerValue}>
          {messages[headerValue] || headerValue}
        </th>
      );
    }

    renderHeaderColumns() {
      if (extraHeaderConfigs) {
        return headers.filter((h, index) => {
          const config = extraHeaderConfigs[index];
          return !config || !config.checkEditLicence || this.hasEditLicence();
        }).map(h => this.renderHeaderColumn(h));
      }
      return headers.map(h => this.renderHeaderColumn(h));
    }

    renderRows() {
      return this.props.rowObjects.map((r, i) => (
        <RowComponent key={r.id || `${r.assetId}-${i}`} rowObject={r} {...this.props} />
      ));
    }

    renderFooterRows() {
      const footers = this.props.footerRowObjects ? this.props.footerRowObjects : [];
      return footers.map(f => (
        <FooterRowComponent key={f.totalFuel} footerObject={f} {...this.props} />
      ));
    }

    renderFooter() {
      return (
        <tfoot>{this.renderFooterRows()}</tfoot>
      );
    }

    render() {
      return (
        <div>
          <table className={`table d2m-result-table ${this.props.extraTableCss}`}>
            {
              ColgroupComponent && <ColgroupComponent />
            }
            <thead>
              <tr>
                {this.renderHeaderColumns()}
              </tr>
            </thead>
            <tbody>
              {this.renderRows()}
            </tbody>
            {
              FooterRowComponent && this.renderFooter()
            }
          </table>
        </div>
      );
    }
  };

  SearchResultTable.propTypes = {
    editLicence: PropTypes.string,
    rowObjects: PropTypes.arrayOf(PropTypes.shape({})),
    footerRowObjects: PropTypes.arrayOf(PropTypes.shape({})),
    extraTableCss: PropTypes.string,
  };
  SearchResultTable.defaultProps = {
    editLicence: null,
    rowObjects: [],
    footerRowObjects: [],
    extraTableCss: '',
  };

  return SearchResultTable;
}
