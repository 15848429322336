import React, { Component } from 'react';
import messages from '../messages';

let mountedSpinner = null;

class LoadingSpinner extends Component {
  static show() {
    if (mountedSpinner) {
      mountedSpinner.showSelf();
    }
  }

  static hide() {
    if (mountedSpinner) {
      mountedSpinner.hideSelf();
    }
  }

  constructor(props) {
    super(props);
    this.state = { show: false };
  }

  componentDidMount() {
    mountedSpinner = this;
  }

  showSelf() {
    this.setState({ show: true });
  }

  hideSelf() {
    this.setState({ show: false });
  }

  render() {
    if (this.state.show) {
      return (
        <div id="loadingSpinnerOverlay">
          <i className="fa fa-spinner fa-spin" />
          <span className="loadingText" >{messages.commonOperationInProgress}</span>
        </div>
      );
    }
    return '';
  }
}

export default LoadingSpinner;
