import _ from 'lodash';
import { SEARCH_LOCATIONS, SAVE_LOCATION } from '../constants';

export default function searchLocations(state = { locations: [], filter: {} }, action) {
  if (action.error) {
    return state;
  }
  switch (action.type) {
    case SEARCH_LOCATIONS:
      return {
        locations: action.payload.data,
        filter: {
          searchText: action.meta.searchText,
          showDeleted: action.meta.showDeleted,
        },
      };
    case SAVE_LOCATION: {
      const newLocations = _.concat([], state.locations);
      const index = _.findIndex(newLocations, l => l.id === action.payload.savedObject.id);
      if (index > -1) {
        newLocations[index] = action.payload.savedObject;
      }
      return Object.assign({}, state, { locations: newLocations });
    }
    default:
      return state;
  }
}
