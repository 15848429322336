import { GetWithToken } from '../../utils/ajax';
import {
  ROOT_URL,
  FETCH_LOCATION,
  FETCH_VALID_END_LOCATION,
  FETCH_VALID_VIA_LOCATION,
  FETCH_VALID_START_LOCATION,
} from '../constants';

export function fetchLocation(locationName, token) {
  const response = GetWithToken(
    `${ROOT_URL}/location/locationname/${locationName}`,
    token,
  );
  return {
    type: FETCH_LOCATION,
    payload: response,
  };
}

export function fetchValidEndLocation(
  locationName,
  startLocation,
  viaLocation,
  token,
) {
  const response = GetWithToken(
    `${ROOT_URL}/location/validendlocationname/${locationName}/${startLocation}/${viaLocation}`,
    token,
  );
  return {
    type: FETCH_VALID_END_LOCATION,
    payload: response,
  };
}

export function fetchValidViaLocation(
  locationName,
  startLocation,
  endLocation,
  token,
) {
  const response = GetWithToken(
    `${ROOT_URL}/location/validvialocationname/${locationName}/${startLocation}/${endLocation}`,
    token,
  );
  return {
    type: FETCH_VALID_VIA_LOCATION,
    payload: response,
  };
}

export function fetchValidStartLocation(
  locationName,
  viaLocation,
  endLocation,
  token,
) {
  const response = GetWithToken(
    `${ROOT_URL}/location/validstartlocationname/${locationName}/${viaLocation}/${endLocation}`,
    token,
  );
  return {
    type: FETCH_VALID_START_LOCATION,
    payload: response,
  };
}
