export default {
  allocationAllocateConfirmationMessage: 'Are you sure you want to allocate {0}',
  allocationAllocateInvalidRequestMessage: 'Allocating {0} is not allowed',
  allocationAllocateSuccessMessage: 'Successfully allocated {0}',
  allocationAllocateToDiagramDetail: '{0} to {1}',
  allocationAllocateToDiagramLegDetail: '{0} to {1} at {2}',
  allocationDeallocateConfirmationMessage: 'Are you sure you want to deallocate {0}',
  allocationDeallocateInvalidRequestMessage: 'Deallocating {0} is not allowed',
  allocationDeallocateSuccessMessage: 'Successfully deallocated {0}',
  allocationDeallocateFromDiagramDetail: '{0} from {1}',
  allocationDeallocateFromDiagramLegDetail: '{0} from {1} starting at {2}',
  allocationDiagram: 'Diagram',
  allocationDiagramClass: 'Class',
  allocationDiagramFilterEndTime: 'End Time',
  allocationDiagramFilterStartTime: 'Start Time',
  allocationDiagramFinish: 'Finish',
  allocationDiagramIntervention: 'Int',
  allocationDiagramLegArrival: 'Arrival',
  allocationDiagramLegDeparture: 'Departure',
  allocationDiagramLegFormation: 'Formation',
  allocationDiagramLegPerform: 'Perform',
  allocationDiagramNoOfVehicles: '# Veh',
  allocationDiagramStart: 'Start',
  allocationReallocateConfirmationMessage: 'Are you sure you want to allocate {0}',
  allocationReallocateInvalidRequestMessage: 'Allocating {0} is not allowed',
  allocationReallocateSuccessMessage: 'Successfully allocated {0}',
  allocationReallocateToDiagramLegDetail: '{0} to {1} starting at {2} from {3} starting at {4}',
  allocationUnitDeallocateTooltip: 'Remove',
  allocationUnitEodFuel: 'EOD Fuel',
  allocationUnitEODLocation: 'EOD Location',
  allocationUnitFuelLevel: 'Fuel Level',
  allocationUnitFuelLevelDiagram: 'Diagram',
  allocationUnitFuelLevelDistance: 'Distance',
  allocationUnitFuelLevelEndFuel: 'End Fuel',
  allocationUnitFuelLevelFinish: 'Finish',
  allocationUnitFuelLevelFinishLocation: 'Finish Location',
  allocationUnitFuelLevelFuel: 'Fuel',
  allocationUnitFuelLevelStart: 'Start',
  allocationUnitFuelLevelStartFuel: 'Start Fuel',
  allocationUnitFuelLevelStartLocation: 'Start Location',
  allocationUnitFuelLevelTotalCumulativeEODFuel: 'EOD Fuel',
  allocationUnitFuelLevelTotalCumulativeFuel: 'Total Fuel',
  allocationUnitFuelLevelTotalCumulativeDistance: 'Total Distance',
  allocationUnitFuelLevelTotalDistance: 'Total Dist.',
  allocationUnitFuelLevelTotalFuel: 'Total Fuel',
  allocationUnitNoOfVehicles: '# Veh',
  allocationUnitNotes: 'Notes',
  allocationUnitNoteClearedSuccessfully: 'Note Cleared Successfully',
  allocationUnitNoteSavedSuccessfully: 'Note Saved Successfully',
  allocationUnitNotesResolved: 'Resolved',
  allocationUnitNotesPlaceholder: 'Enter Notes',
  allocationUnitRestrictionAllowAllocation: 'Allow Allocation',
  allocationUnitRestrictionPrompt: 'Prompt',
  allocationUnitRestrictions: 'Restrictions',
  allocationUnitSODLocation: 'SOD Location',
  allocationUnitStatus: 'Status',
  allocationUnitSwapFuel: 'Swap fuel: {0}',
  allocationUnitsFromCache: 'E2M is currently unavailable, information about assets might be outdated',
  allocationUnitsFilterShowAvailable: 'Show Available',
  allocationUnitsFilterShowAll: 'Show All',
  allocationUnitsFilterUnit: 'Search Unit/Set',
  changeReasonPopupTitle: 'Change Reason',
  changeReasonSaveOrUpdateFailureMessage: 'Error in saving reason',
  changeReasonSaveOrUpdateSuccessMessage: 'Successfully saved reason {0}',
  changeReasonSaveOrUpdateValidationFailedMessage: 'Invalid Reason Details',
  commonAdd: 'Add',
  commonArrival: 'Arrival',
  commonCancel: 'Cancel',
  commonClear: 'Clear',
  commonClearSearch: 'Clear Search',
  commonCode: 'Code',
  commonDiagram: 'Diagram',
  commonDelete: 'Delete',
  commonDeparture: 'Departure',
  commonDescription: 'Description',
  commonDistance: 'Distance',
  commonFillColour: 'Fill colour',
  commonFrom: 'From',
  commonHeadCode: 'Head Code',
  commonLocation: 'Location',
  commonNo: 'No',
  commonOk: 'Ok',
  commonOperationInProgress: 'Operation in progress...',
  commonQuickSearch: 'Quick Search',
  commonRestore: 'Restore',
  commonSave: 'Save',
  commonSearch: 'Search',
  commonShowDeleted: 'Show Deleted',
  commonTextColour: 'Text colour',
  commonTime: 'Time',
  commonTo: 'To',
  commonTotalDistance: 'Total Distance',
  commonUnit: 'Unit/Set',
  commonValidationFieldAlphaNumeric: '{0} should consist of alpha-numeric characters.',
  commonValidationFieldFormat: '{0} value is incorrect (use {1} format).',
  commonValidationFieldFormatType24H: '24 hour',
  commonValidationFieldIncorrect: '{0} value is incorrect.',
  commonValidationFieldMandatory: '{0} is mandatory.',
  commonValidationFieldMax: '{0} must not be greater than {1}.',
  commonValidationFieldMaxLength: '{0} must not be greater than {1} characters.',
  commonValidationFieldMin: '{0} must not be lower than {1}.',
  commonValidationFieldNumeric: '{0} should be numeric.',
  commonVia: 'Via',
  commonYes: 'Yes',
  eodValidationActionDescription: 'Validate that all movements are correct for the {0}',
  eodValidationConfirmation: 'This action permanently marks the diagrams/services for {0} as read only. Are you sure you wish to continue ?',
  eodValidationSuccessMessage: 'End of day validation successful',
  eodValidationValidateButton: 'Validate',
  fleetAddPopupTitle: 'Add Fleet',
  fleetEditPopupTitle: 'Edit Fleet',
  fleetFormationType: 'Formation Type',
  fleetSaveOrUpdateFailureMessage: 'Error in saving Fleet',
  fleetSaveOrUpdateSuccessMessage: 'Successfully saved Fleet {0}',
  fleetSaveOrUpdateValidationFailedMessage: 'Invalid Fleet Details',
  locationAddPopupTitle: 'Add Location',
  locationDeleteConfirmation: 'Are you sure you want to delete {0}?',
  locationDeleteErrorMessage: 'Error in deleting {0}',
  locationDeleteSucessMessage: 'Successfully deleted {0}',
  locationDistancePopupTitle: 'Location Distance',
  locationDistanceSaveOrUpdateValidationFailedMessage: 'Invalid location distance details',
  locationDistanceSaveFailureMessage: 'Error in saving location distance details',
  locationDistanceSaveSuccessMessage: 'Successfully saved location distance details !',
  locationDistanceUpdateFailureMessage: 'Error in updating location distance details',
  locationDistanceUpdateSuccessMessage: 'Successfully updated location distance details !',
  locationEditPopupTitle: 'Edit Location',
  locationIntervention: 'Intervention',
  locationRestoreConfirmation: 'Are you sure you want to restore {0}?',
  locationRestoreErrorMessage: 'Error in restoring {0}',
  locationRestoreSucessMessage: 'Successfully restored {0}',
  locationSaveOrUpdateFailureMessage: 'Error in saving location',
  locationSaveOrUpdateSuccessMessage: 'Successfully saved location {0}',
  locationSaveOrUpdateValidationFailedMessage: 'Invalid Location Details',
  locationSearchPlaceholder: 'Location Search',
  locationShortCode: 'Short Code',
  locationTiploc: 'Tiploc',
  sideMenuAllocation: 'Allocation',
  sideMenuChangeReason: 'Change Reason',
  sideMenuEODValidation: 'End Of Day Validation',
  sideMenuFleets: 'Fleets',
  sideMenuLocationDistances: 'Location Distances',
  sideMenuLocations: 'Locations',
  sideMenuRestrictions: 'Restrictions',
  sideMenuSpecialTrafficMovement: 'Special Traffic Movement',
  specialTrafficAdjustment: 'Adjustment',
  specialTrafficAdjustmentSaveError: 'Adjustment can not be saved!',
  specialTrafficAdjustmentSaveSuccess: 'Adjustment saved successfully',
  specialTrafficIsSpecialTraffic: 'Special Traffic',
  specialTrafficEndLocation: 'End Location',
  specialTrafficEndTime: 'End Time',
  specialTrafficMovement: 'Special Traffic Movement',
  specialTrafficMovementSaveError: 'Special movement can not be saved!',
  specialTrafficMovementSaveSuccess: 'Special movement saved successfully',
  specialTrafficReason: 'Reason',
  specialTrafficValidationErrorEndTimeSooner: 'Time value is incorrect, End Time should not be sooner than Start Time!.',
  specialTrafficViaLocation: 'Via Location',
  specialTrafficSaveConfirmation: 'This action is permanent and will update the distance covered by {0}. Are you sure you wish to continue?',
  specialTrafficSaveErrorPopupHeader: 'Please enter the correct values!',
  specialTrafficSaveErrorPopupTitle: 'Input Error',
  specialTrafficSavePopupTitle: 'Save {0}',
  specialTrafficStartLocation: 'Start Location',
  specialTrafficStartTime: 'Start Time',
  specialTrafficUpdateWebgemini: 'Update webgemini',
  topMenuManageUsers: 'Manage Users',
  topMenuProfile: 'Profile',
  topMenuReportsLibrary: 'Reports Library',
  topMenuSignOut: 'Sign Out',
  restrictionAddPopupTitle: 'Add Restriction',
  restrictionAllowAllocation: 'Allow Allocation',
  restrictionEditPopupTitle: 'Edit Restriction',
  restrictionPrompt: 'Prompt',
  restrictionSaveOrUpdateFailureMessage: 'Error in saving Restriction',
  restrictionSaveOrUpdateSuccessMessage: 'Successfully saved Restriction {0}',
  restrictionSaveOrUpdateValidationFailedMessage: 'Invalid Restriction Details',
  restrictionShowPrompt: 'Show Prompt',
  yesCancelPopupTitleConfirmation: 'Confirmation',
  yesCancelPopupTitleInformation: 'Information',
  yesCancelPopupTitleWarning: 'Warning',
};
