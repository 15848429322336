import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import Autocomplete from 'react-autocomplete';
import Modal from 'react-bootstrap-modal';
import PropTypes from 'prop-types';
import { fetchLocation } from '../../specialMovements/actions/specialLocations';
import { distanceSave, distanceUpdate } from '../actions/distanceSave';
import { TrimbleButton } from '../../components/FormComponents';
import messages from '../../messages';

class DistanceModal extends Component {
  static updateDisplayValue(text, autocompleteData) {
    const autoCompleteFirst = autocompleteData[0];
    if (autoCompleteFirst && autoCompleteFirst.label === text) {
      return autocompleteData[0].label;
    }
    return '';
  }

  static getItemValue(item) {
    return `${item.label}`;
  }

  static renderItem(item, isHighlighted) {
    return (
      <div
        style={{ background: isHighlighted ? 'lightgray' : 'white' }}
        key={item.value}
      >
        {item.label}
      </div>
    );
  }

  constructor(props) {
    super(props);
    this.render = this.render.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.saveSuccessCallback = this.saveSuccessCallback.bind(this);
    this.onKeyPress = DistanceModal.onKeyPress.bind(this);
    this.onFromChange = this.onFromChange.bind(this);
    this.onToChange = this.onToChange.bind(this);
    this.onViaChange = this.onViaChange.bind(this);
    this.onDistanceChange = this.onDistanceChange.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onFromSelect = this.onFromSelect.bind(this);
    this.onToSelect = this.onToSelect.bind(this);
    this.onViaSelect = this.onViaSelect.bind(this);
    this.initialiseDataAsynchronously =
      this.initialiseDataAsynchronously.bind(this);
    this.retrieveFromDataAsynchronously =
      this.retrieveFromDataAsynchronously.bind(this);
    this.retrieveToDataAsynchronously =
      this.retrieveToDataAsynchronously.bind(this);
    this.retrieveViaDataAsynchronously =
      this.retrieveViaDataAsynchronously.bind(this);

    let fromLoc = '';
    let toLoc = '';
    let viaLoc = '';
    let distance = 0;
    if (this.props.locationDistance) {
      if (
        this.props.locationDistance.fromLocation !== null &&
        this.props.locationDistance.fromLocation !== 'undefined'
      ) {
        fromLoc = this.props.locationDistance.fromLocation;
      }
      if (
        this.props.locationDistance.toLocation !== null &&
        this.props.locationDistance.toLocation !== 'undefined'
      ) {
        toLoc = this.props.locationDistance.toLocation;
      }
      if (
        this.props.locationDistance.viaLocation !== null &&
        this.props.locationDistance.viaLocation !== 'undefined'
      ) {
        viaLoc = this.props.locationDistance.viaLocation;
      }
      if (
        this.props.locationDistance.value !== null &&
        this.props.locationDistance.value !== 'undefined'
      ) {
        distance = this.props.locationDistance.value;
      }
      this.state = { id: this.props.locationDistance.id };
    }

    this.state = {
      displayFromValue: fromLoc,
      fromValue: fromLoc,
      displayToValue: toLoc,
      toValue: toLoc,
      displayViaValue: viaLoc,
      viaValue: viaLoc,
      distanceValue: distance,
      fromAutocompleteData: [],
      toAutocompleteData: [],
      viaAutocompleteData: [],
    };
    this.fromAdd = createRef();
    this.toAdd = createRef();
    this.viaAdd = createRef();
    this.distanceAdd = createRef();
  }

  componentWillReceiveProps() {
    if (this.props.locationDistance) {
      let fromLoc = '';
      let toLoc = '';
      let viaLoc = '';
      let distance = 0;
      if (
        this.props.locationDistance.fromLocation !== null &&
        this.props.locationDistance.fromLocation !== 'undefined'
      ) {
        fromLoc = this.props.locationDistance.fromLocation;
      }
      if (
        this.props.locationDistance.toLocation !== null &&
        this.props.locationDistance.toLocation !== 'undefined'
      ) {
        toLoc = this.props.locationDistance.toLocation;
      }
      if (
        this.props.locationDistance.viaLocation !== null &&
        this.props.locationDistance.viaLocation !== 'undefined'
      ) {
        viaLoc = this.props.locationDistance.viaLocation;
      }
      if (
        this.props.locationDistance.value !== null &&
        this.props.locationDistance.value !== 'undefined'
      ) {
        distance = this.props.locationDistance.value;
      }
      this.setState({ id: this.props.locationDistance.id });
      this.setState({
        fromValue: fromLoc,
        displayFromValue: fromLoc,
      });
      this.setState({
        toValue: toLoc,
        displayToValue: toLoc,
      });
      this.setState({
        viaValue: viaLoc,
        displayViaValue: viaLoc,
      });
      this.setState({ distanceValue: distance });
    } else {
      this.resetStatus();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.show !== prevProps.show && this.props.show) {
      this.initialiseDataAsynchronously();
    }
  }

  onCancel() {
    this.fromAdd.current.value = '';
    this.toAdd.current.value = '';
    this.viaAdd.current.value = '';
    this.distanceAdd.current.value = '';

    this.resetStatus();

    this.props.handleHide();
  }

  onFromChange(e) {
    if (e.target.value === '') {
      this.setState({
        fromValue: '',
        displayFromValue: '',
      });
      this.retrieveFromDataAsynchronously('undefined');
    } else {
      this.retrieveFromDataAsynchronously(e.target.value);
      const value = DistanceModal.updateDisplayValue(
        e.target.value,
        this.state.fromAutocompleteData,
      );
      this.setState({
        fromValue: e.target.value,
        displayFromValue: value,
      });
    }
  }

  onFromSelect(val) {
    this.setState({
      fromValue: val,
      displayFromValue: val,
    });
  }

  onToChange(e) {
    if (e.target.value === '') {
      this.setState({
        toValue: '',
        displayToValue: '',
      });
      this.retrieveToDataAsynchronously('undefined');
    } else {
      this.retrieveToDataAsynchronously(e.target.value);
      const value = DistanceModal.updateDisplayValue(
        e.target.value,
        this.state.toAutocompleteData,
      );
      this.setState({
        toValue: e.target.value,
        displayToValue: value,
      });
    }
  }

  onToSelect(val) {
    this.setState({
      toValue: val,
      displayToValue: val,
    });
  }

  static onKeyPress(e) {
    e.preventDefault();
  }

  onViaChange(e) {
    if (e.target.value === '') {
      this.setState({
        viaValue: '',
        displayViaValue: '',
      });
      this.retrieveViaDataAsynchronously('undefined');
    } else {
      this.retrieveViaDataAsynchronously(e.target.value);
      const value = DistanceModal.updateDisplayValue(
        e.target.value,
        this.state.viaAutocompleteData,
      );
      this.setState({
        viaValue: e.target.value,
        displayViaValue: value,
      });
    }
  }

  onViaSelect(val) {
    this.setState({
      viaValue: val,
      displayViaValue: val,
    });
  }

  onDistanceChange(e) {
    this.setState({ distanceValue: e.target.value });
  }

  resetStatus() {
    this.setState({
      fromValue: '',
      displayFromValue: '',
      toValue: '',
      displayToValue: '',
      viaValue: '',
      displayViaValue: '',
      distanceValue: 0,
    });
  }

  saveSuccessCallback() {
    this.onCancel();
    if (this.props.onAfterSave) {
      this.props.onAfterSave();
    }
  }

  initialiseDataAsynchronously() {
    // let _this = this;
    this.props.fetchLocation('undefined').then((result) => {
      this.setState({
        fromAutocompleteData: result.payload.data,
        toAutocompleteData: result.payload.data,
        viaAutocompleteData: result.payload.data,
      });
    });
  }

  retrieveFromDataAsynchronously(searchText) {
    // let _this = this;
    this.props.fetchLocation(searchText).then((result) => {
      this.setState({
        fromAutocompleteData: result.payload.data,
      });
    });
  }

  retrieveToDataAsynchronously(searchText) {
    // let _this = this;
    this.props.fetchLocation(searchText).then((result) => {
      this.setState({
        toAutocompleteData: result.payload.data,
      });
    });
  }

  retrieveViaDataAsynchronously(searchText) {
    // let _this = this;
    this.props.fetchLocation(searchText).then((result) => {
      this.setState({
        viaAutocompleteData: result.payload.data,
      });
    });
  }

  handleSave() {
    if (this.props.save) {
      this.props.distanceSave(
        this.state.displayFromValue,
        this.state.displayToValue,
        this.state.displayViaValue,
        this.state.viaValue,
        this.state.distanceValue, () => this.saveSuccessCallback(),
      );
    } else {
      this.props.distanceUpdate(
        this.state.id,
        this.state.displayFromValue,
        this.state.displayToValue,
        this.state.displayViaValue,
        this.state.viaValue,
        this.state.distanceValue, () => this.saveSuccessCallback(),
      );
    }
  }

  render() {
    const style = {
      borderRadius: '3px',
      boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
      background: 'rgba(255, 255, 255, 0.9)',
      padding: '2px 0',
      fontSize: '90%',
      position: 'fixed',
      overflow: 'auto',
      maxHeight: '50%',
      top: 'auto',
      left: 'auto',
      zIndex: 1,
    };
    return (
      <Modal
        onHide={this.props.handleHide}
        show={this.props.show}
        backdrop="static"
        className="appModal"
      >
        <Modal.Header closeButton>
          <Modal.Title>{messages.locationDistancePopupTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="form-group row">
              <label
                htmlFor="fromLocationInput"
                className="col-sm-2 col-form-label"
              >
                {messages.commonFrom}
              </label>
              <div className="col-sm-10">
                <Autocomplete
                  getItemValue={DistanceModal.getItemValue}
                  items={this.state.fromAutocompleteData}
                  renderItem={DistanceModal.renderItem}
                  value={this.state.fromValue}
                  onChange={this.onFromChange}
                  onSelect={this.onFromSelect}
                  ref={this.fromAdd}
                  inputProps={{
                    id: 'fromLocationInput',
                    className: 'form-control',
                  }}
                  menuStyle={style}
                  wrapperStyle={{ display: 'inherit' }}
                />
              </div>
            </div>
            <div className="form-group row">
              <label
                htmlFor="toLocationInput"
                className="col-sm-2 col-form-label"
              >
                {messages.commonTo}
              </label>
              <div className="col-sm-10">
                <Autocomplete
                  getItemValue={DistanceModal.getItemValue}
                  items={this.state.toAutocompleteData}
                  renderItem={DistanceModal.renderItem}
                  value={this.state.toValue}
                  onChange={this.onToChange}
                  onSelect={this.onToSelect}
                  ref={this.toAdd}
                  inputProps={{
                    id: 'toLocationInput',
                    className: 'form-control',
                  }}
                  menuStyle={style}
                  wrapperStyle={{ display: 'inherit' }}
                />
              </div>
            </div>
            <div className="form-group row">
              <label
                htmlFor="viaLocationInput"
                className="col-sm-2 col-form-label"
              >
                {messages.commonVia}
              </label>
              <div className="col-sm-10">
                <Autocomplete
                  getItemValue={DistanceModal.getItemValue}
                  items={this.state.viaAutocompleteData}
                  renderItem={DistanceModal.renderItem}
                  value={this.state.viaValue}
                  onChange={this.onViaChange}
                  onSelect={this.onViaSelect}
                  ref={this.viaAdd}
                  inputProps={{
                    id: 'viaLocationInput',
                    className: 'form-control',
                  }}
                  menuStyle={style}
                  wrapperStyle={{ display: 'inherit' }}
                />
              </div>
            </div>
            <div className="form-group row">
              <label
                htmlFor="distanceInput"
                className="col-sm-2 col-form-label"
              >
                {messages.commonDistance}
              </label>
              <div className="col-sm-10">
                <input
                  type="text"
                  className="form-control"
                  ref={this.distanceAdd}
                  id="distanceInput"
                  value={this.state.distanceValue}
                  onChange={this.onDistanceChange}
                />
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <TrimbleButton
            label={messages.commonCancel}
            onClick={() => this.onCancel()}
          />
          <TrimbleButton
            label={messages.commonSave}
            onClick={() => this.handleSave()}
          />
        </Modal.Footer>
      </Modal>
    );
  }
}

DistanceModal.propTypes = {
  locationDistance: PropTypes.shape({
    id: PropTypes.number,
    fromLocation: PropTypes.string,
    toLocation: PropTypes.string,
    viaLocation: PropTypes.string,
    value: PropTypes.number,
  }),
  show: PropTypes.bool,
  fetchLocation: PropTypes.func,
  save: PropTypes.bool,
  distanceSave: PropTypes.func,
  distanceUpdate: PropTypes.func,
  onAfterSave: PropTypes.func,
  handleHide: PropTypes.func,
};
DistanceModal.defaultProps = {
  locationDistance: null,
  fetchLocation: null,
  distanceSave: null,
  show: false,
  save: true,
  distanceUpdate: null,
  onAfterSave: null,
  handleHide: null,
};

export default connect(null, { fetchLocation, distanceSave, distanceUpdate })(DistanceModal);
