import { Get } from '../../utils/ajax';
import { ROOT_URL, FETCH_CHANGE_REASON, SAVE_CHANGE_REASON, REASON_CODE_LENGTH, REASON_DESCRIPTION_LENGTH } from '../constants';
import { createSaveAction } from '../../utils/actionHelper';
import { validateMandatoryFields } from '../../utils/validation';
import messages from '../../messages';

function validateChangeReason(item) {
  const errors = [];
  validateMandatoryFields(
    item,
    {
      code: { name: messages.commonCode, maxlength: REASON_CODE_LENGTH },
      description: { name: messages.commonDescription, maxlength: REASON_DESCRIPTION_LENGTH },
    },
    errors,
  );
  return errors;
}

export function fetchChangeReason(changeReason) {
  let response;
  if (changeReason === '') {
    response = Get(`${ROOT_URL}/reason/getReasons/undefined`);
  } else {
    response = Get(`${ROOT_URL}/reason/getReasons/${changeReason}`);
  }
  return {
    type: FETCH_CHANGE_REASON,
    payload: response,
  };
}

export default function saveReason(reason, successCallback) {
  return createSaveAction({
    saveObject: reason,
    validationFunction: validateChangeReason,
    validationFailedMsag: messages.changeReasonSaveOrUpdateValidationFailedMessage,
    saveUrl: `${ROOT_URL}/reason/saveOrUpdate`,
    errorInSavingMsg: messages.changeReasonSaveOrUpdateFailureMessage,
    successfullySavedMsg: messages.formatString(messages.changeReasonSaveOrUpdateSuccessMessage, reason.description || ''),
    dispatchType: SAVE_CHANGE_REASON,
    successCallback,
  });
}
