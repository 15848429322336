import _ from 'lodash';
import moment from 'moment';
import { Get, Post } from '../../utils/ajax';
import { ROOT_URL, FETCH_DIAGRAM, EXPAND_DIAGRAM } from '../constants';
import getDiagramKey from '../util';

function supplierIfNull(value, supplier) {
  return value == null ? supplier() : value;
}

function undefinedStrIfNull(value) {
  return supplierIfNull(value, () => 'undefined');
}

function fetchDiagrams(
  date, filter, location,
  arrivalTimeFrom, arrivalTimeTo, departureTimeFrom, departureTimeTo,
  sort, existingDiagrams,
) {
  const filterDate = supplierIfNull(date, () => moment());
  const filterString = undefinedStrIfNull(filter);
  const filterLocation = undefinedStrIfNull(location);
  const filterArrivalTimeFrom = undefinedStrIfNull(arrivalTimeFrom);
  const filterArrivalTimeTo = undefinedStrIfNull(arrivalTimeTo);
  const filterDepartureTimeFrom = undefinedStrIfNull(departureTimeFrom);
  const filterDepartureTimeTo = undefinedStrIfNull(departureTimeTo);
  const filterSort = supplierIfNull(sort, () => '+name');
  const expandedDiagramIds = (existingDiagrams || [])
    .filter(d => d.expanded)
    .map(getDiagramKey).reduce(
      (prev, cur) => `${prev}${prev.length > 0 ? ',' : ''}${cur}`,
      '',
    );
  const param = `?expanded=${expandedDiagramIds}`;
  const filterDateAsString = filterDate.format('YYYY-MM-DD');
  const response = Get(`${ROOT_URL}/diagramAllocation/${filterDateAsString}/${filterString}/${filterLocation}/${filterArrivalTimeFrom}/${filterArrivalTimeTo}/${filterDepartureTimeFrom}/${filterDepartureTimeTo}/${filterSort}/${param}`);
  return {
    type: FETCH_DIAGRAM,
    payload: response,
    meta: {
      filter: {
        date,
        filter,
        location,
        arrivalTimeFrom,
        arrivalTimeTo,
        departureTimeFrom,
        departureTimeTo,
        sort: filterSort, /* since other parts of the app need to know this */
      },
    },
  };
}

export default function fetchDiagramList(
  date,
  filter,
  location,
  arrivalTimeFrom,
  arrivalTimeTo,
  departureTimeFrom,
  departureTimeTo,
) {
  const result = (dispatch, getState) => {
    const state = getState();
    dispatch(fetchDiagrams(
      date,
      filter,
      location,
      arrivalTimeFrom,
      arrivalTimeTo,
      departureTimeFrom,
      departureTimeTo,
      state.diagram.filter.sort,
      state.diagram.diagrams,
    ));
  };
  return result;
}

export function expandDiagram(diagram) {
  let response = null;
  if (!diagram.legsLoaded) {
    const legAndOrderIds = _.concat([], diagram.diagramLegIds);
    _.forEach(diagram.diagramLegOrderIds, (orderId, index) => {
      legAndOrderIds[index] = `${legAndOrderIds[index]}-${orderId}`;
    });
    response = Post(
      `${ROOT_URL}/diagramAllocation/diagramLegs/${diagram.type}/${diagram.diagramId}`,
      legAndOrderIds,
    );
  }
  return {
    type: EXPAND_DIAGRAM,
    payload: response,
    meta: {
      diagram,
    },
  };
}

export function sortDiagrams(sort) {
  const result = (dispatch, getState) => {
    const state = getState();
    const { filter } = state.diagram;
    dispatch(fetchDiagrams(
      filter.date,
      filter.filter,
      filter.location,
      filter.arrivalTimeFrom,
      filter.arrivalTimeTo,
      filter.departureTimeFrom,
      filter.departureTimeTo,
      sort,
      state.diagram.diagrams,
    ));
  };
  return result;
}
