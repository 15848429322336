import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import fetchAsset from '../allocations/reducers/reducerAsset';
import fetchAllDistances from '../locationDistances/reducers/reducerLocationDistance';
import fetchDiagram from '../allocations/reducers/reducerDiagram';
import fetchChangeReason from '../changeReason/reducers/reducerChangeReason';
import fetchLocation from '../specialMovements/reducers/reducerSpecial';
import yesCancelModalDataReducer from './YesCancel';
import currentDiagramDate from './currentDiagramDate';
import searchLocations from '../locations/reducers/searchLocations';
import searchRestrictions from '../restrictions/reducers/searchRestrictions';
import searchFleets from '../fleets/reducers/fleets';
import clientSettings from '../reducers/clientSettings';

const rootReducer = combineReducers({
  form: formReducer,
  asset: fetchAsset,
  changeReason: fetchChangeReason,
  diagram: fetchDiagram,
  location: fetchLocation,
  yesCancelModalData: yesCancelModalDataReducer,
  currentDiagramDate,
  locationDistance: fetchAllDistances,
  searchLocations,
  searchRestrictions,
  searchFleets,
  clientSettings,
});

export default rootReducer;
