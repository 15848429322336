import { Get } from '../utils/ajax';
import { ROOT_URL, FETCH_CLIENT_SETTINGS } from '../constants';

export default function fetchClientSettings() {
  const response = Get(`${ROOT_URL}/clientSettings`);
  return {
    type: FETCH_CLIENT_SETTINGS,
    payload: response,
  };
}
