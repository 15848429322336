import React from 'react';
import { connect } from 'react-redux';
import saveLocation from '../actions/saveLocation';
import GenericFormModal from '../../components/GenericFormModal';
import {
  LOCATION_CODE_LENGTH,
  LOCATION_DESCRIPTION_LENGTH,
  LOCATION_TIPLOC_LENGTH,
} from '../constants';
import messages from '../../messages';

class LocationModal extends GenericFormModal {
  renderBody() {
    return (
      <span>
        {this.renderInputField(messages.locationShortCode, 'code', LOCATION_CODE_LENGTH)}
        {this.renderInputField(messages.commonDescription, 'description', LOCATION_DESCRIPTION_LENGTH)}
        {this.renderInputField(messages.locationTiploc, 'tiploc', LOCATION_TIPLOC_LENGTH)}
        {this.renderColorPicker(messages.commonFillColour, 'fillColour')}
        {this.renderColorPicker(messages.commonTextColour, 'textColour')}
        {this.renderCheckboxField(messages.locationIntervention, 'intervention')}
      </span>
    );
  }
}

export default connect(null, { saveFormModal: saveLocation })(LocationModal);
