import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import Alert from 'react-s-alert';
import 'react-s-alert/dist/s-alert-default.css';
import Allocation from '../allocations/Allocation';
import SpecialMovements from '../specialMovements/SpecialMovements';
import LocationDistances from '../locationDistances/LocationDistances';
import Side from './Side';
import Top from './Top';
import { LicencedRoute } from './licenced_components';
import {
  ALLOCATION_SCREEN,
  SPECIAL_MOVEMENT_SCREEN,
  EOD_VALIDATION_SCREEN,
  LOCATION_DISTANCE_SCREEN,
  LOCATIONS_SCREEN_VIEW,
  CHANGE_REASON_SCREEN,
  RESTRICTIONS_SCREEN_VIEW,
  FLEETS_SCREEN_VIEW,
} from '../kc_roles';

import YesCancelModal from './YesCancelModal';
import LoadingSpinner from './LoadingSpinner';
import EodValidation from '../eodValidation/eodValidation';
import Locations from '../locations/locations';
import ChangeReason from '../changeReason/ChangeReason';
import Restrictions from '../restrictions/restrictions';
import Fleets from '../fleets/fleets';
import '../base.css';

const App = () => (
  <BrowserRouter>
    <div>
      <Top />
      <section id="main">
        <Side />
        <section id="content">
          <Switch>
            <LicencedRoute
              kcRole={ALLOCATION_SCREEN}
              path="/allocations"
              uiComponent={Allocation}
            />
            <LicencedRoute
              kcRole={SPECIAL_MOVEMENT_SCREEN}
              path="/specialMovements"
              uiComponent={SpecialMovements}
            />
            <LicencedRoute
              kcRole={EOD_VALIDATION_SCREEN}
              path="/eodValidation"
              uiComponent={EodValidation}
            />
            <LicencedRoute
              kcRole={LOCATION_DISTANCE_SCREEN}
              path="/locationDistances"
              uiComponent={LocationDistances}
            />
            <LicencedRoute
              kcRole={LOCATIONS_SCREEN_VIEW}
              path="/locations"
              uiComponent={Locations}
            />
            <LicencedRoute
              kcRole={CHANGE_REASON_SCREEN}
              path="/changeReason"
              uiComponent={ChangeReason}
            />
            <LicencedRoute
              kcRole={RESTRICTIONS_SCREEN_VIEW}
              path="/restrictions"
              uiComponent={Restrictions}
            />
            <LicencedRoute
              kcRole={FLEETS_SCREEN_VIEW}
              path="/fleets"
              uiComponent={Fleets}
            />
          </Switch>
          <YesCancelModal />
          <LoadingSpinner />
        </section>
      </section>
      <Alert
        timeout={3000}
        stack={{ limit: 4 }}
        position="bottom-right"
      />
    </div>
  </BrowserRouter>
);

export default App;
