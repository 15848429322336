import 'babel-polyfill';
import React from 'react';
import Keycloak from 'keycloak-js';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import ReduxPromise from 'redux-promise';
import ThunkMiddleware from 'redux-thunk';
import appState from './reducers';
import App from './components/App';
import fetchClientSettings from './actions/clientSettings';
import { ROOT_URL } from './constants';
import messages from './messages';

const store = createStore(
  appState,
  applyMiddleware(ReduxPromise, ThunkMiddleware),
);

const app = (
  React.createElement(
    Provider,
    { store },
    React.createElement(App, null),
  )
);

export const appInitParams = {};

const kc = Keycloak(`${ROOT_URL}/realm`);

export const updateLocalStorage = () => {
  localStorage.setItem('kc_token', kc.token);
  localStorage.setItem('kc_refreshToken', kc.refreshToken);
};

export const hasStoredToken = () => localStorage.getItem('kc_token') !== null;

const initApp = (profile) => {
  const { attributes } = profile || {};
  const { locale } = attributes || {};
  appInitParams.locale = locale || 'en';
  messages.setLanguage(appInitParams.locale);
  ReactDOM.render(app, document.querySelector('.container-full'));
  store.dispatch(fetchClientSettings());
};

const token = localStorage.getItem('kc_token');
const refreshToken = localStorage.getItem('kc_refreshToken');

kc.init({
  onLoad: 'login-required',
  checkLoginIframe: false,
  token,
  refreshToken,
})
  .success((authenticated) => {
    if (authenticated) {
      updateLocalStorage();
      kc.loadUserProfile().success((profile) => {
        initApp(profile);
      }).error(() => {
        initApp();
      });
    }
  });

export default kc;

