import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { showConfirmation } from '../actions/YesCancel';
import fetchCurrentDiagramDate from '../actions/currentDiagramDate';
import doEodValidation from './actions/eodValidation';
import { TrimbleButton } from '../components/FormComponents';
import messages from '../messages';

class EodValidation extends React.Component {
  componentDidMount() {
    this.props.fetchCurrentDiagramDate();
  }

  onValidateClick(confirmed) {
    if (confirmed) {
      this.props.doEodValidation();
    } else {
      this.props.showConfirmation(
        messages.formatString(messages.eodValidationConfirmation, this.getDiagramDate()),
        () => this.onValidateClick(true),
      );
    }
  }

  getDiagramDate() {
    return moment(this.props.currentDiagramDate).format('DD/MM/YYYY');
  }

  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-xs-12">
            &nbsp;
          </div>
        </div>
        <div className="row">
          <div className="col-xs-9">
            <h5>
              {messages.formatString(messages.eodValidationConfirmation, this.getDiagramDate())}
            </h5>
          </div>
          <div className="col-xs-3">
            <TrimbleButton
              label={messages.eodValidationValidateButton}
              onClick={() => this.onValidateClick(false)}
            />
          </div>
        </div>
      </div>
    );
  }
}

EodValidation.propTypes = {
  fetchCurrentDiagramDate: PropTypes.func,
  doEodValidation: PropTypes.func,
  showConfirmation: PropTypes.func,
  currentDiagramDate: PropTypes.instanceOf(Date),
};
EodValidation.defaultProps = {
  fetchCurrentDiagramDate: null,
  doEodValidation: null,
  showConfirmation: null,
  currentDiagramDate: null,
};

function mapStateToProps({ currentDiagramDate }) {
  return { currentDiagramDate };
}

export default connect(
  mapStateToProps,
  { showConfirmation, doEodValidation, fetchCurrentDiagramDate },
)(EodValidation);
