import React from 'react';
import axios from 'axios';
import _ from 'lodash';
import { connect } from 'react-redux';
import Autocomplete from 'react-autocomplete';
import moment from 'moment';
import PropTypes from 'prop-types';
import { fetchLocation, fetchValidEndLocation, fetchValidViaLocation, fetchValidStartLocation } from './actions/specialLocations';
import fetchDistance from './actions/specialDistance';
import fetchAvailableAssets from './actions/specialAssets';
import fetchReason from './actions/specialReasons';
import { specialSave, adjustmentSave } from './actions/specialSave';
import showPopup from '../actions/YesCancel';
import { TrimbleButton } from '../components/FormComponents';
import messages from '../messages';
import './specialMovements.css';

function getAutoCompleteConfig(actionName, dataStateField, selectedStateField, tokenSource) {
  return {
    actionName, dataStateField, selectedStateField, tokenSource,
  };
}

function getFormattedCurrentTime() {
  return moment().format('HH:mm');
}

function renderItem(item, isHighlighted) {
  return (
    <div
      style={{ background: isHighlighted ? 'lightgray' : 'white' }}
      key={item.value}
    >
      {item.label}
    </div>
  );
}

function getItemValue(item) {
  return `${item.label}`;
}

function getValidatedTime(timeValue, errors, fieldName) {
  const timeMoment = moment(timeValue, 'H:m', true);
  if (!timeValue || timeValue === '' || timeValue === 'undefined') {
    errors.push(messages.formatString(messages.commonValidationFieldMandatory, fieldName));
  } else if (!timeMoment.isValid()) {
    errors.push(messages.formatString(
      messages.commonValidationFieldFormat,
      fieldName,
      messages.commonValidationFieldFormatType24H,
    ));
  }
  return timeMoment;
}

class SpecialMovements extends React.Component {
  constructor(props) {
    super(props);

    this.render = this.render.bind(this);
    const time = getFormattedCurrentTime();
    this.state = {
      type: 'specialTrafficMovement',
      startTime: time,
      endTime: time,
      headCodeValue: '',
      distanceValue: '0',
      wgStatus: true,
      isAdjustment: false,
      isSpecialTraffic: true,
      availableAssetsValue: '',
      availableAssetsData: [],
      startValue: '',
      startAutocompleteData: [],
      endValue: '',
      endAutocompleteData: [],
      viaValue: '',
      viaAutocompleteData: [],
      reasonValue: '',
      reasonAutocompleteData: [],
    };

    this.handleChangeSpecial = this.handleChangeSpecial.bind(this);
    this.specialValidate = this.specialValidate.bind(this);
    this.specialSubmit = this.specialSubmit.bind(this);
    this.resetValues = this.resetValues.bind(this);
    this.onTimeChange = this.onTimeChange.bind(this);
    this.onHeadCodeChange = this.onHeadCodeChange.bind(this);
    this.onDistanceChange = this.onDistanceChange.bind(this);
    this.onWGCheckChange = this.onWGCheckChange.bind(this);
    this.onLocationChange = this.onLocationChange.bind(this);
    this.initialiseDataAsynchronously = this.initialiseDataAsynchronously.bind(this);
    this.cancelToken = axios.CancelToken;
    this.autoCompleteConfigData = {
      UNITS: getAutoCompleteConfig('fetchAvailableAssets', 'availableAssetsData', 'availableAssetsValue', null),
      START_LOCATION: getAutoCompleteConfig('fetchValidStartLocation', 'startAutocompleteData', 'startValue', null),
      END_LOCATION: getAutoCompleteConfig('fetchValidEndLocation', 'endAutocompleteData', 'endValue', null),
      VIA_LOCATION: getAutoCompleteConfig('fetchValidViaLocation', 'viaAutocompleteData', 'viaValue', null),
      REASON: getAutoCompleteConfig('fetchReason', 'reasonAutocompleteData', 'reasonValue', null),
    };

    this.startTimeInput = React.createRef();
    this.endTimeInput = React.createRef();
  }

  componentDidMount() {
    this.initialiseDataAsynchronously();
  }

  onTimeChange(event) {
    event.preventDefault();
    // let startTime = ReactDOM.findDOMNode(this.startTimeInput).value.trim();
    // let endTime = ReactDOM.findDOMNode(this.endTimeInput).value.trim();
    let startTime = this.startTimeInput.current.value.trim();
    let endTime = this.endTimeInput.current.value.trim();
    if (startTime === '') {
      startTime = 'undefined';
    }
    if (endTime === '') {
      endTime = 'undefined';
    }
    this.setState({
      startTime,
      endTime,
    });
  }

  onHeadCodeChange(e) {
    this.setState({
      headCodeValue: e.target.value,
    });
  }

  onDistanceChange(e) {
    this.setState({
      distanceValue: e.target.value,
    });
  }

  onLocationChange() {
    // check if start and end is present
    if (this.state.startValue !== '' && this.state.endValue !== '') {
      this.retrieveDistanceDataAsynchronously();
    }
    this.retrieveLocationDataAsynchronously();
  }

  onWGCheckChange(e) {
    this.setState({
      wgStatus: e.target.checked,
    });
  }

  onAutoCompleteChange(type, e) {
    const { selectedStateField } = this.autoCompleteConfigData[type];
    const changes = {};
    changes[selectedStateField] = e.target.value;
    let searchText = e.target.value;
    if (e.target.value === '') {
      searchText = 'undefined';
    }
    this.setState(changes);
    this.retrieveAutocompleteData(type, searchText);
  }

  onAutoCompleteChangeWithParams(type, e, param1, param2) {
    const { selectedStateField } = this.autoCompleteConfigData[type];
    const changes = {};
    let theParam1 = param1;
    let theParam2 = param2;
    changes[selectedStateField] = e.target.value;
    let searchText = e.target.value;
    if (e.target.value === '') {
      searchText = 'undefined';
    }
    if (param1 === '') {
      theParam1 = 'undefined';
    }
    if (param2 === '') {
      theParam2 = 'undefined';
    }
    this.setState(changes);
    this.retrieveAutocompleteDataWithParams(type, searchText, theParam1, theParam2);
  }

  onAutoCompleteSelect(type, val) {
    const { selectedStateField, dataStateField } = this.autoCompleteConfigData[type];
    const changes = {};
    changes[selectedStateField] = val;
    this.setState(
      changes,
      this.getLocationDistancePopulationCallback(type, val, this.state[dataStateField]),
    );
  }

  getLocationDistancePopulationCallback(type, selectedAutoCompleteValue = '', autoCompleteData = []) {
    let callBack = null;
    if ((type === 'START_LOCATION' || type === 'END_LOCATION' || type === 'VIA_LOCATION')
      && _.findIndex(autoCompleteData, o => o.label === selectedAutoCompleteValue) >= 0) {
      callBack = () => this.onLocationChange();
    } else if (((type === 'START_LOCATION' || type === 'END_LOCATION' || type === 'VIA_LOCATION')
      && this.state.startValue === '' && this.state.endValue === '' && this.state.viaValue === '')) {
      callBack = () => this.onLocationChange();
    }
    return callBack;
  }

  getAutoCompleteItems(type) {
    const { dataStateField } = this.autoCompleteConfigData[type];
    const items = this.state[dataStateField];
    return items || [];
  }

  getSelectedAutoCompleteItem(type) {
    const { selectedStateField } = this.autoCompleteConfigData[type];
    const item = this.state[selectedStateField];
    return item || '';
  }

  isSelectedAutoCompleteValueValid(autoCompleteDataField, selectedField) {
    return _.findIndex(
      this.state[autoCompleteDataField],
      o => o.label === this.state[selectedField],
    ) >= 0;
  }

  validateAutoCompleteValue(autoCompleteDataField, selectedField, errors, fieldName) {
    if (!this.isSelectedAutoCompleteValueValid(autoCompleteDataField, selectedField)) {
      const fieldValue = this.state[selectedField];
      let errorMsg = '';
      if (!fieldValue || fieldValue === '') {
        errorMsg = messages.formatString(messages.commonValidationFieldMandatory, fieldName);
      } else {
        errorMsg = messages.formatString(messages.commonValidationFieldIncorrect, fieldName);
      }
      errors.push(errorMsg);
    }
  }

  specialValidate() {
    const errors = [];
    this.validateAutoCompleteValue('availableAssetsData', 'availableAssetsValue', errors, messages.commonUnit);
    if (this.state.type === 'specialTrafficMovement') {
      this.validateAutoCompleteValue('startAutocompleteData', 'startValue', errors, messages.specialTrafficStartLocation);
      this.validateAutoCompleteValue('endAutocompleteData', 'endValue', errors, messages.specialTrafficEndLocation);
      if (this.state.viaValue !== '') {
        this.validateAutoCompleteValue('viaAutocompleteData', 'viaValue', errors, messages.specialTrafficViaLocation);
      }
      const startMoment = getValidatedTime(
        this.state.startTime, errors,
        messages.specialTrafficStartTime,
      );
      const endMoment = getValidatedTime(
        this.state.endTime, errors,
        messages.specialTrafficEndTime,
      );
      if (startMoment.isValid() && endMoment.isValid() && !endMoment.isAfter(startMoment)) {
        errors.push(messages.specialTrafficValidationErrorEndTimeSooner);
      }
    }
    if (!this.isSelectedAutoCompleteValueValid('reasonAutocompleteData', 'reasonValue')) {
      errors.push(messages.formatString(
        messages.commonValidationFieldMandatory,
        messages.specialTrafficReason,
      ));
    }
    if (this.state.distanceValue === '' && this.state.type === 'specialTrafficAdjustment') {
      errors.push(messages.formatString(
        messages.commonValidationFieldMandatory,
        messages.commonDistance,
      ));
    }
    return errors;
  }

  specialSubmit() {
    const title = messages.formatString(
      messages.specialTrafficSavePopupTitle,
      messages[this.state.type],
    );
    const message = messages.formatString(
      messages.specialTrafficSaveConfirmation,
      this.state.availableAssetsValue,
    );
    const webGeminiStat = (this.state.wgStatus ? 1 : 0);

    const errors = this.specialValidate();
    const isValid = errors.length === 0;

    if (isValid) {
      if (this.state.type === 'specialTrafficMovement') {
        // params: (show, title, message, onactioncreator)
        this.props.showPopup(true, title, message, () => specialSave(
          this.state.availableAssetsValue,
          this.state.startValue,
          this.state.startTime,
          this.state.endValue,
          this.state.endTime,
          this.state.viaValue,
          this.state.headCodeValue,
          this.state.distanceValue,
          this.state.reasonValue,
          webGeminiStat,
          () => this.resetValues(),
        ));
      } else {
        // saving Adjustment
        // params: (show, title, message, onactioncreator)
        this.props.showPopup(true, title, message, () => adjustmentSave(
          this.state.availableAssetsValue,
          this.state.distanceValue,
          this.state.reasonValue,
          () => this.resetValues(),
        ));
      }
    } else {
      this.props.showPopup(
        true,
        messages.specialTrafficSaveErrorPopupTitle,
        messages.specialTrafficSaveErrorPopupHeader,
        null,
        errors,
      );
    }
  }

  resetValues() {
    const time = getFormattedCurrentTime();
    this.setState({
      startTime: time,
      endTime: time,
      headCodeValue: '',
      distanceValue: '0',
      availableAssetsValue: '',
      startValue: '',
      endValue: '',
      viaValue: '',
      reasonValue: '',
    });
    this.startTimeInput.current.value = time;
    this.endTimeInput.current.value = time;
    this.initialiseDataAsynchronously();
  }

  handleChangeSpecial() {
    if (this.state.isSpecialTraffic) {
      this.setState({
        isAdjustment: true,
        isSpecialTraffic: false,
        type: 'specialTrafficAdjustment',
        wgStatus: false,
      });
    } else {
      this.setState({
        isAdjustment: false,
        isSpecialTraffic: true,
        type: 'specialTrafficMovement',
        wgStatus: true,
      });
    }
  }

  initialiseDataAsynchronously() {
    this.props.fetchValidStartLocation('undefined', 'undefined', 'undefined').then((result) => {
      this.setState({
        startAutocompleteData: result.payload.data,
      });
    });
    this.props.fetchValidViaLocation('undefined', 'undefined', 'undefined').then((result) => {
      this.setState({
        viaAutocompleteData: result.payload.data,
      });
    });
    this.props.fetchValidEndLocation('undefined', 'undefined', 'undefined').then((result) => {
      this.setState({
        endAutocompleteData: result.payload.data,
      });
    });
    this.props.fetchAvailableAssets().then((result) => {
      this.setState({
        availableAssetsData: result.payload.data,
      });
    });
    this.props.fetchReason('undefined').then((result) => {
      this.setState({
        reasonAutocompleteData: result.payload.data,
      });
    });
  }

  retrieveAutocompleteData(type, searchText) {
    const autoCompleteConfigData = this.autoCompleteConfigData[type];
    let { tokenSource } = autoCompleteConfigData;
    const { actionName, dataStateField, selectedStateField } = autoCompleteConfigData;
    if (tokenSource) {
      tokenSource.cancel(`auto-complete search cancelled-${type}`);
    }
    tokenSource = this.cancelToken.source();
    autoCompleteConfigData.tokenSource = tokenSource;
    this.props[actionName](searchText, tokenSource.token).then((result) => {
      const changes = {};
      const autoCompleteData = result.payload.data;
      changes[dataStateField] = autoCompleteData;
      this.setState(
        changes,
        this.getLocationDistancePopulationCallback(
          type,
          this.state[selectedStateField],
          autoCompleteData,
        ),
      );
    });
  }

  retrieveAutocompleteDataWithParams(type, searchText, param1, param2) {
    const autoCompleteConfigData = this.autoCompleteConfigData[type];
    let { tokenSource } = autoCompleteConfigData;
    const { actionName, dataStateField, selectedStateField } = autoCompleteConfigData;
    if (tokenSource) {
      tokenSource.cancel(`auto-complete search cancelled-${type}`);
    }
    tokenSource = this.cancelToken.source();
    autoCompleteConfigData.tokenSource = tokenSource;
    this.props[actionName](searchText, param1, param2, tokenSource.token).then((result) => {
      const changes = {};
      const autoCompleteData = result.payload.data;
      changes[dataStateField] = autoCompleteData;
      this.setState(
        changes,
        this.getLocationDistancePopulationCallback(
          type,
          this.state[selectedStateField],
          autoCompleteData,
        ),
      );
    });
  }

  retrieveLocationDataAsynchronously() {
    let startLoc = this.state.startValue;
    let endLoc = this.state.endValue;
    let viaLoc = this.state.viaValue;
    if (startLoc === '') {
      startLoc = 'undefined';
    }
    if (endLoc === '') {
      endLoc = 'undefined';
    }
    if (viaLoc === '') {
      viaLoc = 'undefined';
    }
    if (this.locationTokenSource) {
      this.locationTokenSource.cancel('cancelled location call');
    }
    this.locationTokenSource = this.cancelToken.source();
    this.props.fetchValidEndLocation(endLoc, startLoc, viaLoc)
      .then((result) => {
        this.setState({
          endAutocompleteData: result.payload.data,
        });
      });
    this.props.fetchValidViaLocation(viaLoc, startLoc, endLoc)
      .then((result) => {
        this.setState({
          viaAutocompleteData: result.payload.data,
        });
      });
    this.props.fetchValidStartLocation(startLoc, viaLoc, endLoc)
      .then((result) => {
        this.setState({
          startAutocompleteData: result.payload.data,
        });
      });
  }

  retrieveDistanceDataAsynchronously() {
    const startLoc = this.state.startValue;
    const endLoc = this.state.endValue;
    let viaLoc = this.state.viaValue;
    if (viaLoc === '') {
      viaLoc = 'undefined';
    }
    if (this.distanceTokenSource) {
      this.distanceTokenSource.cancel('cancelled distance call');
    }
    this.distanceTokenSource = this.cancelToken.source();
    this.props.fetchDistance(startLoc, endLoc, viaLoc, this.distanceTokenSource.token)
      .then((result) => {
        this.setState({
          distanceValue: result.payload.data,
        });
      });
  }

  renderAutoCompleteItem(type, inputProps) {
    return (
      <Autocomplete
        getItemValue={getItemValue}
        items={this.getAutoCompleteItems(type)}
        renderItem={renderItem}
        value={this.getSelectedAutoCompleteItem(type)}
        onChange={e => this.onAutoCompleteChange(type, e)}
        onSelect={e => this.onAutoCompleteSelect(type, e)}
        inputProps={inputProps}
      />
    );
  }

  renderAutoCompleteItemWithParams(type, inputProps, param1, param2) {
    return (
      <Autocomplete
        getItemValue={getItemValue}
        items={this.getAutoCompleteItems(type)}
        renderItem={renderItem}
        value={this.getSelectedAutoCompleteItem(type)}
        onChange={e => this.onAutoCompleteChangeWithParams(type, e, param1, param2)}
        onSelect={e => this.onAutoCompleteSelect(type, e)}
        inputProps={inputProps}
      />
    );
  }

  render() {
    return (
      <div className="col-xs-6 specialContent">
        <table>
          <thead />
          <tbody>
            <tr id="unitFilter">
              <td className="col-xs-1">{messages.commonUnit}</td>
              <td className="col-xs-1">
                {this.renderAutoCompleteItem('UNITS', { id: 'specialUnitInput' })}
              </td>
            </tr>
            <tr id="specialTrafficCheckbox">
              <td className="col-xs-1">{messages.specialTrafficIsSpecialTraffic}</td>
              <td className="col-xs-1">
                <input
                  type="checkbox"
                  defaultChecked={this.state.isSpecialTraffic}
                  onChange={this.handleChangeSpecial}
                />
              </td>
            </tr>
            <tr />
          </tbody>
          <tbody id="specialLeg">
            <tr>
              <td className="col-xs-1">{messages.specialTrafficStartLocation}</td>
              <td className="col-xs-1">
                {this.renderAutoCompleteItemWithParams('START_LOCATION', { id: 'specialStartLocationInput', disabled: this.state.isAdjustment }, this.state.viaValue, this.state.endValue)}
              </td>
              <td className="col-xs-1">{messages.commonTime}</td>
              <td className="col-xs-1">
                <input
                  type="time"
                  id="specialTime"
                  ref={this.startTimeInput}
                  defaultValue={this.state.startTime}
                  format="HH:mm"
                  disabled={this.state.isAdjustment}
                  onChange={this.onTimeChange}
                />
              </td>
            </tr>
            <tr>
              <td className="col-xs-1">{messages.specialTrafficEndLocation}</td>
              <td className="col-xs-1">
                {this.renderAutoCompleteItemWithParams('END_LOCATION', { id: 'specialEndLocationInput', disabled: this.state.isAdjustment }, this.state.startValue, this.state.viaValue)}
              </td>
              <td className="col-xs-1">{messages.commonTime}</td>
              <td className="col-xs-1">
                <input
                  type="time"
                  id="specialTime"
                  ref={this.endTimeInput}
                  defaultValue={this.state.endTime}
                  format="HH:mm"
                  disabled={this.state.isAdjustment}
                  onChange={this.onTimeChange}
                />
              </td>
            </tr>
            <tr>
              <td className="col-xs-1">{messages.commonVia}</td>
              <td className="col-xs-1">
                {this.renderAutoCompleteItemWithParams('VIA_LOCATION', { id: 'specialViaInput', disabled: this.state.isAdjustment }, this.state.startValue, this.state.endValue)}
              </td>
              <td /><td />
            </tr>
            <tr>
              <td className="col-xs-1">{messages.commonHeadCode}</td>
              <td className="col-xs-1">
                <input
                  type="text"
                  disabled={this.state.isAdjustment}
                  onChange={this.onHeadCodeChange}
                  value={this.state.headCodeValue}
                />
              </td>
              <td /><td />
            </tr>
          </tbody>
          <tbody>
            <tr id="specialDistance">
              <td className="col-xs-1">{messages.commonDistance}</td>
              <td className="col-xs-1">
                <input
                  type="text"
                  disabled={this.state.isSpecialTraffic}
                  onChange={this.onDistanceChange}
                  value={this.state.distanceValue}
                />
              </td>
            </tr>
            <tr id="specialReason">
              <td className="col-xs-1">{messages.specialTrafficReason}</td>
              <td className="col-xs-1">
                {this.renderAutoCompleteItem('REASON', { id: 'specialReasonInput', onKeyPress: e => e.preventDefault() })}
              </td>
            </tr>
          </tbody>
          <tbody id="specialUpdate">
            <tr>
              <td className="col-xs-1">{messages.specialTrafficUpdateWebgemini}</td>
              <td className="col-xs-1">
                <input
                  type="checkbox"
                  checked={this.state.wgStatus}
                  onChange={this.onWGCheckChange}
                  disabled={this.state.isAdjustment}
                />
              </td>
              <td /><td />
            </tr>
          </tbody>
        </table>
        <TrimbleButton
          id="specialSaveButton"
          label={messages.commonSave}
          onClick={this.specialSubmit}
        />
      </div>
    );
  }
}

SpecialMovements.propTypes = {
  fetchValidStartLocation: PropTypes.func,
  fetchValidEndLocation: PropTypes.func,
  fetchValidViaLocation: PropTypes.func,
  fetchDistance: PropTypes.func,
  fetchAvailableAssets: PropTypes.func,
  fetchReason: PropTypes.func,
  showPopup: PropTypes.func,
};
SpecialMovements.defaultProps = {
  fetchValidStartLocation: null,
  fetchValidEndLocation: null,
  fetchValidViaLocation: null,
  fetchDistance: null,
  fetchAvailableAssets: null,
  fetchReason: null,
  showPopup: null,
};

export default connect(null, {
  showPopup,
  fetchDistance,
  fetchLocation,
  fetchValidStartLocation,
  fetchValidEndLocation,
  fetchValidViaLocation,
  fetchAvailableAssets,
  fetchReason,
})(SpecialMovements);
