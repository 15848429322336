import {
  ROOT_URL,
  SAVE_LOCATION,
  LOCATION_CODE_LENGTH,
  LOCATION_DESCRIPTION_LENGTH,
  LOCATION_TIPLOC_LENGTH,
} from '../constants';
import { validateMandatoryFields } from '../../utils/validation';
import { createSaveAction } from '../../utils/actionHelper';
import messages from '../../messages';

function validateLocation(location) {
  const errors = [];
  validateMandatoryFields(
    location,
    {
      code: { name: messages.commonCode, maxLegnth: LOCATION_CODE_LENGTH },
      description: { name: messages.commonDescription, maxLength: LOCATION_DESCRIPTION_LENGTH },
      tiploc: { name: messages.locationTiploc, maxLength: LOCATION_TIPLOC_LENGTH },
    },
    errors,
  );
  if (location.tiploc && !/^[a-zA-Z0-9]+$/.test(location.tiploc)) {
    const message = messages.formatString(
      messages.commonValidationFieldAlphaNumeric,
      messages.locationTiploc,
    );
    errors.push(message);
  }
  return errors;
}

export default function saveLocation(location, successCallback) {
  return createSaveAction({
    saveObject: location,
    validationFunction: validateLocation,
    validationFailedMsag: messages.locationSaveOrUpdateValidationFailedMessage,
    saveUrl: `${ROOT_URL}/location/saveOrUpdate`,
    errorInSavingMsg: messages.locationSaveOrUpdateFailureMessage,
    successfullySavedMsg: messages.formatString(messages.locationSaveOrUpdateSuccessMessage, location.description || ''),
    dispatchType: SAVE_LOCATION,
    successCallback,
  });
}
