import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import messages from '../../messages';
import '../allocation.css';

class TimeSearch extends Component {
  constructor(props) {
    super(props);
    this.render = this.render.bind(this);
    this.enterPressed = this.enterPressed.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.startTimeForm = createRef();
    this.endTimeForm = createRef();
    this.startTimeInput = createRef();
    this.endTimeInput = createRef();
  }

  enterPressed(event) {
    const code = event.keyCode || event.which;
    const startTime = this.startTimeInput.current.value.trim();
    const endTime = this.endTimeInput.current.value.trim();
    if (code === 13) {
      this.props.onSubmit(startTime, endTime);
    }
  }

  handleChange(event) {
    event.preventDefault();
    const startTime = this.startTimeInput.current.value.trim();
    const endTime = this.endTimeInput.current.value.trim();
    this.props.onChange(startTime, endTime);
  }

  render() {
    return (
      <div className={`timeFilterDiv ${this.props.extraCss || ''}`}>
        <form ref={this.startTimeForm} className="startTimeForm">
          <span className="startTimeDiv">
            <span className="fieldLabel">{this.props.label}</span>
            <span className="fieldLabelFrom">{messages.commonFrom}</span>
            <input
              className="trimbleStyleBorder startTimeFilter"
              type="time"
              ref={this.startTimeInput}
              onChange={this.handleChange}
              onKeyPress={this.enterPressed}
            />
          </span>
        </form>
        <form ref={this.endTimeForm} className="endTimeForm">
          <span className="endTimeDiv">
            <span className="fieldLabelTo">{messages.commonTo}</span>
            <input
              className="trimbleStyleBorder endTimeFilter"
              type="time"
              ref={this.endTimeInput}
              onChange={this.handleChange}
              onKeyPress={this.enterPressed}
            />
          </span>
        </form>
      </div>
    );
  }
}

TimeSearch.propTypes = {
  label: PropTypes.string,
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  extraCss: PropTypes.string,
};
TimeSearch.defaultProps = {
  label: '',
  onSubmit: null,
  onChange: null,
  extraCss: '',
};

export default TimeSearch;
