import { DragDropContext, DragSource, DropTarget } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';

const dndContext = DragDropContext(HTML5Backend);

export function makeDnDAware(reactComponent) {
  return dndContext(reactComponent);
}

export const DraggableItemTypes = {
  ASSET: 'Asset',
  ALLOCATED_ASSET: 'AllocatedAsset',
};

function defaultDragCollector(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
    supportsDrag: monitor.canDrag() || monitor.isDragging(),
  };
}

function defaultDropCollector(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop(),
  };
}

export function makeDragSource(reactComponent, itemType, dragSource, collector) {
  const dragCollector = (!collector ? defaultDragCollector : collector);
  return DragSource(itemType, dragSource, dragCollector)(reactComponent);
}

export function makeDropTarget(reactComponent, itemType, dropTarget, collector) {
  const dropCollector = (!collector ? defaultDropCollector : collector);
  return DropTarget(itemType, dropTarget, dropCollector)(reactComponent);
}
