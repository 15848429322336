import { ROOT_URL as BASE_ROOT_URL } from '../constants';

export const ROOT_URL = BASE_ROOT_URL;

export const FETCH_ASSET = 'FETCH_ASSET';

export const FETCH_DIAGRAM = 'FETCH_DIAGRAM';

export const EXPAND_DIAGRAM = 'EXPAND_DIAGRAM';

export const ALLOCATE_ASSET = 'ALLOCATE_ASSET';

export const DEALLOCATE_ASSET = 'DEALLOCATE_ASSET';

export const REALLOCATE_ASSET = 'REALLOCATE_ASSET';

export const ADD_NOTE = 'ADD_NOTE';

export const ASSIGN_RESTRICTIONS = 'ASSIGN_RESTRICTIONS';

export const ALLOCATION_TYPE_DIAGRAM = 'DIAGRAM';

export const EOD_FUEL_ERROR_LEVEL = 100;

export const EOD_FUEL_LEVEL_THRESHOLDTYPE_ERROR = 1;

export const TOOLTIP_PARTIAL_ALLOCATION = 'Not all of the legs in this diagram have a unit / set allocated to them';

export const TOOLTIP_NO_ALLOCATION_DIAGRAM = 'This diagram does not have any unit / set allocated to it ';

export const TOOLTIP_NO_ALLOCATION_DIAGRAM_LEG = 'This diagram leg does not have any unit / set allocated to it ';

export const TOOLTIP_EOD_FUEL_ERROR = `The EOD fuel value is below ${EOD_FUEL_ERROR_LEVEL}`;

