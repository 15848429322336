import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap-modal';
import saveReason, { fetchChangeReason } from '../actions/changeReason';
import messages from '../../messages';

class ReasonModel extends Component {
  constructor(props) {
    super(props);
    this.descriptionChange = this.descriptionChange.bind(this);
    this.onCodeChange = this.onCodeChange.bind(this);

    let code = '';
    let description = '';
    if (this.props.item) {
      if (this.props.item.code !== '' || this.props.item.code !== 'undefined') {
        ({ code } = this.props.item);
      }
      if (
        this.props.item.description !== '' ||
        this.props.item.description !== 'undefined'
      ) {
        ({ description } = this.props.item.description || '');
      }
    }
    this.state = { code, description };
  }

  componentWillReceiveProps() {
    if (this.props.item !== undefined) {
      this.setState({
        code: this.props.item.code,
        description: this.props.item.description,
      });
    }
  }

  onClickCancel() {
    this.setState({ code: '', description: '' });
    this.props.handleHide();
  }

  onCodeChange(e) {
    this.setState({ code: e.target.value });
  }

  descriptionChange(e) {
    this.setState({ description: e.target.value });
  }

  handleSave = () => {
    if (this.props.item) {
      this.props.saveFormModal(
        {
          id: this.props.item.id,
          code: this.state.code,
          description: this.state.description,
        },
        () => this.saveSuccessCallback(),
      );
    } else {
      this.props.saveFormModal(
        { code: this.state.code, description: this.state.description },
        () => this.saveSuccessCallback(),
      );
    }
  };

  saveSuccessCallback() {
    this.setState({ code: '', description: '' });
    document.getElementById('assetFilterMagnifier').click();
    this.props.handleHide();
  }

  render() {
    return (
      <Modal
        onHide={this.props.handleHide}
        show={this.props.show}
        backdrop="static"
        className="appModal"
      >
        <Modal.Header closeButton>
          <Modal.Title>{messages.changeReasonPopupTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="form-group row">
              <label className="col-sm-2 col-form-label" htmlFor="id-code">
                {messages.commonCode}
              </label>
              <div className="col-sm-10">
                <input
                  id="id-code"
                  type="text"
                  className="form-control"
                  value={this.state.code}
                  onChange={this.onCodeChange}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-2 col-form-label" htmlFor="id-description">
                {messages.commonDescription}
              </label>
              <div className="col-sm-10">
                <input
                  id="id-description"
                  type="text"
                  className="form-control"
                  value={this.state.description}
                  onChange={this.descriptionChange}
                />
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn trimbleStyleButton"
            onClick={() => this.onClickCancel()}
          >
            {messages.commonCancel}
          </button>
          <button
            className="btn btn trimbleStyleButton"
            onClick={() => this.handleSave()}
          >
            {messages.commonSave}
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

ReasonModel.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
    code: PropTypes.string,
    description: PropTypes.string,
  }),
  saveFormModal: PropTypes.func,
  handleHide: PropTypes.func,
  show: PropTypes.bool,
};
ReasonModel.defaultProps = {
  item: {},
  saveFormModal: null,
  handleHide: null,
  show: false,
};

export default connect(null, { fetchChangeReason, saveFormModal: saveReason })(ReasonModel);
