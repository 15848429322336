import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { fetchChangeReason } from '../actions/changeReason';
import ReasonModal from './reasonModal';
import { hasLicence } from '../../components/licenced_components';
import { CHANGE_REASON_SCREEN_EDIT } from '../../kc_roles';
import { TrimbleButton } from '../../components/FormComponents';
import messages from '../../messages';
import '../../allocations/allocation.css';

class Filters extends Component {
  constructor(props) {
    super(props);
    this.state = { filter: '' };
    this.props.fetchChangeReason();
    this.render = this.render.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.assetSearch = createRef();
    this.assetSearchButton = createRef();
  }

  handleSearch(event) {
    event.preventDefault();
    const filter = this.assetSearch.current.value.trim();
    this.setState({ filter });
    this.props.fetchChangeReason(filter);
  }

  handleChange(event) {
    event.preventDefault();
    const filter = this.assetSearch.current.value.trim();
    if (filter === '') {
      this.assetSearchButton.current.className = 'hiddenButton';
    } else {
      this.assetSearchButton.current.className = 'fa fa-search';
    }
    this.setState({ filter });
  }

  doNewSearch() {
    this.setState({ filter: '' });
    this.props.fetchChangeReason(this.state.filter);
  }
  clearSearch(event) {
    event.preventDefault();
    let filter = this.assetSearch.current.value.trim();
    if (filter !== undefined && filter !== '') {
      this.setState({
        filter: '',
      });

      filter = '';
      this.assetSearchButton.current.className = 'hiddenButton';
    }
    this.props.fetchChangeReason();
  }

  render() {
    return (
      <div id="filters">
        <div id="assetFilterDiv">
          <form onSubmit={this.handleSearch}>
            <input
              type="text"
              id="assetFilter"
              placeholder={messages.commonSearch}
              ref={this.assetSearch}
              value={this.state.filter}
              onChange={this.handleChange}
            />
            <button
              type="submit"
              id="assetFilterMagnifier"
              ref={this.assetSearchButton}
              className="hiddenButton"
              onClick={this.handleSearch}
            />
          </form>
        </div>
        <TrimbleButton
          id="clearSearchButton"
          label={messages.commonClearSearch}
          onClick={this.clearSearch}
        />
        {hasLicence(CHANGE_REASON_SCREEN_EDIT) && (
          <TrimbleButton
            id="addButton"
            label={messages.commonAdd}
            onClick={this.props.handleClick}
          />
        )}
        <ReasonModal
          item={undefined}
          show={this.props.show}
          handleHide={this.props.handleHide}
          onAfterSave={() => this.doNewSearch()}
        />
      </div>
    );
  }
}

Filters.propTypes = {
  fetchChangeReason: PropTypes.func,
  handleHide: PropTypes.func,
  handleClick: PropTypes.func,
  show: PropTypes.bool,
};
Filters.defaultProps = {
  fetchChangeReason: null,
  handleHide: null,
  handleClick: null,
  show: false,
};

export default connect(null, { fetchChangeReason })(Filters);
