import React from 'react';
import { connect } from 'react-redux';
import GenericFormModal from '../../components/GenericFormModal';
import { MAX_FLEET_CODE_LENGTH } from '../constants';
import saveFleet from '../actions/saveFleet';
import messages from '../../messages';

class FleetModal extends GenericFormModal {
  renderBody() {
    return (
      <span>
        {this.renderInputField(messages.commonCode, 'code', MAX_FLEET_CODE_LENGTH)}
        {this.renderColorPicker(messages.commonFillColour, 'fillColour')}
        {this.renderColorPicker(messages.commonTextColour, 'Text colour', 'textColour')}
      </span>
    );
  }
}

export default connect(null, { saveFormModal: saveFleet })(FleetModal);
