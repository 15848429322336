import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import ColorPicker from './ColorPicker';

export const InputField = ({
  input,
  label,
  type,
  meta: { touched, error },
}) => (
  <div>
    <label htmlFor={input.id}>{label}</label>
    <input {...input} className="form-control" type={type} />
    <div className="text-help">{touched ? error : ''}</div>
  </div>
);
InputField.propTypes = {
  input: PropTypes.shape({ id: PropTypes.string }),
  label: PropTypes.string,
  type: PropTypes.string,
  meta: PropTypes.shape({ touched: PropTypes.bool, error: PropTypes.string }),
};
InputField.defaultProps = {
  input: {},
  label: '',
  type: null,
  meta: null,
};

export const TrimbleButton = props => (
  <button
    className={`trimbleStyleButton btn ${props.extraCss || ''}`}
    {...(_.omit(props, 'extraCss', 'label'))}
  >
    {props.label || ''}
  </button>
);
TrimbleButton.propTypes = {
  extraCss: PropTypes.string,
  label: PropTypes.string,
};
TrimbleButton.defaultProps = {
  extraCss: '',
  label: '',
};

const DEFAULT_FIELD_CSS = {
  topDivCss: 'form-group row',
  labelCss: 'col-xs-3 col-form-label',
  midDivCss: 'col-xs-9',
};

export function getDefaultFieldCss() {
  return Object.assign({}, DEFAULT_FIELD_CSS);
}

function getInputCss(cssClasses, type) {
  let result = cssClasses.inputCss;
  if (!result) {
    result = (type === 'text' ? 'form-control' : 'form-input-checkbox');
  }
  return result;
}

export const LabeledInputField = ({
  name,
  label,
  type,
  extraInputParams = {},
  cssClasses = DEFAULT_FIELD_CSS,
}) => (
  <div className={cssClasses.topDivCss}>
    <label htmlFor={`${name}ID`} className={cssClasses.labelCss}>
      {label}
    </label>
    <div className={cssClasses.midDivCss}>
      <input
        id={`${name}ID`}
        name={name}
        type={type}
        className={getInputCss(cssClasses, type)}
        {...extraInputParams}
      />
    </div>
  </div>
);
LabeledInputField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  extraInputParams: PropTypes.shape({}),
  cssClasses: PropTypes.shape({}),
};
LabeledInputField.defaultProps = {
  name: null,
  label: null,
  type: null,
  extraInputParams: {},
  cssClasses: DEFAULT_FIELD_CSS,
};

export const LabeledColorPicker = ({
  name,
  label,
  extraParams,
  cssClasses = DEFAULT_FIELD_CSS,
}) => (
  <div className={cssClasses.topDivCss}>
    <label htmlFor={`${name}ID`} className={cssClasses.labelCss}>
      {label}
    </label>
    <div className={cssClasses.midDivCss}>
      <ColorPicker {...extraParams} />
    </div>
  </div>
);
LabeledColorPicker.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  extraParams: PropTypes.shape({}),
  cssClasses: PropTypes.shape({}),
};
LabeledColorPicker.defaultProps = {
  name: null,
  label: null,
  extraParams: {},
  cssClasses: DEFAULT_FIELD_CSS,
};
