import { connect } from 'react-redux';
import RestrictionModal from './restrictionModal';
import { refreshSearch } from '../actions/searchRestrictions';
import searchResultTableRow from '../../components/searchResultTableRow';
import messages from '../../messages';

const BaseTableRow = searchResultTableRow(
  [
    { propertyName: 'description' },
    { propertyName: 'code' },
    { propertyName: 'canAllocate' },
  ],
  RestrictionModal,
);

class RestrictionRow extends BaseTableRow {
  getRowStyle() {
    const restriction = this.props.rowObject;
    return {
      color: '#FFF',
      backgroundColor: restriction.color,
    };
  }

  static renderColumnValue(config, index, restriction) {
    if (config.propertyName === 'canAllocate') {
      if (restriction.canAllocate) {
        return (restriction.showPrompt ? messages.restrictionPrompt : messages.commonYes);
      }
      return messages.commonNo;
    }
    return restriction[config.propertyName];
  }
}

export default connect(null, { onAfterEdit: refreshSearch })(RestrictionRow);
