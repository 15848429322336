import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { fetchAllDistances, fetchDistances } from '../actions/locationDistance';
import DistanceModal from './distanceModal';
import { LOCATION_DISTANCE_SCREEN_EDIT } from '../../kc_roles';
import { hasLicence } from '../../components/licenced_components';
import { TrimbleButton } from '../../components/FormComponents';
import messages from '../../messages';

class TopBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
    this.props.fetchAllDistances();
    this.render = this.render.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.addDistance = this.addDistance.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.filterChange = this.filterChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleHide = this.handleHide.bind(this);
    this.locationInput = createRef();
    this.locationSearchButton = createRef();
  }

  clearSearch(event) {
    event.preventDefault();
    const locSearchField = this.locationInput.current;

    if (locSearchField.value !== undefined && locSearchField.value !== '') {
      locSearchField.value = '';
      this.locationSearchButton.current.className = 'hiddenButton';
    }
    this.props.fetchAllDistances();
  }

  addDistance() {
    if (!this.state.show) {
      this.setState({ show: true });
    } else {
      this.handleHide();
    }
  }

  handleSearch(event) {
    if (event) {
      event.preventDefault();
    }
    let filter;
    filter = this.locationInput.current.value.trim();
    if (filter === '') {
      filter = 'undefined';
      this.props.fetchAllDistances(filter);
    } else {
      this.props.fetchDistances(filter);
    }
  }

  filterChange(event) {
    event.preventDefault();
    let filter;
    filter = this.locationInput.current.value.trim();
    if (filter === '') {
      filter = 'undefined';
      this.locationSearchButton.current.className = 'hiddenButton';
    } else {
      this.locationSearchButton.current.className = 'fa fa-search';
    }
  }

  handleClick() {
    if (!this.state.show) {
      this.setState({ show: true });
    } else {
      this.handleHide();
    }
  }

  handleHide() {
    this.setState({ show: false });
  }

  render() {
    return (
      <div id="topbar">
        <div id="locationFilterDiv">
          <form onSubmit={this.handleSearch}>
            <input
              type="text"
              id="locationSearchFilter"
              placeholder={messages.locationSearchPlaceholder}
              ref={this.locationInput}
              onChange={this.filterChange}
            />
            <button
              type="submit"
              id="locationSearchMagnifier"
              ref={this.locationSearchButton}
              className="hiddenButton"
              onClick={this.handleSearch}
            />
          </form>
        </div>
        <TrimbleButton
          id="clearSearchButton"
          label={messages.commonClearSearch}
          onClick={this.clearSearch}
        />
        {hasLicence(LOCATION_DISTANCE_SCREEN_EDIT) && (
          <TrimbleButton
            id="addDistanceButton"
            label={messages.commonAdd}
            onClick={this.addDistance}
          />
        )}
        <DistanceModal
          show={this.state.show}
          handleHide={this.handleHide}
          onAfterSave={this.handleSearch}
        />
      </div>
    );
  }
}

TopBar.propTypes = {
  fetchAllDistances: PropTypes.func,
  fetchDistances: PropTypes.func,
};
TopBar.defaultProps = {
  fetchAllDistances: null,
  fetchDistances: null,
};

export default connect(null, { fetchAllDistances, fetchDistances })(TopBar);
