import { connect } from 'react-redux';
import searchResultTableRow from '../../components/searchResultTableRow';
import FleetModal from './fleetModal';
import { refreshSearch } from '../actions/searchFleets';

const BaseTableRow = searchResultTableRow(
  [{ propertyName: 'code' }, { propertyName: 'formationType' }],
  FleetModal,
);

class FleetRow extends BaseTableRow {
  getRowStyle() {
    const { rowObject } = this.props;
    return {
      color: rowObject.textColour,
      backgroundColor: rowObject.fillColour,
    };
  }
}

export default connect(null, { onAfterEdit: refreshSearch })(FleetRow);
