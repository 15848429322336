import React, { Component, createRef } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import QuickSearch from './quickSearch';
import LocationSearch from './locationSearch';
import TimeSearch from './timeSearch';
import Calendar from './calendar';
import fetchDiagramList from '../actions/diagram';
import fetchCurrentDiagramDate from '../../actions/currentDiagramDate';
import { TrimbleButton } from '../../components/FormComponents';
import messages from '../../messages';
import '../allocation.css';

class FilterBar extends Component {
  constructor(props) {
    super(props);

    this.render = this.render.bind(this);
    this.state = {
      arrivalTimeFrom: 'undefined',
      arrivalTimeTo: 'undefined',
      departureTimeFrom: 'undefined',
      departureTimeTo: 'undefined',
      location: 'undefined',
      filter: 'undefined',
      startDate: this.props.currentDiagramDate,
    };
    this.locationSearch = this.locationSearch.bind(this);
    this.locationChange = this.locationChange.bind(this);
    this.calendarSearch = this.calendarSearch.bind(this);
    this.quickSearch = this.quickSearch.bind(this);
    this.quickSearchChange = this.quickSearchChange.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.setCalendarDate = this.setCalendarDate.bind(this);
    this.arrivalTimeChange = this.arrivalTimeChange.bind(this);
    this.arrivalTimeSearch = this.arrivalTimeSearch.bind(this);
    this.departureTimeChange = this.departureTimeChange.bind(this);
    this.departureTimeSearch = this.departureTimeSearch.bind(this);
    this.quickSearchRef = createRef();
    this.locationSearchRef = createRef();
    this.arrivalSearchRef = createRef();
    this.departureSearchRef = createRef();
  }

  componentDidMount() {
    fetchCurrentDiagramDate().payload.then((response) => {
      this.setState({
        startDate: moment(response.data),
      });
      this.props.fetchDiagramList(
        this.state.startDate,
        this.state.filter,
        this.state.location,
        this.state.arrivalTimeFrom,
        this.state.arrivalTimeTo,
        this.state.departureTimeFrom,
        this.state.departureTimeTo,
      );
    });
  }

  setCalendarDate(startDate) {
    this.doStateUpdate({ startDate });
  }

  doStateUpdate(newFieldValues) {
    const updatedFieldValues = _.mapValues(
      newFieldValues,
      value => value || 'undefined',
    );
    const newState = Object.assign({}, this.state, updatedFieldValues);
    this.setState(newState);
    return newState;
  }

  doFetchDiagrams(newFieldValues) {
    const newState = this.doStateUpdate(newFieldValues);
    this.props.fetchDiagramList(
      newState.startDate,
      newState.filter,
      newState.location,
      newState.arrivalTimeFrom,
      newState.arrivalTimeTo,
      newState.departureTimeFrom,
      newState.departureTimeTo,
    );
  }

  calendarSearch(startDate) {
    this.doFetchDiagrams({ startDate });
  }

  arrivalTimeChange(arrivalTimeFrom, arrivalTimeTo) {
    this.doStateUpdate({ arrivalTimeFrom, arrivalTimeTo });
  }

  arrivalTimeSearch(arrivalTimeFrom, arrivalTimeTo) {
    this.doFetchDiagrams({ arrivalTimeFrom, arrivalTimeTo });
  }

  departureTimeChange(departureTimeFrom, departureTimeTo) {
    this.doStateUpdate({ departureTimeFrom, departureTimeTo });
  }

  departureTimeSearch(departureTimeFrom, departureTimeTo) {
    this.doFetchDiagrams({ departureTimeFrom, departureTimeTo });
  }

  locationSearch(location) {
    this.doFetchDiagrams({ location });
  }

  locationChange(location) {
    this.doStateUpdate({ location });
  }

  quickSearch(filter) {
    this.doFetchDiagrams({ filter });
  }

  quickSearchChange(filter) {
    this.doStateUpdate({ filter });
  }

  clearSearch(event) {
    event.preventDefault();
    const searchField = this.quickSearchRef.current.quickSearch.current;
    const locSearchField = this.locationSearchRef.current.locationInput.current;
    const arrivalFromField = this.arrivalSearchRef.current.startTimeInput;
    const arrivalToField = this.arrivalSearchRef.current.endTimeInput;
    const departureFromField = this.departureSearchRef.current.startTimeInput;
    const deparuteToField = this.departureSearchRef.current.endTimeInput;
    if (
      (searchField.value !== undefined && searchField.value !== '') ||
      (locSearchField.value !== undefined && locSearchField.value !== '') ||
      (arrivalFromField.value !== undefined && arrivalFromField.value !== '') ||
      (arrivalToField.value !== undefined && arrivalToField.value !== '') ||
      (departureFromField.value !== undefined &&
        departureFromField.value !== '') ||
      (deparuteToField.value !== undefined && deparuteToField.value !== '')
    ) {
      searchField.value = '';
      locSearchField.value = '';
      this.arrivalSearchRef.current.startTimeForm.current.reset();
      this.arrivalSearchRef.current.endTimeForm.current.reset();
      this.departureSearchRef.current.startTimeForm.current.reset();
      this.departureSearchRef.current.endTimeForm.current.reset();
      this.quickSearchRef.current.quickSearchButton.current.className = 'hiddenButton';
      this.locationSearchRef.current.locationButton.current.className = 'hiddenButton';
    }
    this.doFetchDiagrams({
      startDate: this.state.startDate,
      arrivalTimeFrom: 'undefined',
      arrivalTimeTo: 'undefined',
      departureTimeFrom: 'undefined',
      departureTimeTo: 'undefined',
      location: 'undefined',
      filter: 'undefined',
    });
  }

  render() {
    return (
      <div id="filterBar">
        <span className="arrivalDepartureFilter">
          <TimeSearch
            ref={this.arrivalSearchRef}
            onChange={this.arrivalTimeChange}
            onSubmit={this.arrivalTimeSearch}
            label={messages.commonArrival}
          />
          <TimeSearch
            ref={this.departureSearchRef}
            onChange={this.departureTimeChange}
            onSubmit={this.departureTimeSearch}
            label={messages.commonDeparture}
            extraCss="departureField"
          />
        </span>
        <LocationSearch
          ref={this.locationSearchRef}
          onSearch={this.locationSearch}
          onChange={this.locationChange}
        />
        <QuickSearch
          ref={this.quickSearchRef}
          onSearch={this.quickSearch}
          onChange={this.quickSearchChange}
        />
        <div>
          <TrimbleButton
            id="clearSearchButton"
            label={messages.commonClear}
            onClick={this.clearSearch}
          />
        </div>
        <i
          className={`availabilitySectionToggle fa fa-2x fa-angle-${
            this.props.showAvailabilitySection ? 'right' : 'left'
          }`}
          onClick={() => this.props.toggleAvailabilitysection()}
          onKeyDown={() => this.props.toggleAvailabilitysection()}
          role="button"
          tabIndex={0}
        />
        <Calendar
          setDate={this.setCalendarDate}
          calendarSearch={this.calendarSearch}
          initialDate={this.state.startDate}
        />
      </div>
    );
  }
}

FilterBar.propTypes = {
  currentDiagramDate: PropTypes.shape({}),
  fetchDiagramList: PropTypes.func,
  toggleAvailabilitysection: PropTypes.func,
  showAvailabilitySection: PropTypes.bool,
};
FilterBar.defaultProps = {
  currentDiagramDate: null,
  fetchDiagramList: null,
  toggleAvailabilitysection: null,
  showAvailabilitySection: false,
};

function mapStateToProps({ currentDiagramDate }) {
  return { currentDiagramDate: moment(currentDiagramDate) };
}

export default connect(mapStateToProps, { fetchDiagramList })(FilterBar);
