import { ROOT_URL, ALLOCATE_ASSET } from '../constants';
import createConfirmableAction from '../../utils/actionHelper';
import messages from '../../messages';

export function allocateAsset(asset, diagram, diagramLeg, successCallBack, confirmed = false) {
  let moveDetail = messages.formatString(
    messages.allocationAllocateToDiagramDetail,
    asset.code,
    diagram.diagramName,
  );
  let diagramLegId = null;
  if (diagramLeg) {
    moveDetail = messages.formatString(
      messages.allocationAllocateToDiagramLegDetail,
      asset.code,
      diagram.diagramName,
      diagramLeg.location,
    );
    diagramLegId = diagramLeg.id;
  }
  const successActionCreator = (payload) => {
    const result = (dispatch) => {
      dispatch({
        type: ALLOCATE_ASSET,
        payload: {
          asset,
          updatedAsset: payload.asset,
          diagram,
          allocations: payload.allocations,
          allocatedNow: payload.assetAllocatedNow,
        },
      });
      successCallBack();
    };
    return result;
  };
  return createConfirmableAction({
    postURL: `${ROOT_URL}/diagramAllocation/allocateAsset`,
    postData: {
      asset,
      diagramId: diagram.diagramId,
      diagramLegId,
      confirmed,
      allocationType: diagram.type,
    },
    confirmationMsg: messages.formatString(
      messages.allocationAllocateConfirmationMessage,
      moveDetail,
    ),
    confirmationActionCreator: () =>
      allocateAsset(asset, diagram, diagramLeg, successCallBack, true),
    successActionCreator,
    successMessage: messages.formatString(
      messages.allocationAllocateSuccessMessage,
      moveDetail,
    ),
    invalidRequestMessage: messages.formatString(
      messages.allocationAllocateInvalidRequestMessage,
      moveDetail,
    ),
  });
}

export default allocateAsset;
