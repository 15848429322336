import { ROOT_URL, REALLOCATE_ASSET } from '../constants';
import createConfirmableAction from '../../utils/actionHelper';
import messages from '../../messages';

export function reallocateAsset(
  asset, fromDiagram, fromDiagramLeg,
  toDiagram, toDiagramLeg, confirmed = false,
) {
  const moveDetail = messages.formatString(
    messages.allocationReallocateToDiagramLegDetail,
    asset.code,
    toDiagram.diagramName,
    toDiagramLeg.location,
    fromDiagram.diagramName,
    fromDiagramLeg.location,
  );
  const successActionCreator = payload => ({
    type: REALLOCATE_ASSET,
    payload: {
      asset,
      updatedAsset: payload.asset,
      fromDiagram,
      toDiagram,
      allocations: payload.allocations,
      allocatedNow: payload.assetAllocatedNow,
    },
  });
  return createConfirmableAction({
    postURL: `${ROOT_URL}/diagramAllocation/reallocateAsset`,
    postData: {
      assetId: asset.id,
      toDiagramId: toDiagram.diagramId,
      toDiagramLegId: toDiagramLeg.id,
      toAllocationType: toDiagram.type,
      fromDiagramId: fromDiagram.diagramId,
      fromDiagramLegId: fromDiagramLeg.id,
      fromAllocationType: fromDiagram.type,
      confirmed,
    },
    confirmationMsg: messages.formatString(
      messages.allocationReallocateConfirmationMessage,
      moveDetail,
    ),
    confirmationActionCreator: () => reallocateAsset(
      asset, fromDiagram, fromDiagramLeg,
      toDiagram, toDiagramLeg, true,
    ),
    successActionCreator,
    successMessage: messages.formatString(
      messages.allocationReallocateSuccessMessage,
      moveDetail,
    ),
    invalidRequestMessage: messages.formatString(
      messages.allocationReallocateInvalidRequestMessage,
      moveDetail,
    ),
  });
}

export default reallocateAsset;
