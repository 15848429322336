import { ROOT_URL as BASE_ROOT_URL } from '../constants';

export const ROOT_URL = BASE_ROOT_URL;

export const FETCH_REASON = 'FETCH_REASON';
export const FETCH_LOCATION = 'FETCH_LOCATION';
export const FETCH_DISTANCE = 'FETCH_DISTANCE';
export const FETCH_AVAILABLE_ASSETS = 'FETCH_AVAILABLE_ASSETS';
export const CREATE_SPECIAL = 'CREATE_SPECIAL';
export const FETCH_VALID_END_LOCATION = 'FETCH_VALID_END_LOCATION';
export const FETCH_VALID_VIA_LOCATION = 'FETCH_VALID_VIA_LOCATION';
export const FETCH_VALID_START_LOCATION = 'FETCH_VALID_START_LOCATION';
