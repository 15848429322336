import { Post } from '../../utils/ajax';
import { ROOT_URL, CREATE_SPECIAL } from '../constants';
import { showInformation, showWarning } from '../../actions/YesCancel';
import messages from '../../messages';

export function specialSave(
  unitNumber,
  startLoc,
  startTime,
  endLoc,
  endTime,
  viaLoc,
  headCode,
  distance,
  reason,
  updateMainstream,
  reset,
) {
  return (dispatch) => {
    Post(
      `${ROOT_URL}/specialtraffic/create`,
      {
        unitNumber,
        startLoc,
        startTime,
        endLoc,
        endTime,
        viaLoc,
        headCode,
        distance,
        reason,
        updateMainstream,
      },
      { validateStatus: status => status === 200 || status === 400 },
    ).then((result) => {
      console.log(result);
      if (result) {
        if (result.status === 200) {
          dispatch(showInformation(messages.specialTrafficMovementSaveSuccess));
          reset();
        } else if (result.status === 400) {
          dispatch(showWarning(messages.specialTrafficMovementSaveError));
        }
      }

      dispatch({
        type: CREATE_SPECIAL,
        payload: {},
      });
    });
  };
}

export function adjustmentSave(
  unitNumber,
  distance,
  reason,
  reset,
) {
  return (dispatch) => {
    Post(
      `${ROOT_URL}/specialtraffic/adjust`,
      {
        unitNumber,
        distance,
        reason,
      },
      { validateStatus: status => status === 200 || status === 400 },
    ).then((result) => {
      console.log(result);
      if (result) {
        if (result.status === 200) {
          dispatch(showInformation(messages.specialTrafficAdjustmentSaveSuccess));
          reset();
        } else if (result.status === 400) {
          dispatch(showWarning(messages.specialTrafficAdjustmentSaveError));
        }
      }

      dispatch({
        type: CREATE_SPECIAL,
        payload: {},
      });
    });
  };
}
