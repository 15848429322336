import { YES_CANCEL_MODAL } from '../constants';
import { showSuccessAlert } from '../utils/selfCloseAlert';
import messages from '../messages';

export function showYesCancel(show, title, message, onOkActionCreator, subMessages) {
  return {
    type: YES_CANCEL_MODAL,
    payload: {
      show,
      title,
      message,
      subMessages,
      showCancel: (onOkActionCreator != null),
      onOkActionCreator,
      yesLabel: (onOkActionCreator == null) ? messages.commonOk : null,
    },
  };
}

export function hideYesCancel() {
  return showYesCancel(false);
}

export function showConfirmation(message, confirmationActionCreator, subMessages) {
  return showYesCancel(
    true, messages.yesCancelPopupTitleConfirmation, message,
    confirmationActionCreator, subMessages,
  );
}

export function showTitledMessage(title, message, subMessages) {
  return showYesCancel(true, title, message, null, subMessages);
}

export function showInformation(message, subMessages, showAlways) {
  const result = (dispatch) => {
    if (showAlways) {
      dispatch(showTitledMessage(messages.yesCancelPopupTitleInformation, message, subMessages));
    }
    showSuccessAlert(message);
  };
  return result;
}

export function showWarning(message, subMessages) {
  return showTitledMessage(messages.yesCancelPopupTitleWarning, message, subMessages);
}

export default showYesCancel;
