import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import fetchAssetList from '../actions/asset';
import { TrimbleButton } from '../../components/FormComponents';
import messages from '../../messages';
import '../allocation.css';

class AvailabilityFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      onlyAvailable: true,
    };
    this.render = this.render.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.assetSearch = createRef();
    this.assetSearchButton = createRef();
  }

  componentDidMount() {
    this.props.fetchAssetList(undefined, true);
  }

  handleSearch(event) {
    event.preventDefault();
    const filter = this.assetSearch.current.value.trim();
    this.props.fetchAssetList(filter, this.state.onlyAvailable);
  }

  handleChange(event) {
    event.preventDefault();
    const filter = this.assetSearch.current.value.trim();
    if (filter === '') {
      this.assetSearchButton.current.className = 'hiddenButton';
    } else {
      this.assetSearchButton.current.className = 'fa fa-search';
    }
  }

  showAssets(onlyAvailable) {
    const filter = this.assetSearch.current.value.trim();
    this.setState({ onlyAvailable });
    this.props.fetchAssetList(filter, onlyAvailable);
  }

  clearSearch() {
    this.assetSearch.current.value = '';
    this.setState({
      onlyAvailable: true,
    });
    this.props.fetchAssetList('', true);
  }

  render() {
    return (
      <div>
        <div id="assetFilterDiv">
          <form onSubmit={this.handleSearch}>
            <input
              type="text"
              id="assetFilter"
              placeholder={messages.allocationUnitsFilterUnit}
              ref={this.assetSearch}
              onChange={this.handleChange}
            />
            <button
              type="submit"
              id="assetFilterMagnifier"
              ref={this.assetSearchButton}
              className="hiddenButton"
              onClick={this.handleSearch}
            />
          </form>
        </div>
        <div>
          <TrimbleButton
            label={messages.allocationUnitsFilterShowAll}
            onClick={() => this.showAssets(false)}
            extraCss="searchPanelButton"
          />
          <TrimbleButton
            label={messages.allocationUnitsFilterShowAvailable}
            onClick={() => this.showAssets(true)}
            extraCss="searchPanelButton"
          />
          <TrimbleButton
            label={messages.commonClear}
            onClick={() => this.clearSearch()}
            extraCss="searchPanelButton"
          />
        </div>
      </div>
    );
  }
}

AvailabilityFilter.propTypes = {
  fetchAssetList: PropTypes.func,
};
AvailabilityFilter.defaultProps = {
  fetchAssetList: null,
};

export default connect(null, { fetchAssetList })(AvailabilityFilter);
