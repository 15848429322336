import _ from 'lodash';
import { ROOT_URL, DEALLOCATE_ASSET } from '../constants';
import createConfirmableAction from '../../utils/actionHelper';
import { showConfirmation, showInformation, showWarning } from '../../actions/YesCancel';
import messages from '../../messages';
import { Post } from '../../utils/ajax';

export function deallocateAsset(
  asset,
  diagram,
  diagramLeg,
  successCallBack,
  confirmed = false,
) {
  let moveDetail = messages.formatString(
    messages.allocationDeallocateFromDiagramDetail,
    asset.code,
    diagram.diagramName,
  );
  let diagramLegId = null;
  if (diagramLeg && !_.isEmpty(diagramLeg)) {
    moveDetail = messages.formatString(
      messages.allocationDeallocateFromDiagramLegDetail,
      asset.code,
      diagram.diagramName,
      diagramLeg.location,
    );
    diagramLegId = diagramLeg.id;
  }

  if (!confirmed) {
    return showConfirmation(
      messages.formatString(
        messages.allocationDeallocateConfirmationMessage,
        moveDetail,
      ),
      () => deallocateAsset(asset, diagram, diagramLeg, successCallBack, true),
    );
  }

  const successActionCreator = (payload) => {
    const result = (dispatch) => {
      dispatch({
        type: DEALLOCATE_ASSET,
        payload: {
          asset,
          updatedAsset: payload.asset,
          diagram,
          allocations: payload.allocations,
        },
      });
      successCallBack();
    };
    return result;
  };

  if (!diagramLegId || diagramLegId === diagram.diagramLegOrderIds[0]) {
    return createConfirmableAction({
      postURL: `${ROOT_URL}/diagramAllocation/deallocateAsset`,
      postData: {
        assetId: asset.id,
        diagramId: diagram.diagramId,
        diagramLegId,
        confirmed,
        allocatedType: diagram.type,
      },
      successActionCreator,
      successMessage: messages.formatString(
        messages.allocationDeallocateSuccessMessage,
        moveDetail,
      ),
      invalidRequestMessage: messages.formatString(
        messages.allocationDeallocateInvalidRequestMessage,
        moveDetail,
      ),
    });
  }

  const response = (dispatch) => {
    Post(`${ROOT_URL}/diagramAllocation/deallocateAsset`, {
      assetId: asset.id,
      diagramId: diagram.diagramId,
      confirmed,
      allocatedType: diagram.type,
    }).then((deallocateResult) => {
      const deallocatePayload = deallocateResult.data;
      if (deallocatePayload.requestValid) {
        Post(`${ROOT_URL}/diagramAllocation/allocateAsset`, {
          asset: deallocatePayload.e2mAsset,
          diagramId: diagram.diagramId,
          diagramLegId: diagram.diagramLegOrderIds[0],
          toDiagramLegId: diagramLegId,
          allocationType: diagram.type,
        }).then((result) => {
          const payload = result.data;
          if (payload.requestValid) {
            dispatch(successActionCreator(payload));
            dispatch(showInformation(messages.formatString(
              messages.allocationDeallocateSuccessMessage,
              moveDetail,
            )));
          } else {
            dispatch(showWarning(
              messages.formatString(
                messages.allocationDeallocateInvalidRequestMessage,
                moveDetail,
              ),
              payload.messages,
            ));
          }
        }).catch(error => console.log(error));
      } else {
        dispatch(showWarning(
          messages.formatString(
            messages.allocationDeallocateInvalidRequestMessage,
            moveDetail,
          ),
          deallocatePayload.messages,
        ));
      }
    }).catch(error => console.log(error));
  };
  return response;
}

export default deallocateAsset;
