export const ROOT_URL = '/api';

export const FETCH_CURRENT_DIAGRAM_DATE = 'FETCH_CURRENT_DIAGRAM_DATE';
export const FETCH_CLIENT_SETTINGS = 'FETCH_CLIENT_SETTINGS';
export const FETCH_APPLICATION_VERSION = 'FETCH_APPLICATION_VERSION';
export const YES_CANCEL_MODAL = 'YesCancelModal';
export const ERROR_ACCESS = 'You are not licensed to access this feature.';
export const INTERNAL_SERVER_ERROR = 'Internal Server Error. Please contact system administrator';

export const ALERT_TIMEOUT = 5000;
