export default {
  allocationAllocateConfirmationMessage: 'TODO-Are you sure you want to allocate {0}',
  allocationAllocateInvalidRequestMessage: 'TODO-Allocating {0} is not allowed',
  allocationAllocateSuccessMessage: 'TODO-Successfully allocated {0}',
  allocationAllocateToDiagramDetail: 'TODO-{0} to {1}',
  allocationAllocateToDiagramLegDetail: 'TODO-{0} to {1} at {2}',
  allocationDeallocateConfirmationMessage: 'TODO-Are you sure you want to deallocate {0}',
  allocationDeallocateInvalidRequestMessage: 'TODO-Deallocating {0} is not allowed',
  allocationDeallocateSuccessMessage: 'TODO-Successfully deallocated {0}',
  allocationDeallocateFromDiagramDetail: 'TODO-{0} from {1}',
  allocationDeallocateFromDiagramLegDetail: 'TODO-{0} from {1} starting at {2}',
  allocationDiagram: 'TODO-Diagram',
  allocationDiagramClass: 'TODO-Class',
  allocationDiagramFilterEndTime: 'TODO-End Time',
  allocationDiagramFilterStartTime: 'TODO-Start Time',
  allocationDiagramFinish: 'TODO-Finish',
  allocationDiagramIntervention: 'TODO-Int',
  allocationDiagramLegArrival: 'TODO-Arrival',
  allocationDiagramLegDeparture: 'TODO-Departure',
  allocationDiagramLegFormation: 'TODO-Formation',
  allocationDiagramLegPerform: 'TODO-Perform',
  allocationDiagramNoOfVehicles: 'TODO-# Veh',
  allocationDiagramStart: 'TODO-Start',
  allocationReallocateConfirmationMessage: 'TODO-Are you sure you want to allocate {0}',
  allocationReallocateInvalidRequestMessage: 'TODO-Allocating {0} is not allowed',
  allocationReallocateSuccessMessage: 'TODO-Successfully allocated {0}',
  allocationReallocateToDiagramLegDetail: 'TODO-{0} to {1} starting at {2} from {3} starting at {4}',
  allocationUnitDeallocateTooltip: 'TODO-Remove',
  allocationUnitEodFuel: 'TODO-EOD Fuel',
  allocationUnitEODLocation: 'TODO-EOD Location',
  allocationUnitFuelLevel: 'TODO-Fuel Level',
  allocationUnitFuelLevelDiagram: 'TODO-Diagram',
  allocationUnitFuelLevelDistance: 'TODO-Distance',
  allocationUnitFuelLevelEndFuel: 'TODO-End Fuel',
  allocationUnitFuelLevelFinish: 'TODO-Finish',
  allocationUnitFuelLevelFinishLocation: 'TODO-Finish Location',
  allocationUnitFuelLevelFuel: 'TODO-Fuel',
  allocationUnitFuelLevelStart: 'TODO-Start',
  allocationUnitFuelLevelStartFuel: 'TODO-Start Fuel',
  allocationUnitFuelLevelStartLocation: 'TODO-Start Location',
  allocationUnitFuelLevelTotalCumulativeEODFuel: 'TODO-EOD Fuel',
  allocationUnitFuelLevelTotalCumulativeFuel: 'TODO-Total Fuel',
  allocationUnitFuelLevelTotalCumulativeDistance: 'TODO-Total Distance',
  allocationUnitFuelLevelTotalDistance: 'TODO-Total Dist.',
  allocationUnitFuelLevelTotalFuel: 'TODO-Total Fuel',
  allocationUnitNoOfVehicles: 'TODO-# Veh',
  allocationUnitNotes: 'TODO-Notes',
  allocationUnitNoteClearedSuccessfully: 'TODO-Note Cleared Successfully',
  allocationUnitNoteSavedSuccessfully: 'TODO-Note Saved Successfully',
  allocationUnitNotesResolved: 'TODO-Resolved',
  allocationUnitNotesPlaceholder: 'TODO-Enter Notes',
  allocationUnitRestrictionAllowAllocation: 'TODO-Allow Allocation',
  allocationUnitRestrictionPrompt: 'TODO-Prompt',
  allocationUnitRestrictions: 'TODO-Restrictions',
  allocationUnitSODLocation: 'TODO-SOD Location',
  allocationUnitStatus: 'TODO-Status',
  allocationUnitSwapFuel: 'TODO-Swap fuel: {0}',
  allocationUnitsFromCache: 'TODO-E2M is currently unavailable, information about assets might be outdated',
  allocationUnitsFilterShowAvailable: 'TODO-Show Available',
  allocationUnitsFilterShowAll: 'TODO-Show All',
  allocationUnitsFilterUnit: 'TODO-Search Unit/Set',
  changeReasonPopupTitle: 'TODO-Change Reason',
  changeReasonSaveOrUpdateFailureMessage: 'TODO-Error in saving reason',
  changeReasonSaveOrUpdateSuccessMessage: 'TODO-Successfully saved reason {0}',
  changeReasonSaveOrUpdateValidationFailedMessage: 'TODO-Invalid Reason Details',
  commonAdd: 'TODO-Add',
  commonArrival: 'TODO-Arrival',
  commonCancel: 'TODO-Cancel',
  commonClear: 'TODO-Clear',
  commonClearSearch: 'TODO-Clear Search',
  commonCode: 'TODO-Code',
  commonDiagram: 'TODO-Diagram',
  commonDelete: 'TODO-Delete',
  commonDeparture: 'TODO-Departure',
  commonDescription: 'TODO-Description',
  commonDistance: 'TODO-Distance',
  commonFillColour: 'TODO-Fill colour',
  commonFrom: 'TODO-From',
  commonHeadCode: 'TODO-Head Code',
  commonLocation: 'TODO-Location',
  commonNo: 'TODO-No',
  commonOk: 'TODO-Ok',
  commonOperationInProgress: 'TODO-Operation in progress...',
  commonQuickSearch: 'TODO-Quick Search',
  commonRestore: 'TODO-Restore',
  commonSave: 'TODO-Save',
  commonSearch: 'TODO-Search',
  commonShowDeleted: 'TODO-Show Deleted',
  commonTextColour: 'TODO-Text colour',
  commonTime: 'TODO-Time',
  commonTo: 'TODO-To',
  commonTotalDistance: 'TODO-Total Distance',
  commonUnit: 'TODO-Unit/Set',
  commonValidationFieldAlphaNumeric: 'TODO-{0} should consist of alpha-numeric characters',
  commonValidationFieldFormat: 'TODO-{0} value is incorrect (use {1} format).',
  commonValidationFieldFormatType24H: 'TODO-24 hour',
  commonValidationFieldIncorrect: 'TODO-{0} value is incorrect.',
  commonValidationFieldMandatory: 'TODO-{0} is mandatory',
  commonValidationFieldMax: 'TODO-{0} must not be greater than {1}',
  commonValidationFieldMaxLength: 'TODO-{0} must not be greater than {1} characters',
  commonValidationFieldMin: 'TODO-{0} must not be lower than {1}',
  commonValidationFieldNumeric: 'TODO-{0} should be numeric',
  commonVia: 'TODO-Via',
  commonYes: 'TODO-Yes',
  eodValidationActionDescription: 'TODO-Validate that all movements are correct for the {0}',
  eodValidationConfirmation: 'TODO-This action permanently marks the diagrams/services for {0} as read only. Are you sure you wish to continue ?',
  eodValidationSuccessMessage: 'TODO-End of day validation successful',
  eodValidationValidateButton: 'TODO-Validate',
  fleetAddPopupTitle: 'TODO-Add Fleet',
  fleetEditPopupTitle: 'TODO-Edit Fleet',
  fleetFormationType: 'TODO-Formation Type',
  fleetSaveOrUpdateFailureMessage: 'TODO-Error in saving Fleet',
  fleetSaveOrUpdateSuccessMessage: 'TODO-Successfully saved Fleet {0}',
  fleetSaveOrUpdateValidationFailedMessage: 'TODO-Invalid Fleet Details',
  locationAddPopupTitle: 'TODO-Add Location',
  locationDeleteConfirmation: 'TODO-Are you sure you want to delete {0}?',
  locationDeleteErrorMessage: 'TODO-Error in deleting {0}',
  locationDeleteSucessMessage: 'TODO-Successfully deleted {0}',
  locationDistancePopupTitle: 'TODO-Location Distance',
  locationDistanceSaveOrUpdateValidationFailedMessage: 'TODO-Invalid location distance details',
  locationDistanceSaveFailureMessage: 'TODO-Error in saving location distance details',
  locationDistanceSaveSuccessMessage: 'TODO-Successfully saved location distance details !',
  locationDistanceUpdateFailureMessage: 'TODO-Error in updating location distance details',
  locationDistanceUpdateSuccessMessage: 'TODO-Successfully updated location distance details !',
  locationEditPopupTitle: 'TODO-Edit Location',
  locationIntervention: 'TODO-Intervention',
  locationRestoreConfirmation: 'TODO-Are you sure you want to restore {0}?',
  locationRestoreErrorMessage: 'TODO-Error in restoring {0}',
  locationRestoreSucessMessage: 'TODO-Successfully restored {0}',
  locationSaveOrUpdateFailureMessage: 'TODO-Error in saving location',
  locationSaveOrUpdateSuccessMessage: 'TODO-Successfully saved location {0}',
  locationSaveOrUpdateValidationFailedMessage: 'TODO-Invalid Location Details',
  locationSearchPlaceholder: 'TODO-Location Search',
  locationShortCode: 'TODO-Short Code',
  locationTiploc: 'TODO-Tiploc',
  sideMenuAllocation: 'TODO-Allocation',
  sideMenuChangeReason: 'TODO-Change Reason',
  sideMenuEODValidation: 'TODO-End Of Day Validation',
  sideMenuFleets: 'TODO-Fleets',
  sideMenuLocationDistances: 'TODO-Location Distances',
  sideMenuLocations: 'TODO-Locations',
  sideMenuRestrictions: 'TODO-Restrictions',
  sideMenuSpecialTrafficMovement: 'TODO-Special Traffic Movement',
  specialTrafficAdjustment: 'TODO-Adjustment',
  specialTrafficAdjustmentSaveError: 'TODO-Adjustment can not be saved!',
  specialTrafficAdjustmentSaveSuccess: 'TODO-Adjustment saved successfully',
  specialTrafficIsSpecialTraffic: 'TODO-Special Traffic',
  specialTrafficEndLocation: 'TODO-End Location',
  specialTrafficEndTime: 'TODO-End Time',
  specialTrafficMovement: 'TODO-Special Traffic Movement',
  specialTrafficMovementSaveError: 'TODO-Special movement can not be saved!',
  specialTrafficMovementSaveSuccess: 'TODO-Special movement saved successfully',
  specialTrafficReason: 'TODO-Reason',
  specialTrafficValidationErrorEndTimeSooner: 'TODO-Time value is incorrect, End Time should not be sooner than Start Time!.',
  specialTrafficViaLocation: 'TODO-Via Location',
  specialTrafficSaveConfirmation: 'TODO-This action is permanent and will update the distance covered by {0}. Are you sure you wish to continue?',
  specialTrafficSaveErrorPopupHeader: 'TODO-Please enter the correct values!',
  specialTrafficSaveErrorPopupTitle: 'TODO-Input Error',
  specialTrafficSavePopupTitle: 'TODO-Save {0}',
  specialTrafficStartLocation: 'TODO-Start Location',
  specialTrafficStartTime: 'TODO-Start Time',
  specialTrafficUpdateWebgemini: 'TODO-Update webgemini',
  topMenuManageUsers: 'TODO-Manage Users',
  topMenuProfile: 'TODO-Profile',
  topMenuReportsLibrary: 'TODO-Reports Library',
  topMenuSignOut: 'TODO-Sign Out',
  restrictionAddPopupTitle: 'TODO-Add Restriction',
  restrictionAllowAllocation: 'TODO-Allow Allocation',
  restrictionEditPopupTitle: 'TODO-Edit Restriction',
  restrictionPrompt: 'TODO0-Prompt',
  restrictionSaveOrUpdateFailureMessage: 'TODO-Error in saving Restriction',
  restrictionSaveOrUpdateSuccessMessage: 'TODO-Successfully saved Restriction {0}',
  restrictionSaveOrUpdateValidationFailedMessage: 'TODO-Invalid Restriction Details',
  restrictionShowPrompt: 'TODO-Show Prompt',
  yesCancelPopupTitleConfirmation: 'TODO-Confirmation',
  yesCancelPopupTitleInformation: 'TODO-Information',
  yesCancelPopupTitleWarning: 'TODO-Warning',
};
