import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TrimbleButton } from './FormComponents';
import messages from '../messages';

class SearchField extends Component {
  constructor(props) {
    super(props);
    this.state = { filter: '' };
    this.magnifier = null;
    this.setMagnifier = (e) => {
      this.magnifier = e;
    };
    this.doSearch();
  }

  handleTextChange(e) {
    const textValue = e.target.value;
    this.setState({ filter: textValue });
    if (this.magnifier) {
      const className = textValue.length > 0 ?
        'searchFieldMagnifier fa fa-search' : 'searchFieldMagnifier hiddenButton';
      this.magnifier.className = className;
    }
  }

  handleKeyPress(e) {
    if (e.keyCode === 13) {
      this.doSearch();
    }
  }

  doSearch() {
    if (this.props.doSearch) {
      this.props.doSearch(this.state.filter);
    }
  }

  doNewSearch() {
    this.clearFilter();
    this.doSearch();
  }

  clearSearch() {
    this.clearFilter();
    if (this.props.doSearch) {
      this.props.doSearch('');
    }
  }

  clearFilter() {
    this.setState({ filter: '' });
  }

  render() {
    return (
      <div>
        <div className="searchFieldDiv">
          <input
            type="text"
            placeholder={this.props.placeHolder || messages.commonSearch}
            onChange={e => this.handleTextChange(e)}
            onKeyDown={e => this.handleKeyPress(e)}
            value={this.state.filter}
            className="searchField"
          />
          <button
            type="button"
            className="searchFieldMagnifier hiddenButton"
            ref={this.setMagnifier}
            onClick={() => this.doSearch()}
          />
        </div>
        {
          !this.props.hideClearSearch &&
          <TrimbleButton
            label={messages.commonClearSearch}
            extraCss="clearSearchField searchPanelButton"
            onClick={() => this.clearSearch()}
          />
        }
      </div>
    );
  }
}

SearchField.propTypes = {
  doSearch: PropTypes.func,
  placeHolder: PropTypes.string,
  hideClearSearch: PropTypes.bool,
};
SearchField.defaultProps = {
  doSearch: null,
  placeHolder: '',
  hideClearSearch: false,
};

export default SearchField;
