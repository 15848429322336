import { Get } from '../../utils/ajax';
import { ROOT_URL, SEARCH_LOCATIONS } from '../constants';

function searchLocations(searchText, showDeleted) {
  const searchValue = searchText || '';
  const includeDeletedValue = showDeleted || 'false';
  const response = Get(`${ROOT_URL}/location/search?quickSearch=${searchValue}&includeDeleted=${includeDeletedValue}`);
  return {
    type: SEARCH_LOCATIONS,
    payload: response,
    meta: {
      searchText,
      showDeleted,
    },
  };
}

export default function search(searchText) {
  return (dispatch, getState) => {
    dispatch(searchLocations(searchText, getState().searchLocations.filter.showDeleted));
  };
}

export function searchDeleted(deleted) {
  return (dispatch, getState) => {
    dispatch(searchLocations(getState().searchLocations.filter.searchText, deleted));
  };
}

export function newSearch() {
  return searchLocations('', false);
}

function dispatchPreviousSearch(dispatch, getState) {
  const { filter } = getState().searchLocations;
  dispatch(searchLocations(filter.searchText, filter.showDeleted));
}

export function refreshSearch() {
  return dispatchPreviousSearch;
}
