import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import Tooltip from 'react-tooltip-lite';
import fetchAssetList from '../actions/asset';
import { deallocateAsset } from '../actions/deallocateAsset';
import { DraggableItemTypes, makeDragSource } from '../../utils/drag-n-drop';
import NoteModal from './noteModal';
import { ALLOCATION_TYPE_DIAGRAM, TOOLTIP_PARTIAL_ALLOCATION } from '../constants';
import { isEditingAllowed } from '../util';
import { HighWarningIcon as WarningIcon } from './warningIcon';
import format from '../../utils/numbers';
import messages from '../../messages';

const allocatedAssetDragSource = {
  beginDrag(props) {
    return {
      asset: props.asset,
      diagram: props.diagram,
      diagramLeg: props.diagramLeg,
    };
  },
  canDrag(props) {
    return !props.diagram.readOnly && props.diagramLeg != null &&
    props.diagram.type === ALLOCATION_TYPE_DIAGRAM && isEditingAllowed();
  },
};

class AllocatedAssetRow extends Component {
  constructor(props) {
    super(props);

    this.handleHide = this.handleHide.bind(this);
    this.successCallBack = this.successCallBack.bind(this);

    this.state = {
      show: false,
    };
  }

  getFleetStyle() {
    const { asset } = this.props;
    if (asset.fleet) {
      return {
        color: asset.fleet.textColour,
        backgroundColor: asset.fleet.fillColour,
      };
    }
    return {};
  }

  handleRemove() {
    const { asset, diagram, diagramLeg } = this.props;
    this.props.onAssetRemove(
      asset,
      diagram,
      diagramLeg,
      () => this.successCallBack(),
      false,
    );
  }

  successCallBack() {
    this.props.onSuccessCallBack(
      this.props.searchText,
      this.props.onlyAvailable,
    );
  }

  handleAssetClick() {
    this.setState({
      show: true,
    });
  }

  handleHide() {
    this.setState({ show: false });
  }

  renderClickableAsset() {
    const {
      asset, diagram, diagramLeg, assetClickable,
    } = this.props;
    let onClickCallback = () => true;
    let extraCss = '';
    let role = 'presentation';
    if (!diagram.readOnly && assetClickable !== 'false') {
      onClickCallback = () => this.handleAssetClick(asset);
      extraCss = 'clickableAsset';
      role = 'button';
    }
    return (
      <span // eslint-disable-line jsx-a11y/no-static-element-interactions
        className={`allocatedAsset ${extraCss}`}
        onClick={onClickCallback}
        onKeyDown={onClickCallback}
        style={this.getFleetStyle()}
        role={role}
        data-tip-disable={!diagramLeg || diagramLeg.lastLeg}
      >
        {this.renderAssetCode()}
      </span>
    );
  }

  renderAssetCode() {
    const { asset, diagramLeg } = this.props;
    if (!diagramLeg || diagramLeg.lastLeg) {
      return asset.code;
    }
    const swapFuel = messages.formatString(
      messages.allocationUnitSwapFuel,
      format(asset.startFuelLevel),
    );
    return (
      <Tooltip
        content={swapFuel}
        tipContentClassName="allocatedAssetToolTip"
        padding="5px"
        tagName="span"
        direction="right"
        arrowSize={5}
        color="#fff"
        background="#337AB7"
      >
        {asset.code}
      </Tooltip>
    );
  }

  renderTrashIcon() {
    const { diagram, diagramLeg } = this.props;
    if (
      diagram.readOnly ||
      !isEditingAllowed() ||
      (diagramLeg && diagramLeg.lastLeg)
    ) {
      return '';
    }
    return (
      <i
        className="remove fa fa-trash assetRemove"
        title={messages.allocationUnitDeallocateTooltip}
        onClick={() => this.handleRemove()}
        onKeyDown={() => this.handleRemove()}
        role="button"
        tabIndex={0}
      />
    );
  }

  renderWarningIcon() {
    if (!this.props.showWarningIcon) {
      return '';
    }
    return (
      <WarningIcon
        iconClassName="assetWarning"
        iconTooltip={TOOLTIP_PARTIAL_ALLOCATION}
      />
    );
  }

  renderAsset() {
    const { isDragging, supportsDrag, asset } = this.props;
    const className = `allocatedAsset list-group-item ${
      supportsDrag ? 'draggable' : ''
    } ${isDragging ? 'dragging' : ''}`;
    return (
      <li className={className}>
        {this.renderTrashIcon()}
        {this.renderWarningIcon()}
        {this.renderClickableAsset()}
        <NoteModal
          asset={asset}
          diagram={this.props.diagram}
          show={this.state.show}
          handleHide={this.handleHide}
        />
      </li>
    );
  }

  render() {
    const { connectDragSource } = this.props;
    return connectDragSource(this.renderAsset());
  }
}

AllocatedAssetRow.propTypes = {
  onSuccessCallBack: PropTypes.func,
  searchText: PropTypes.string,
  onlyAvailable: PropTypes.bool,
  asset: PropTypes.shape({
    code: PropTypes.string,
    startFuelLevel: PropTypes.number,
    fleet: PropTypes.shape({
      textColour: PropTypes.string,
      fillColour: PropTypes.string,
    }),
  }),
  diagram: PropTypes.shape({
    readOnly: PropTypes.bool,
  }),
  diagramLeg: PropTypes.shape({
    lastLeg: PropTypes.string,
  }),
  onAssetRemove: PropTypes.func,
  assetClickable: PropTypes.func,
  connectDragSource: PropTypes.func,
  supportsDrag: PropTypes.bool,
  isDragging: PropTypes.bool,
  showWarningIcon: PropTypes.bool,
};
AllocatedAssetRow.defaultProps = {
  onSuccessCallBack: null,
  searchText: '',
  onlyAvailable: true,
  asset: null,
  diagram: {},
  diagramLeg: {},
  onAssetRemove: null,
  assetClickable: null,
  connectDragSource: null,
  supportsDrag: false,
  isDragging: false,
  showWarningIcon: false,
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      onAssetRemove: deallocateAsset,
      onSuccessCallBack: fetchAssetList,
    },
    dispatch,
  );
}

const draggableAllocatedAsset = makeDragSource(
  AllocatedAssetRow,
  DraggableItemTypes.ALLOCATED_ASSET,
  allocatedAssetDragSource,
);

function mapStateToProps({ asset }) {
  return {
    onlyAvailable: asset.filter.onlyAvailable,
    searchText: asset.filter.searchText,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(draggableAllocatedAsset);
