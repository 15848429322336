import React from 'react';
import FilterPanel from './components/filterPanel';
import RestrictionsTable from './components/restrictionsTable';
import { RESTRICTIONS_SCREEN_EDIT } from '../kc_roles';
import messages from '../messages';

const Restrictions = () => (
  <div>
    <div className="col-xs-12 filtersColumn">
      <div className="table-responsive">
        <FilterPanel
          addPopuptitle={messages.restrictionAddPopupTitle}
          addPopupformModal={{ color: '#000' }}
          editLicence={RESTRICTIONS_SCREEN_EDIT}
        />
      </div>
    </div>
    <div className="col-xs-12 location">
      <div className="table-responsive">
        <RestrictionsTable
          editPopuptitle={messages.restrictionEditPopupTitle}
          editLicence={RESTRICTIONS_SCREEN_EDIT}
          extraTableCss="compactFirstColumn"
        />
      </div>
    </div>
  </div>
);

export default Restrictions;

