import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DraggableItemTypes, makeDropTarget } from '../../utils/drag-n-drop';
import AllocatedAssetList from './allocatedAssetList';
import { ALLOCATION_TYPE_DIAGRAM } from '../constants';
import { isEditingAllowed } from '../util';

// Available asset drop support
const availableAssetDropSpec = {
  drop(props, monitor) {
    props.onAssetDrop(
      monitor.getItem().asset,
      props.diagram,
      props.diagramLeg,
      props.onSuccessCallBack,
      false,
    );
  },
  canDrop(props) {
    return (
      !props.diagram.readOnly &&
      props.diagram.type === ALLOCATION_TYPE_DIAGRAM &&
      isEditingAllowed()
    );
  },
};

function availableAssetDropCollector(connect, monitor) {
  return {
    availableAssetConnectDropTarget: connect.dropTarget(),
    isAvailableAssetOver: monitor.isOver(),
    canDropAvailableAsset: monitor.canDrop(),
  };
}

// Allocated asset drop support

const allocatedAssetDropSpec = {
  drop(props, monitor) {
    const dragDetails = monitor.getItem();
    props.onAssetReallocation(
      dragDetails.asset,
      dragDetails.diagram,
      dragDetails.diagramLeg,
      props.diagram,
      props.diagramLeg,
    );
  },
  canDrop(props, monitor) {
    return (
      !props.diagram.readOnly &&
      props.diagramLeg.id !== monitor.getItem().diagramLeg.id &&
      props.diagram.type === ALLOCATION_TYPE_DIAGRAM
    );
  },
};

function allocatedAssetDropCollector(connect, monitor) {
  return {
    allocatedAssetConnectDropTarget: connect.dropTarget(),
    isAllocatedAssetOver: monitor.isOver() && monitor.canDrop(),
    canDropAllocatedAsset: monitor.canDrop(),
  };
}

class DiagramLegRow extends Component {
  renderDiagramLegRow() {
    const {
      diagram,
      diagramLeg,
      isAvailableAssetOver,
      isAllocatedAssetOver,
      canDropAvailableAsset,
      canDropAllocatedAsset,
    } = this.props;
    const isOver = isAvailableAssetOver || isAllocatedAssetOver;
    const canDrop = canDropAvailableAsset || canDropAllocatedAsset;
    const dragNdropclassNames = `diagramLegCol ${canDrop ? 'droppable' : ''} ${
      isOver ? 'over' : ''
    }`;

    return (
      <tr
        key={diagramLeg.id}
        id={`diagramLeg${diagramLeg.id}`}
        className={`diagramLegRow collapse ${diagram.expanded ? 'in' : ''}`}
      >
        <td className={`${dragNdropclassNames}`}> {diagramLeg.location}</td>
        <td className={`${dragNdropclassNames}`}>
          {diagramLeg.arrival ? diagramLeg.arrival.slice(0, -3) : ''}
        </td>
        <td className={`${dragNdropclassNames}`}>
          {diagramLeg.departure ? diagramLeg.departure.slice(0, -3) : ''}
        </td>
        <td className={`${dragNdropclassNames}`}>{diagramLeg.headCode}</td>
        <td className={`${dragNdropclassNames}`}>{diagramLeg.perform}</td>
        <td className={`${dragNdropclassNames}`}>{diagramLeg.formation}</td>
        <td className={`${dragNdropclassNames}`}>
          <AllocatedAssetList
            diagram={diagram}
            diagramLeg={diagramLeg}
            unitList={diagramLeg.units}
            assetClickable="false"
          />
        </td>
        <td className={`${dragNdropclassNames}`}>{diagramLeg.distance}</td>
        <td className={`${dragNdropclassNames}`}>{diagramLeg.totalDistance}</td>
      </tr>
    );
  }

  render() {
    const { availableAssetConnectDropTarget, allocatedAssetConnectDropTarget } =
      this.props;
    const availableDropSupport = availableAssetConnectDropTarget(this.renderDiagramLegRow());
    return allocatedAssetConnectDropTarget(availableDropSupport);
  }
}

DiagramLegRow.propTypes = {
  diagram: PropTypes.shape({
    id: PropTypes.number,
    expanded: PropTypes.bool,
  }),
  diagramLeg: PropTypes.shape({
    id: PropTypes.number,
    location: PropTypes.string,
    arrival: PropTypes.string,
    departure: PropTypes.string,
    headCode: PropTypes.string,
    perform: PropTypes.string,
    formation: PropTypes.string,
    units: PropTypes.arrayOf(PropTypes.shape({})),
    distance: PropTypes.number,
    totalDistance: PropTypes.number,
  }),
  isAvailableAssetOver: PropTypes.bool,
  isAllocatedAssetOver: PropTypes.bool,
  canDropAvailableAsset: PropTypes.bool,
  canDropAllocatedAsset: PropTypes.bool,
  availableAssetConnectDropTarget: PropTypes.func,
  allocatedAssetConnectDropTarget: PropTypes.func,
};
DiagramLegRow.defaultProps = {
  diagram: {},
  diagramLeg: {},
  isAvailableAssetOver: false,
  isAllocatedAssetOver: false,
  canDropAvailableAsset: false,
  canDropAllocatedAsset: false,
  availableAssetConnectDropTarget: null,
  allocatedAssetConnectDropTarget: null,
};

const availableAssetDropTarget = makeDropTarget(
  DiagramLegRow,
  DraggableItemTypes.ASSET,
  availableAssetDropSpec,
  availableAssetDropCollector,
);
const allocatedAndAvailableAssetDropTarget = makeDropTarget(
  availableAssetDropTarget,
  DraggableItemTypes.ALLOCATED_ASSET,
  allocatedAssetDropSpec,
  allocatedAssetDropCollector,
);

export default allocatedAndAvailableAssetDropTarget;
