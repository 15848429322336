import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AllocatedAsset from './allocatedAsset';
import SevereWarningIcon, { HighWarningIcon } from './warningIcon';
import { TOOLTIP_NO_ALLOCATION_DIAGRAM, TOOLTIP_NO_ALLOCATION_DIAGRAM_LEG } from '../constants';

class AllocatedAssetList extends Component {
  renderNoUnitsWarningIcon() {
    const { diagram, diagramLeg } = this.props;
    const toolTip = diagramLeg
      ? TOOLTIP_NO_ALLOCATION_DIAGRAM_LEG
      : TOOLTIP_NO_ALLOCATION_DIAGRAM;
    if (diagramLeg && diagram.unitList && diagram.unitList.length > 0) {
      return (
        <HighWarningIcon iconClassName="assetWarning" iconTooltip={toolTip} />
      );
    }
    return (
      <SevereWarningIcon iconClassName="assetWarning" iconTooltip={toolTip} />
    );
  }
  renderAllocatedAssetRows() {
    const { diagram, diagramLeg } = this.props;
    let { unitList } = this.props;
    if (!unitList) {
      unitList = [];
    }
    let key = diagram.diagramId;
    key = (key * 13) + (diagramLeg ? diagramLeg.id : 0);
    const assetRows = unitList.map(unit => (
      <AllocatedAsset
        key={(key * 13) + unit.id}
        asset={unit}
        diagram={diagram}
        diagramLeg={diagramLeg}
        assetClickable={this.props.assetClickable}
        showWarningIcon={diagram.hasUnallocatedLegs && !diagramLeg}
      />
    ));
    if (unitList.length === 0) {
      assetRows.splice(
        assetRows.length,
        0,
        <li key={key * 13} className="list-group-item allocatedAsset">
          {this.renderNoUnitsWarningIcon()}
        </li>,
      );
    }
    return assetRows;
  }

  render() {
    const { diagram, diagramLeg } = this.props;
    const classname = `list-group allocatedAssets ${
      diagram ? 'diagramLevel' : ''
    } ${diagramLeg ? 'diagramLegLevel' : ''}`;
    return <ul className={classname}>{this.renderAllocatedAssetRows()}</ul>;
  }
}

AllocatedAssetList.propTypes = {
  diagram: PropTypes.shape({
    id: PropTypes.number,
    diagramId: PropTypes.number,
    unitList: PropTypes.arrayOf(PropTypes.shape({})),
    hasUnallocatedLegs: PropTypes.bool,
  }),
  diagramLeg: PropTypes.shape({
    id: PropTypes.number,
  }),
  unitList: PropTypes.arrayOf(PropTypes.shape({})),
  assetClickable: PropTypes.string,
};
AllocatedAssetList.defaultProps = {
  diagram: {},
  diagramLeg: {},
  unitList: [],
  assetClickable: null,
};

export default AllocatedAssetList;
