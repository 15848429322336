import { Post } from '../../utils/ajax';
import { ROOT_URL, ASSIGN_RESTRICTIONS } from '../constants';

function assignAssetRestrictions(asset, restrictionIds) {
  const availableAsset = (asset && !asset.id) ? asset : null;
  const result = (dispatch) => {
    Post(`${ROOT_URL}/restriction/assign`, { assetId: asset.id, availableAsset, restrictionIds })
      .then((response) => {
        dispatch({
          type: ASSIGN_RESTRICTIONS,
          payload: {
            restrictions: response.data,
            asset,
          },
        });
      }).catch(error => console.log(error));
  };
  return result;
}

export default assignAssetRestrictions;
