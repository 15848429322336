import React from 'react';
import PropTypes from 'prop-types';
import severeWarningIcon from '../noUnitWarningIcon.png';
import highWarningIcon from '../orange-warning.png';

const SevereWarningIcon = ({ iconClassName, iconTooltip }) => (
  <img
    src={severeWarningIcon}
    alt="Severe Warning Icon"
    className={iconClassName}
    title={iconTooltip}
  />
);
SevereWarningIcon.propTypes = {
  iconClassName: PropTypes.string,
  iconTooltip: PropTypes.string,
};
SevereWarningIcon.defaultProps = {
  iconClassName: '',
  iconTooltip: '',
};

export default SevereWarningIcon;

export const HighWarningIcon = ({ iconClassName, iconTooltip }) => (
  <img
    src={highWarningIcon}
    alt="High Warning Icon"
    className={iconClassName}
    title={iconTooltip}
  />
);
HighWarningIcon.propTypes = {
  iconClassName: PropTypes.string,
  iconTooltip: PropTypes.string,
};
HighWarningIcon.defaultProps = {
  iconClassName: '',
  iconTooltip: '',
};
