import {
  ROOT_URL,
  SAVE_RESTRICTION,
  RESTRICTION_CODE_LENGTH,
  RESTRICTION_DESCRIPTION_LENGTH,
} from '../constants';
import { validateMandatoryFields } from '../../utils/validation';
import { createSaveAction } from '../../utils/actionHelper';
import messages from '../../messages';

function validateRestriction(restriction) {
  const errors = [];
  validateMandatoryFields(
    restriction,
    {
      code: { name: messages.commonCode, maxLegnth: RESTRICTION_CODE_LENGTH },
      description: { name: messages.commonDescription, maxLength: RESTRICTION_DESCRIPTION_LENGTH },
    },
    errors,
  );
  return errors;
}

export default function saveRestriction(restriction, successCallback) {
  return createSaveAction({
    saveObject: restriction,
    validationFunction: validateRestriction,
    validationFailedMsag: messages.restrictionSaveOrUpdateValidationFailedMessage,
    saveUrl: `${ROOT_URL}/restriction/saveOrUpdate`,
    errorInSavingMsg: messages.restrictionSaveOrUpdateFailureMessage,
    successfullySavedMsg: messages.formatString(messages.restrictionSaveOrUpdateSuccessMessage, restriction.description || ''),
    dispatchType: SAVE_RESTRICTION,
    successCallback,
  });
}

