import React from 'react';

function doSortColumn(column, currentlySortedAscending, sortFunction) {
  if (!column) {
    return;
  }
  const ascending = (currentlySortedAscending != null ? !currentlySortedAscending : true);
  const sortColumn = `${ascending ? '+' : '-'}${column}`;
  sortFunction(sortColumn);
}

function isSortedAscending(sortColumn, currentSort) {
  let sortedAscending = null;
  if (currentSort && currentSort.substring(1) === sortColumn) {
    sortedAscending = currentSort.charAt(0) === '+';
  }
  return sortedAscending;
}

export default function renderSortableColumnHeader(
  label,
  sortColumn,
  currentSort,
  sortFunction,
  uniqueKey,
) {
  const sortedAscending = isSortedAscending(sortColumn, currentSort);
  return (
    <th
      key={uniqueKey || label}
      className={`${sortColumn != null ? 'clickableColumn' : ''}`}
      onClick={() => doSortColumn(sortColumn, sortedAscending, sortFunction)}
    >
      {
        sortedAscending != null &&
        <i key={`i_${uniqueKey || label}`} className={`fa fa-sort-${sortedAscending ? 'up' : 'down'}`} aria-hidden="true" />
      }
      {label}
    </th>
  );
}

