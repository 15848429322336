import React from 'react';
import { connect } from 'react-redux';
import saveRestriction from '../actions/saveRestriction';
import GenericFormModal from '../../components/GenericFormModal';
import { getDefaultFieldCss } from '../../components/FormComponents';
import {
  RESTRICTION_CODE_LENGTH,
  RESTRICTION_DESCRIPTION_LENGTH,
} from '../constants';
import messages from '../../messages';

class RestrictionModal extends GenericFormModal {
  setFormModalField(field, value) {
    if (field === 'canAllocate' && !value) {
      super.setFormModalFields({ canAllocate: false, showPrompt: false }, false);
    } else {
      super.setFormModalField(field, value);
    }
  }

  renderBody() {
    return (
      <span>
        {this.renderInputField(messages.commonDescription, 'description', RESTRICTION_DESCRIPTION_LENGTH)}
        {this.renderInputField(messages.commonCode, 'code', RESTRICTION_CODE_LENGTH)}
        {this.renderCheckboxField(
          messages.restrictionAllowAllocation,
          'canAllocate',
          {},
          Object.assign(
            getDefaultFieldCss(),
            { labelCss: 'col-xs-4 col-form-label', midDivCss: 'col-xs-8' },
          ),
        )}
        {this.renderCheckboxField(
          messages.restrictionShowPrompt,
          'showPrompt',
          { disabled: !this.getFormModalField('canAllocate') },
        )}
        {this.renderColorPicker(messages.commonFillColour, 'color')}
      </span>
    );
  }
}

export default connect(null, { saveFormModal: saveRestriction })(RestrictionModal);
