export const ALLOCATION_SCREEN = 'ui.screens.allocation';
export const ALLOCATION_SCREEN_EDIT = 'ui.screens.allocation.edit';
export const ALLOCATION_SCREEN_UNIT_DETAILS_EDIT = 'ui.screens.allocation.unit.details.edit';
export const SPECIAL_MOVEMENT_SCREEN = 'ui.screens.specialmovements';
export const EOD_VALIDATION_SCREEN = 'ui.screens.eodvalidation';
export const LOCATION_DISTANCE_SCREEN = 'ui.screens.locationdistances';
export const LOCATION_DISTANCE_SCREEN_EDIT = 'ui.screens.locationdistances.edit';
export const LOCATIONS_SCREEN_VIEW = 'ui.screens.locations';
export const LOCATIONS_SCREEN_EDIT = 'ui.screens.locations.edit';
export const CHANGE_REASON_SCREEN = 'ui.screens.changereason';
export const RESTRICTIONS_SCREEN_VIEW = 'ui.screens.restrictions';
export const RESTRICTIONS_SCREEN_EDIT = 'ui.screens.restrictions.edit';
export const CHANGE_REASON_SCREEN_EDIT = 'ui.screens.changereason.edit';
export const FLEETS_SCREEN_VIEW = 'ui.screens.fleets';
export const FLEETS_SCREEN_EDIT = 'ui.screens.fleets.edit';
export const REPORTS_LIBRARY_MENU = 'ui.menu.reportslibrary';
