import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import Modal from 'react-bootstrap-modal';
import PropTypes from 'prop-types';
import { Get } from '../../utils/ajax';
import { ROOT_URL } from '../constants';
import addAssetNote from '../actions/assetNote';
import assignAssetRestrictions from '../actions/assetRestriction';
import { TrimbleButton } from '../../components/FormComponents';
import { isNoteEditingAllowed, isRestrictionEditingAllowed } from '../util';
import messages from '../../messages';

class Tab {
  constructor(id, labelKey) {
    this.id = id;
    this.labelKey = labelKey;
  }

  getId() {
    return this.id;
  }

  getLabelKey() {
    return this.labelKey;
  }

  getLabel() {
    return messages[this.labelKey];
  }
}

const NOTE_TAB = new Tab('NOTE', 'allocationUnitNotes');
const RESTRICTION_TAB = new Tab('RESTRICTION', 'allocationUnitRestrictions');
const TABS = [NOTE_TAB, RESTRICTION_TAB];

class NoteModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isChecked: this.props.asset.checked,
      textValue: this.props.asset.noteDescription,
      restrictionList: [],
      restrictionLoaded: false,
      selectedRestrictionIds: [],
      currentTab: NOTE_TAB,
    };
  }
  componentWillReceiveProps() {
    let currentRestrictionIds = [];
    if (this.props.asset.restrictions) {
      currentRestrictionIds = this.props.asset.restrictions.map(r => r.id);
    }
    this.setState({
      isChecked: this.props.asset.checked,
      textValue: this.props.asset.noteDescription,
      selectedRestrictionIds: currentRestrictionIds,
    });
  }

  onChange = e => this.setState({ textValue: e.target.value });

  onRestrictionSelect(id, event) {
    const selectedIds = this.state.selectedRestrictionIds;
    if (event.target.checked) {
      selectedIds.push(id);
    } else {
      _.remove(selectedIds, curId => curId === id);
    }
    this.setState({ selectedRestrictionIds: _.concat([], selectedIds) });
  }

  onTabSelect(tab) {
    if (tab === RESTRICTION_TAB && !this.state.restrictionLoaded) {
      Get(`${ROOT_URL}/restriction/all`).then((response) => {
        this.setState({
          currentTab: tab,
          restrictionList: response.data,
          restrictionLoaded: true,
        });
      });
    } else {
      this.setState({ currentTab: tab });
    }
  }

  handleRestrictionSave() {
    this.props.assignAssetRestrictions(
      this.props.asset,
      this.state.selectedRestrictionIds,
    );
    this.props.handleHide();
  }

  handleClear = () => {
    this.props.addAssetNote(this.props.asset, '', false);
    this.props.handleHide();
  };

  handleSave = () => {
    this.props.addAssetNote(
      this.props.asset,
      this.state.textValue,
      this.state.isChecked,
    );
    this.props.handleHide();
  };

  toggleChange = () => {
    this.setState({
      isChecked: !this.state.isChecked,
    });
  };

  renderTab(tab) {
    let className = 'nav-link';
    if (this.state.currentTab === tab) {
      className = `${className} active`;
    }
    return (
      <li className="nav-item" key={tab.id}>
        <a
          className={className}
          onClick={() => this.onTabSelect(tab)}
          onKeyDown={() => this.onTabSelect(tab)}
          role="presentation"
        >
          {tab.getLabel()}
        </a>
      </li>
    );
  }

  renderTabContent() {
    if (this.state.currentTab === NOTE_TAB) {
      return this.renderNoteTabContent();
    }
    if (this.state.currentTab === RESTRICTION_TAB) {
      return this.renderRestrictionTabContent();
    }
    return null;
  }

  renderNoteTabContent() {
    return (
      <div className="tab-content">
        <div>
          <textarea
            // autoFocus
            placeholder={messages.allocationUnitNotesPlaceholder}
            cols="40"
            rows="5"
            value={this.state.textValue || ''}
            onChange={this.onChange}
            disabled={!isNoteEditingAllowed()}
          />
        </div>
        <div>
          <label id="resolvedLabel">
            {messages.allocationUnitNotesResolved}:
            <input
              name="resolved"
              type="checkbox"
              checked={this.state.isChecked}
              onChange={this.toggleChange}
              disabled={!isNoteEditingAllowed()}
            />
          </label>
        </div>
      </div>
    );
  }

  renderRestrictionTabContent() {
    return (
      <div
        className="tab-content"
        style={{ maxHeight: '450px', overflowY: 'auto' }}
      >
        <table className="table">
          <thead>
            <tr>
              <th />
              <th>{messages.commonDescription}</th>
              <th>{messages.allocationUnitRestrictionAllowAllocation}</th>
            </tr>
          </thead>
          <tbody>
            {this.state.restrictionList.map(r => (
              <tr key={r.id}>
                <td>
                  <input
                    name={r.id}
                    type="checkbox"
                    checked={
                      this.state.selectedRestrictionIds.indexOf(r.id) >= 0
                    }
                    onChange={e => this.onRestrictionSelect(r.id, e)}
                    disabled={!isRestrictionEditingAllowed()}
                  />
                </td>
                <td>{r.description}</td>
                <td>
                  {r.canAllocate
                    ? r.showPrompt
                      ? messages.allocationUnitRestrictionPrompt
                      : messages.commonYes
                    : messages.commonNo}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  renderTabFooter() {
    if (this.state.currentTab === NOTE_TAB) {
      return this.renderNoteTabFooter();
    }
    if (this.state.currentTab === RESTRICTION_TAB) {
      return this.renderRestrictionTabFooter();
    }
    return null;
  }

  renderNoteTabFooter() {
    if (!isNoteEditingAllowed()) {
      return '';
    }
    return (
      <div>
        <TrimbleButton
          label={messages.commonClear}
          onClick={() => this.handleClear()}
        />
        <TrimbleButton
          label={messages.commonSave}
          onClick={() => this.handleSave()}
        />
      </div>
    );
  }

  renderRestrictionTabFooter() {
    if (!isRestrictionEditingAllowed()) {
      return '';
    }
    return (
      <div>
        <TrimbleButton
          label={messages.commonCancel}
          onClick={() => this.props.handleHide()}
        />
        <TrimbleButton
          label={messages.commonSave}
          onClick={() => this.handleRestrictionSave()}
        />
      </div>
    );
  }

  render() {
    return (
      <Modal
        onHide={this.props.handleHide}
        show={this.props.show}
        backdrop="static"
        className="appModal"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {messages.commonUnit} : {this.props.asset.code}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul className="nav nav-tabs asset-modal">
            {TABS.map(tab => this.renderTab(tab))}
          </ul>
          {this.renderTabContent()}
        </Modal.Body>
        <Modal.Footer>{this.renderTabFooter()}</Modal.Footer>
      </Modal>
    );
  }
}

NoteModal.propTypes = {
  asset: PropTypes.shape({
    code: PropTypes.string,
    checked: PropTypes.bool,
    noteDescription: PropTypes.string,
    restrictions: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  addAssetNote: PropTypes.func,
  handleHide: PropTypes.func,
  assignAssetRestrictions: PropTypes.func,
  show: PropTypes.bool,
};
NoteModal.defaultProps = {
  asset: {},
  addAssetNote: null,
  handleHide: null,
  assignAssetRestrictions: null,
  show: false,
};

export default connect(null, {
  addAssetNote,
  assignAssetRestrictions,
})(NoteModal);
