import { FETCH_CURRENT_DIAGRAM_DATE } from '../constants';

export default function currentDiagramDate(state = new Date(), action) {
  if (action.error) {
    return state;
  }
  switch (action.type) {
    case FETCH_CURRENT_DIAGRAM_DATE:
      return new Date(action.payload.data);
    default:
      return state;
  }
}
