import { Link } from 'react-router-dom';
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import kc from '../index';
import { hasLicence } from './licenced_components';
import { REPORTS_LIBRARY_MENU } from '../kc_roles';
import messages from '../messages';
import ReleaseVersion from './ReleaseVersion';

const ManageUsersLink = () => {
  if (kc.hasResourceRole('manage-users', 'realm-management')) {
    return (
      <li className="hidden-xs">
        <a
          href={`${kc.authServerUrl}/admin/${kc.realm.toLowerCase()}/console`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {messages.topMenuManageUsers}
        </a>
      </li>
    );
  }
  return '';
};

const ReportsLibraryLinkBasic = ({ reportsLibraryUrl }) => {
  if (hasLicence(REPORTS_LIBRARY_MENU)
    && reportsLibraryUrl
    && reportsLibraryUrl.trim().length > 0) {
    return (
      <li className="hidden-xs">
        <a
          href={reportsLibraryUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          {messages.topMenuReportsLibrary}
        </a>
      </li>
    );
  }
  return '';
};
ReportsLibraryLinkBasic.propTypes = {
  reportsLibraryUrl: PropTypes.string,
};
ReportsLibraryLinkBasic.defaultProps = {
  reportsLibraryUrl: '',
};

const ReportsLibraryLink = connect(
  ({ clientSettings }) => ({ reportsLibraryUrl: clientSettings.reportsLibraryUrl }),
  null,
)(ReportsLibraryLinkBasic);

const handleLogOut = () => {
  kc.logout();
  localStorage.removeItem('kc_token');
  localStorage.removeItem('kc_refreshToken');
};

const Top = () => (
  <header id="header" className="clearfix" data-ma-theme="trimble-white">
    <div className="app-title ng-scope">
      <ReleaseVersion />
    </div>
    <ul className="h-inner">
      <li
        className="hi-trigger ma-trigger"
        data-ma-action="sidebar-open"
        data-ma-target="#sidebar"
      >
        <div className="line-wrap">
          <div className="line top" />
          <div className="line center" />
          <div className="line bottom" />
        </div>
      </li>

      <li className="hi-logo hidden-xs">
        <Link to="/">
          <img src="images/trimble-logo-blue.png" alt="Trimble" />
        </Link>
      </li>

      <li className="pull-right">
        <ul className="hi-menu">
          <li className="dropdown">
            <a data-toggle="dropdown" href="">
              <img src="images/grid-black.png" alt="Grid-icon" />
            </a>
            <ul className="dropdown-menu dm-icon pull-right">
              <li className="hidden-xs">
                {' '}
                <a href={kc.createAccountUrl()}>
                  {messages.topMenuProfile}
                </a>{' '}
              </li>
              <ManageUsersLink />
              <ReportsLibraryLink />
              <li className="hidden-xs">
                {' '}
                <a href={kc.createLogoutUrl()} onClick={handleLogOut}>
                  {messages.topMenuSignOut}
                </a>{' '}
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
  </header>
);

export default Top;
