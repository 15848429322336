import React from 'react';
import Modal from 'react-bootstrap-modal';
import PropTypes from 'prop-types';
import { TrimbleButton } from '../../components/FormComponents';
import searchResultTable from '../../components/searchResultTable';
import format from '../../utils/numbers';
import messages from '../../messages';

const ColgroupComponent = () => (
  <colgroup>
    <col width="6%" />
    <col width="7%" />
    <col width="8%" />
    <col width="12%" />
    <col width="8%" />
    <col width="12%" />
    <col width="6%" />
    <col width="9%" />
    <col width="5%" />
    <col width="9%" />
    <col width="9%" />
    <col width="9%" />
  </colgroup>
);

const HeaderValueComponent = ({ value }) => (
  <span className="text-no-wrap">{messages[value]}</span>
);
HeaderValueComponent.propTypes = {
  value: PropTypes.string,
};
HeaderValueComponent.defaultProps = {
  value: null,
};

const FuelLevelMovementRow = ({ rowObject }) => (
  <tr>
    <td>{rowObject.allocationName}</td>
    <td>{rowObject.headCode}</td>
    <td className="text-no-wrap">{rowObject.startTime}</td>
    <td className="text-no-wrap">{rowObject.startLocation}</td>
    <td className="text-no-wrap">{rowObject.endTime}</td>
    <td className="text-no-wrap">{rowObject.endLocation}</td>
    <td>{format(rowObject.distance)}</td>
    <td>{format(rowObject.totalDistance)}</td>
    <td>{format(rowObject.fuel)}</td>
    <td>{format(rowObject.totalFuel)}</td>
    <td>{format(rowObject.startFuelLevel)}</td>
    <td>{format(rowObject.endFuelLevel)}</td>
  </tr>
);
FuelLevelMovementRow.propTypes = {
  rowObject: PropTypes.shape({
    allocationName: PropTypes.string,
    headCode: PropTypes.string,
    startTime: PropTypes.string,
    startLocation: PropTypes.string,
    endTime: PropTypes.string,
    endLocation: PropTypes.string,
    distance: PropTypes.number,
    totalDistance: PropTypes.number,
    fuel: PropTypes.number,
    totalFuel: PropTypes.number,
    startFuelLevel: PropTypes.number,
    endFuelLevel: PropTypes.number,
  }),
};
FuelLevelMovementRow.defaultProps = {
  rowObject: {},
};

const FuelLevelMovementFooterRow = ({ footerObject }) => (
  <tr>
    <td colSpan="6">&nbsp;</td>
    <td>{messages.allocationUnitFuelLevelTotalCumulativeDistance}</td>
    <td>{format(footerObject.totalDistance)}</td>
    <td>{messages.allocationUnitFuelLevelTotalCumulativeFuel}</td>
    <td>{format(footerObject.totalFuel)}</td>
    <td>{messages.allocationUnitFuelLevelTotalCumulativeEODFuel}</td>
    <td>{format(footerObject.eodFuel)}</td>
  </tr>
);
FuelLevelMovementFooterRow.propTypes = {
  footerObject: PropTypes.shape({
    totalDistance: PropTypes.number,
    totalFuel: PropTypes.number,
    eodFuel: PropTypes.number,
  }),
};
FuelLevelMovementFooterRow.defaultProps = {
  footerObject: {},
};

const FuelLevelMovementTable = searchResultTable(
  ['allocationUnitFuelLevelDiagram', 'commonHeadCode', 'allocationUnitFuelLevelStart',
    'allocationUnitFuelLevelStartLocation', 'allocationUnitFuelLevelFinish', 'allocationUnitFuelLevelFinishLocation',
    'allocationUnitFuelLevelDistance', 'allocationUnitFuelLevelTotalDistance', 'allocationUnitFuelLevelFuel',
    'allocationUnitFuelLevelTotalFuel', 'allocationUnitFuelLevelStartFuel', 'allocationUnitFuelLevelEndFuel'],
  FuelLevelMovementRow,
  HeaderValueComponent,
  FuelLevelMovementFooterRow,
  ColgroupComponent,
);

const FuelLevelMovementModal = ({
  eodFuelLevelInfo,
  asset,
  handleHide,
  show,
}) => (
  <Modal
    onHide={handleHide}
    show={show}
    backdrop="static"
    className="appModal allocationFont"
    dialogClassName="modal-xl"
  >
    <Modal.Header closeButton>
      <Modal.Title>
        {messages.allocationUnitFuelLevel} : {asset.code}
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div style={{ maxHeight: '450px', overflowY: 'auto' }}>
        <FuelLevelMovementTable
          rowObjects={eodFuelLevelInfo.fuelLevelMovements}
          footerRowObjects={[eodFuelLevelInfo]}
          extraTableCss="compact fuelLevelMovementTable"
        />
      </div>
    </Modal.Body>
    <Modal.Footer>
      <div>
        <TrimbleButton label={messages.commonOk} onClick={() => handleHide()} />
      </div>
    </Modal.Footer>
  </Modal>
);
FuelLevelMovementModal.propTypes = {
  eodFuelLevelInfo: PropTypes.shape({
    fuelLevelMovements: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  asset: PropTypes.shape({ code: PropTypes.string }),
  handleHide: PropTypes.func,
  show: PropTypes.bool,
};
FuelLevelMovementModal.defaultProps = {
  eodFuelLevelInfo: {},
  asset: null,
  handleHide: null,
  show: false,
};

export default FuelLevelMovementModal;
