import {
  ROOT_URL,
  RESTORE_LOCATION,
} from '../constants';
import { Post } from '../../utils/ajax';
import { showConfirmation } from '../../actions/YesCancel';
import { createServerAction } from '../../utils/actionHelper';
import messages from '../../messages';

export default function restoreLocation(location, successCallback, confirmed = false) {
  if (!confirmed) {
    return showConfirmation(
      messages.formatString(messages.locationRestoreConfirmation, location.description),
      () => restoreLocation(location, successCallback, true),
    );
  }
  const restoreUrl = `${ROOT_URL}/location/${location.id}/restore`;
  const serverSuccessCallback = (dispatch) => {
    dispatch({
      type: RESTORE_LOCATION,
      payload: {
        location,
      },
    });
    successCallback();
  };
  return createServerAction(
    Post(restoreUrl),
    messages.formatString(messages.locationRestoreSucessMessage, location.description),
    messages.formatString(messages.locationRestoreErrorMessage, location.description),
    serverSuccessCallback,
  );
}
