import React, { Component } from 'react';
import applicationReleaseVersion from './actions/applicationReleaseVersion';

class ReleaseVersion extends Component {
  constructor(props) {
    super(props);

    this.render = this.render.bind(this);
    this.state = {
      appReleaseVersion: '',
    };

    applicationReleaseVersion().payload.then((response) => {
      this.setState({
        appReleaseVersion: response.data,
      });
    });
  }

  render() {
    return (
      <div>
        <b>D2M </b>
        <font className="app-version">{this.state.appReleaseVersion}</font>
      </div>
    );
  }
}
export default ReleaseVersion;
