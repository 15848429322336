import React, { Component } from 'react';
import Modal from 'react-bootstrap-modal';
import PropTypes from 'prop-types';
import { LabeledInputField, LabeledColorPicker, TrimbleButton } from './FormComponents';
import messages from '../messages';

class GenericFormModal extends Component {
  static renderBody() {
    return null;
  }

  constructor(props) {
    super(props);
    this.state = {
      formModal: Object.assign({}, this.props.formModal),
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ formModal: nextProps.formModal });
  }

  setFormModalField(field, value) {
    let { formModal } = this.state;
    formModal = Object.assign({}, formModal);
    formModal[field] = value;
    this.setState({ formModal });
  }

  setFormModalFields(fieldValues) {
    let { formModal } = this.state;
    formModal = Object.assign({}, formModal, fieldValues);
    this.setState({ formModal });
  }

  getFormModalField(field) {
    return this.state.formModal[field];
  }

  getFormModal() {
    return this.state.formModal;
  }

  handleFormModalSave() {
    if (this.props.saveFormModal) {
      this.props.saveFormModal(this.getFormModal(), () => this.saveSuccessCallback());
    } else {
      // assume it's a success scenario
      this.saveSuccessCallback();
    }
  }

  saveSuccessCallback() {
    this.props.handleHide();
    if (this.props.onAfterSave) {
      this.props.onAfterSave();
    }
  }

  renderInputField(fieldLabel, fieldName, maxLength = 100, extraParams, extraCss) {
    return (
      <LabeledInputField
        name={fieldName}
        label={fieldLabel}
        type="text"
        extraInputParams={{
          onChange: e => this.setFormModalField(fieldName, e.target.value),
          value: this.getFormModalField(fieldName),
          maxLength,
          ...extraParams,
        }}
        cssClasses={extraCss}
      />
    );
  }

  renderCheckboxField(fieldLabel, fieldName, extraParams, extraCss) {
    return (
      <LabeledInputField
        name={fieldName}
        label={fieldLabel}
        type="checkbox"
        extraInputParams={{
          onChange: e => this.setFormModalField(fieldName, e.target.checked),
          checked: this.getFormModalField(fieldName),
          ...extraParams,
        }}
        cssClasses={extraCss}
      />
    );
  }

  renderColorPicker(fieldLabel, fieldName, extraParams, extraCss) {
    return (
      <LabeledColorPicker
        name={fieldName}
        label={fieldLabel}
        extraParams={{
          onColorSelect: c => this.setFormModalField(fieldName, c),
          color: this.getFormModalField(fieldName),
          useHex: true,
          ...extraParams,
        }}
        cssClasses={extraCss}
      />
    );
  }

  render() {
    return (
      <Modal
        onHide={this.props.handleHide}
        show={this.props.show}
        backdrop="static"
        className="appModal"
      >
        <Modal.Header closeButton>
          <Modal.Title>{this.props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.renderBody()}
        </Modal.Body>
        <Modal.Footer>
          <div>
            <TrimbleButton label={messages.commonCancel} onClick={() => this.props.handleHide()} />
            <TrimbleButton label={messages.commonSave} onClick={() => this.handleFormModalSave()} />
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

GenericFormModal.propTypes = {
  formModal: PropTypes.shape({}),
  saveFormModal: PropTypes.func,
  handleHide: PropTypes.func,
  onAfterSave: PropTypes.func,
  show: PropTypes.bool,
  title: PropTypes.string,
};
GenericFormModal.defaultProps = {
  formModal: {},
  saveFormModal: null,
  handleHide: null,
  onAfterSave: null,
  show: false,
  title: '',
};

export default GenericFormModal;
