import _ from 'lodash';
import { SEARCH_FLEETS, SAVE_FLEET } from '../constants';

export default function searchFleets(state = { fleets: [] }, action) {
  if (action.error) {
    return state;
  }
  switch (action.type) {
    case SEARCH_FLEETS:
      return { fleets: action.payload.data, searchText: action.meta.searchText };
    case SAVE_FLEET: {
      const newFleets = _.concat([], state.fleets);
      const index = _.findIndex(newFleets, f => f.id === action.payload.savedObject.id);
      if (index > -1) {
        newFleets[index] = action.payload.savedObject;
      }
      return Object.assign({}, state, { fleets: newFleets });
    }
    default:
      return state;
  }
}
