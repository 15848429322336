import React from 'react';
import PropTypes from 'prop-types';


const Location = ({ location }) => {
  const locationUsed = location || {};
  return (
    <span
      className="locationBox"
      style={{
        backgroundColor: locationUsed.fillColour,
        color: locationUsed.textColour,
      }}
    >
      {locationUsed.description}
    </span>
  );
};

Location.propTypes = {
  location: PropTypes.shape({}),
};
Location.defaultProps = {
  location: {},
};

export default Location;
