import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import LocationModal from './locationModal';
import searchLocations, { searchDeleted, newSearch } from './../actions/searchLocations';
import simpleSearchFilter from '../../components/simpleSearchFilter';
import messages from '../../messages';

const ExtraSearchPanel = ({ showDeleted }) => (
  <span>
    <input
      id="show_deleted"
      type="checkbox"
      checked={showDeleted}
      onChange={e => searchDeleted(e.target.checked)}
    />
    <label htmlFor="show_deleted">{messages.commonShowDeleted}</label>
  </span>
);

ExtraSearchPanel.propTypes = {
  showDeleted: PropTypes.bool,
};

ExtraSearchPanel.defaultProps = {
  showDeleted: false,
};

function mapStateToProps(state) {
  return { extraSearchPanelshowDeleted: state.searchLocations.filter.showDeleted };
}

export default connect(
  mapStateToProps,
  {
    doSearch: searchLocations,
    extraSearchPanelsearchDeleted: searchDeleted,
    doNewSearch: newSearch,
  },
)(simpleSearchFilter(LocationModal, ExtraSearchPanel));
