import { connect } from 'react-redux';
import RestrictionModal from './restrictionModal';
import searchRestrictions from './../actions/searchRestrictions';
import simpleSearchFilter from '../../components/simpleSearchFilter';

export default connect(
  null,
  { doSearch: searchRestrictions },
)(simpleSearchFilter(RestrictionModal));

