import Alert from 'react-s-alert';
import { ALERT_TIMEOUT } from '../constants';

export function showWaringAlert(message) {
  Alert.warning(message, { timeout: ALERT_TIMEOUT, position: 'bottom-right' });
}

export function showSuccessAlert(message) {
  Alert.success(message, { timeout: ALERT_TIMEOUT, position: 'bottom-right' });
}

export function showErrorAlert(message) {
  Alert.error(message, { timeout: ALERT_TIMEOUT, position: 'bottom-right' });
}

export default showSuccessAlert;
