import { Post } from './ajax';
import { showConfirmation, showInformation, showWarning } from '../actions/YesCancel';

function createConfirmableAction(actionParams) {
  const {
    postURL, postData,
    confirmationMsg, confirmationActionCreator,
    successActionCreator, successMessage,
    invalidRequestMessage,
  } = actionParams;
  return (dispatch) => {
    Post(
      postURL,
      postData,
    ).then((result) => {
      const payload = result.data;
      if (payload.requestValid) {
        if (payload.confirmationRequired) {
          dispatch(showConfirmation(confirmationMsg, confirmationActionCreator, payload.messages));
        } else {
          dispatch(successActionCreator(payload));
          dispatch(showInformation(successMessage));
        }
      } else {
        dispatch(showWarning(invalidRequestMessage, payload.messages));
      }
    }).catch(error => console.log(error));
  };
}

export default createConfirmableAction;

export function createServerAction(
  severRequestPromise,
  successMessage,
  errorMessage,
  successCallback,
) {
  return (dispatch) => {
    severRequestPromise.then((response) => {
      if (response) {
        if (response.status === 200) {
          dispatch(showInformation(successMessage));
          if (successCallback) {
            successCallback(dispatch, response);
          }
          return;
        }
        dispatch(showWarning(errorMessage));
      }
    }).catch((error) => {
      if (error && error.globallyHandled) {
        return;
      }
      let msg = errorMessage;
      if (error.response && error.response.status === 400 && error.response.data) {
        msg = error.response.data;
      }
      dispatch(showWarning(msg));
    });
  };
}

export function createSaveAction(actionParams) {
  const {
    saveObject, validationFunction,
    validationFailedMsag, saveUrl,
    errorInSavingMsg, successfullySavedMsg,
    dispatchType, successCallback,
  } = actionParams;
  const errors = validationFunction(saveObject);
  if (errors.length > 0) {
    return showWarning(validationFailedMsag, errors);
  }
  const serverSuccessCallback = (dispatch, response) => {
    const updatedSaveObject = response.data;
    dispatch({
      type: dispatchType,
      payload: {
        savedObject: updatedSaveObject,
      },
    });
    if (successCallback) {
      successCallback();
    }
  };
  return createServerAction(
    Post(saveUrl, saveObject),
    successfullySavedMsg,
    errorInSavingMsg,
    serverSuccessCallback,
  );
}
