import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TrimbleButton } from './FormComponents';
import messages from '../messages';

/**
 * Renders a button to open a Modal.
 *
 * @param {*} Modal - Modal which needs to be opened on clicking the button.
 */
export default function modalOpenerButton(Modal) {
  const ModalOpenerButton = class ModalOpenerButton extends Component {
    constructor(props) {
      super(props);
      this.state = { showModal: false };
    }

    hideModal() {
      this.setState({ showModal: false });
    }

    showModal() {
      this.setState({ showModal: true });
    }

    render() {
      return (
        <span>
          <TrimbleButton
            label={this.props.btnLabel || messages.commonAdd}
            extraCss="searchPanelButton add"
            onClick={() => this.showModal()}
          />
          <Modal
            show={this.state.showModal}
            handleHide={() => this.hideModal()}
            {...this.props}
          />
        </span>
      );
    }
  };

  ModalOpenerButton.propTypes = {
    btnLabel: PropTypes.string,
  };
  ModalOpenerButton.defaultProps = {
    btnLabel: '',
  };

  return ModalOpenerButton;
}
