import { Post } from '../../utils/ajax';
import {
  ROOT_URL,
  CREATE_DISTANCE,
  UPDATE_DISTANCE,
  MIN_DISTANCE,
  MAX_DISTANCE,
} from '../constants';
import { showInformation, showWarning } from '../../actions/YesCancel';
import messages from '../../messages';

function validateMandatoryField(field, name, errors) {
  if (!field && field !== 0) {
    errors.push(messages.formatString(messages.commonValidationFieldMandatory, name));
  }
}

function validateLocationDistance(fromLocation, toLocation, viaLocation, viaTmp, value) {
  const errors = [];
  validateMandatoryField(fromLocation, messages.commonFrom, errors);
  validateMandatoryField(toLocation, messages.commonTo, errors);

  if (viaTmp && viaTmp.length > 0 && viaLocation !== viaTmp) {
    validateMandatoryField(viaLocation, messages.commonVia, errors);
  }

  validateMandatoryField(value, messages.commonDistance, errors);

  if (value) {
    if (!/^-?[0-9]\d*(\.\d+)?$/.test(value)) {
      errors.push(messages.formatString(
        messages.commonValidationFieldNumeric,
        messages.commonDistance,
      ));
    } else {
      const distanceValue = Number.parseFloat(value);
      if (distanceValue < MIN_DISTANCE) {
        errors.push(messages.formatString(
          messages.commonValidationFieldMin,
          messages.commonDistance,
          MIN_DISTANCE,
        ));
      } else if (distanceValue > MAX_DISTANCE) {
        errors.push(messages.formatString(
          messages.commonValidationFieldMax,
          messages.commonDistance,
          MAX_DISTANCE,
        ));
      }
    }
  }
  return errors;
}

export function distanceSave(
  fromLocation,
  toLocation,
  viaLocation,
  viaTmp,
  value,
  successCallback,
) {
  return (dispatch) => {
    const errors = validateLocationDistance(fromLocation, toLocation, viaLocation, viaTmp, value);
    if (errors.length > 0) {
      dispatch(showWarning(messages.locationDistanceSaveOrUpdateValidationFailedMessage, errors));
      return;
    }
    Post(
      `${ROOT_URL}/distance/create`,
      {
        fromLocation,
        toLocation,
        viaLocation,
        value,
      },
      { validateStatus: status => status === 200 || status === 400 },
    ).then((response) => {
      let msg = messages.locationDistanceSaveFailureMessage;
      if (response) {
        if (response.status === 200) {
          dispatch(showInformation(messages.locationDistanceSaveSuccessMessage));
          dispatch({
            type: CREATE_DISTANCE,
            payload: {
            },
          });
          if (successCallback) {
            successCallback();
          }
          return;
        } else if (response.status === 400 && response.data) {
          msg = response.data;
        }
      }
      dispatch(showWarning(msg));
    }).catch(error => console.log(error));
  };
}

export function distanceUpdate(
  id,
  fromLocation,
  toLocation,
  viaLocation,
  viaTmp,
  value,
  successCallback,
) {
  return (dispatch) => {
    const errors = validateLocationDistance(fromLocation, toLocation, viaLocation, viaTmp, value);
    if (errors.length > 0) {
      dispatch(showWarning(messages.locationDistanceSaveOrUpdateValidationFailedMessage, errors));
      return;
    }
    Post(
      `${ROOT_URL}/distance/update`,
      {
        id,
        fromLocation,
        toLocation,
        viaLocation,
        value,
      },
      { validateStatus: status => status === 200 || status === 400 },
    ).then((response) => {
      let msg = messages.locationDistanceUpdateFailureMessage;
      if (response) {
        if (response.status === 200) {
          dispatch(showInformation(messages.locationDistanceUpdateSuccessMessage));
          dispatch({
            type: UPDATE_DISTANCE,
            payload: {
            },
          });
          if (successCallback) {
            successCallback();
          }
          return;
        } else if (response.status === 400 && response.data) {
          msg = response.data;
        }
      }
      dispatch(showWarning(msg));
    }).catch(error => console.log(error));
  };
}
