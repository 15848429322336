import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types';
import ChangeReasonRow from './changeReasonRow';
import { CHANGE_REASON_SCREEN_EDIT } from '../../kc_roles';
import { hasLicence } from '../../components/licenced_components';
import messages from '../../messages';
import '../changeReason.css';

class ChangeReasonTable extends Component {
  constructor(props) {
    super(props);
    this.render = this.render.bind(this);
  }

  renderItem() {
    return _.map(this.props.changeReason, item => (
      <ChangeReasonRow
        key={item.id}
        item={item}
      />

    ));
  }

  render() {
    return (
      <div>
        <table id={hasLicence(CHANGE_REASON_SCREEN_EDIT) ? 'clickable' : ''} className="compactFirstColumn table text-nowrap reasonHead">
          <thead>
            <tr>
              <th>{messages.commonCode}</th>
              <th>{messages.commonDescription}</th>
            </tr>
          </thead>
          <tbody>
            {this.renderItem()}
          </tbody>
        </table>
      </div>
    );
  }
}

ChangeReasonTable.propTypes = {
  changeReason: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })),
};
ChangeReasonTable.defaultProps = {
  changeReason: [],
};

function mapStateToProps({ changeReason }) {
  return { changeReason };
}

export default connect(mapStateToProps)(ChangeReasonTable);
