import React from 'react';
import FleetTable from './components/fleetTable';
import FilterPanel from './components/filterPanel';
import { FLEETS_SCREEN_EDIT } from '../kc_roles';
import messages from '../messages';

const Fleets = () => (
  <div>
    <div className="col-xs-12 filtersColumn">
      <div className="table-responsive">
        <FilterPanel
          addPopuptitle={messages.fleetAddPopupTitle}
          addPopupformModal={{ }}
          editLicence={FLEETS_SCREEN_EDIT}
        />
      </div>
    </div>
    <div className="col-xs-12 location">
      <div className="table-responsive">
        <FleetTable
          editPopuptitle={messages.fleetEditPopupTitle}
          editLicence={FLEETS_SCREEN_EDIT}
          extraTableCss="compactFirstColumn"
        />
      </div>
    </div>
  </div>
);

export default Fleets;
