import { YES_CANCEL_MODAL } from '../constants';

export default function yesCancelModalDataReducer(state = {}, action) {
  if (action.error) {
    return state;
  }
  switch (action.type) {
    case YES_CANCEL_MODAL:
      return action.payload;
    default:
      return state;
  }
}
