import { ROOT_URL, ADD_NOTE } from '../constants';
import { Post } from '../../utils/ajax';
import { showInformation } from '../../actions/YesCancel';
import messages from '../../messages';

export default function addAssetNote(asset, note, checked) {
  let availableAssetToSave = Object.assign({}, asset);
  let assetId = null;
  if (asset.id) {
    availableAssetToSave = null;
    assetId = asset.id;
  }
  const response = (dispatch) => {
    Post(`${ROOT_URL}/note/addNote`, {
      availableAsset: availableAssetToSave,
      assetId,
      note,
      checked,
    }).then((result) => {
      if (result.status === 200 && result.statusText === 'OK') {
        if (note === '') {
          dispatch(showInformation(messages.allocationUnitNoteClearedSuccessfully));
        } else {
          dispatch(showInformation(messages.allocationUnitNoteSavedSuccessfully));
        }
      }
      dispatch({
        type: ADD_NOTE,
        payload: {
          asset,
          note,
          checked,
        },
      });
    }).catch(error => console.log(error));
  };
  return response;
}
