import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { hasLicence } from './licenced_components';

/**
 * Meant to be used to create a RowComponent to be passed to 'searchResultTable'.
 * Renders a <tr>.
 *
 * @param columnConfigs - array of objects containing following column configurations.
 *  - propertyName : property of the 'rowObject' to be shown as column value.
 *  - checkEditLicence : whether to check edit licence in rendering the column
 * @param GenericFormEditModal - Popup Modal which is a subclass of GenericFormModal for
 *  editing the record shown in table row.
 * @param EditModalStaticProps - static properties to be passed to Popup Modal represented
 *  by 'GenericFormEditModal'.
 *
 */
export default function searchResultTableRow(
  columnConfigs = [],
  GenericFormEditModal,
  EditModalStaticProps,
) {
  const SearchResultTableRow = class SearchResultTableRow extends Component {
    static renderColumnValue(config, index, rowObject) {
      return rowObject[config.propertyName];
    }

    constructor(props) {
      super(props);
      this.state = { showEditModal: false };
    }

    onAfterEdit() {
      if (this.props.onAfterEdit) {
        this.props.onAfterEdit();
      }
    }

    getRowStyle() {
      return this.props.rowStyle || {};
    }

    getRowClassName() {
      return this.props.rowClassName || '';
    }

    getColumnClassName() {
      return this.isEditable() ? 'clickableColumn' : '';
    }

    getEditModalProps() {
      let result = {};
      Object.keys(this.props).forEach((key) => {
        if (key.startsWith('editPopup')) {
          result[key.substring(9)] = this.props[key];
        }
      });
      if (EditModalStaticProps) {
        result = Object.assign(result, EditModalStaticProps);
      }
      return result;
    }

    hasEditLicence() {
      return !this.props.editLicence || hasLicence(this.props.editLicence);
    }

    isEditable() {
      return this.hasEditLicence();
    }

    toggleModal() {
      this.setState({ showEditModal: !this.state.showEditModal });
    }

    renderColumns() {
      const { rowObject } = this.props;
      const isLicenced = this.hasEditLicence();
      return columnConfigs
        .filter(config => !config.checkEditLicence || isLicenced)
        .map((config, index) => this.renderColumn(config, index, rowObject));
    }

    renderColumn(config, index, rowObject) {
      return (
        <td
          key={index}
          role="gridcell"
          onClick={() => this.toggleModal()}
          onKeyDown={() => this.toggleModal()}
          className={this.getColumnClassName()}
        >
          {SearchResultTableRow.renderColumnValue(config, index, rowObject)}
        </td>
      );
    }

    renderEditModal() {
      if (GenericFormEditModal && this.isEditable()) {
        return (
          <GenericFormEditModal
            show={this.state.showEditModal}
            handleHide={() => this.toggleModal()}
            formModal={Object.assign({}, this.props.rowObject)}
            onAfterSave={() => this.onAfterEdit()}
            {...this.getEditModalProps()}
          />
        );
      }
      return '';
    }

    render() {
      return (
        <tr className={this.getRowClassName()} style={this.getRowStyle()}>
          {this.renderColumns()}
          {this.renderEditModal()}
        </tr>
      );
    }
  };

  SearchResultTableRow.propTypes = {
    onAfterEdit: PropTypes.func,
    rowStyle: PropTypes.shape({}),
    rowClassName: PropTypes.string,
    editLicence: PropTypes.string,
    rowObject: PropTypes.shape({}),
  };
  SearchResultTableRow.defaultProps = {
    onAfterEdit: null,
    rowStyle: {},
    rowClassName: '',
    editLicence: null,
    rowObject: null,
  };
  return SearchResultTableRow;
}
