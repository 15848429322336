import _ from 'lodash';
import {
  FETCH_ASSET,
  ALLOCATE_ASSET,
  DEALLOCATE_ASSET,
  REALLOCATE_ASSET,
  ASSIGN_RESTRICTIONS,
  ADD_NOTE,
} from '../constants';

function updateUnits(state, updateUnit) {
  state.assets.forEach(updateUnit);
  const newState = Object.assign({}, state);
  newState.assets = _.concat([], state.assets);
  return newState;
}

export default function fetchAsset(state = { assets: [], filter: {} }, action) {
  if (action.error) {
    return state;
  }
  switch (action.type) {
    case FETCH_ASSET:
      return {
        assets: action.payload.data.assets,
        filter: {
          onlyAvailable: action.meta.onlyAvailable,
          searchText: action.meta.asset,
          sort: action.meta.sort,
        },
        outdated: action.payload.data.outdated,
      };
    case ALLOCATE_ASSET:
    case DEALLOCATE_ASSET:
    case REALLOCATE_ASSET: {
      const { updatedAsset, allocatedNow } = action.payload;
      const updateUnit = (unitIn) => {
        const unit = unitIn;
        if (updatedAsset.externalId === unit.externalId) {
          unit.displayEodFuel = updatedAsset.displayEodFuel;
          unit.sodLocation = updatedAsset.sodLocation;
          unit.eodLocation = updatedAsset.eodLocation;
        }
      };
      const updatedState = updateUnits(state, updateUnit);
      if ((ALLOCATE_ASSET === action.type || REALLOCATE_ASSET === action.type)
        && state.filter && state.filter.onlyAvailable && allocatedNow) {
        const newState = Object.assign({}, updatedState);
        newState.assets = _.remove(
          updatedState.assets,
          a => a.externalId !== action.payload.asset.externalId,
        );
        return newState;
      }
      return updatedState;
    }
    case ASSIGN_RESTRICTIONS: {
      const { restrictions, asset } = action.payload;
      const updateUnit = (unitIn) => {
        const unit = unitIn;
        if (asset.externalId === unit.externalId) {
          unit.restrictions = restrictions;
        }
      };
      return updateUnits(state, updateUnit);
    }
    case ADD_NOTE: {
      const { asset, note, checked } = action.payload;
      const updateUnit = (unitIn) => {
        if (asset.externalId === unitIn.externalId) {
          const unit = unitIn;
          unit.noteDescription = note;
          unit.checked = checked;
        }
      };
      return updateUnits(state, updateUnit);
    }
    default:
      return state;
  }
}
