import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap-modal';
import PropTypes from 'prop-types';
import 'react-bootstrap-modal/lib/css/rbm-patch.css';
import { hideYesCancel } from '../actions/YesCancel';
import { TrimbleButton } from '../components/FormComponents';
import messages from '../messages';

class YesCancelModal extends Component {
  onCancel() {
    this.props.dispatch(this.props.onCancelActionCreator());
  }

  onOk() {
    this.onCancel();
    if (this.props.onOkActionCreator) {
      const actionArg = this.props.onOkActionCreator();
      if ((typeof actionArg === 'object' && actionArg.type)
        || typeof actionArg === 'function') {
        this.props.dispatch(actionArg);
      }
    }
  }

  createSubMessages() {
    if (this.props.subMessages) {
      return (
        <ul>
          {this.props.subMessages.map(msg => (<li key={msg}>{msg}</li>))}
        </ul>
      );
    }
    return '';
  }

  renderCancelButton() {
    if (this.props.showCancel) {
      return (
        <Modal.Dismiss className="btn trimbleStyleButton">{messages.commonCancel}</Modal.Dismiss>
      );
    }
    return '';
  }

  renderYesButton() {
    const label = this.props.yesLabel || messages.commonYes;
    return (
      <TrimbleButton label={label} onClick={() => this.onOk()} />
    );
  }

  render() {
    return (
      <div className="modal-container">
        <Modal show={this.props.show} onHide={() => this.onCancel()} backdrop="static" className="appModal">
          <Modal.Header closeButton>
            <Modal.Title id="ModalHeader">{this.props.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.props.message}{this.createSubMessages()}
          </Modal.Body>
          <Modal.Footer>
            {this.renderCancelButton()}
            {this.renderYesButton()}
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

YesCancelModal.propTypes = {
  dispatch: PropTypes.func,
  onCancelActionCreator: PropTypes.func,
  onOkActionCreator: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({})]),
  subMessages: PropTypes.arrayOf(PropTypes.string),
  showCancel: PropTypes.bool,
  yesLabel: PropTypes.string,
  show: PropTypes.bool,
  title: PropTypes.string,
  message: PropTypes.string,
};
YesCancelModal.defaultProps = {
  dispatch: null,
  onCancelActionCreator: null,
  onOkActionCreator: null,
  subMessages: [],
  showCancel: false,
  yesLabel: '',
  show: false,
  title: '',
  message: '',
};

function mapStateToProps(state) {
  return Object.assign({ onCancelActionCreator: hideYesCancel }, state.yesCancelModalData);
}

export default connect(mapStateToProps)(YesCancelModal);
