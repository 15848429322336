import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'react-bootstrap-modal/lib/css/rbm-patch.css';
import ReasonModal from './reasonModal';
import { CHANGE_REASON_SCREEN_EDIT } from '../../kc_roles';
import { hasLicence } from '../../components/licenced_components';
import '../changeReason.css';

class ChangeReasonRow extends Component {
  constructor(props) {
    super(props);

    this.handleHide = this.handleHide.bind(this);
    this.handleClick = this.handleClick.bind(this);

    this.state = {
      show: false,
    };
  }

  handleClick() {
    if (!this.state.show) {
      this.setState({ show: true });
    } else {
      this.handleHide();
    }
  }

  handleHide() {
    this.setState({ show: !this.state.show });
  }

  render() {
    const { item } = this.props;
    return (
      <tr key={item.id} id={item.id} className="changeReason">
        {hasLicence(CHANGE_REASON_SCREEN_EDIT) && (
          <ReasonModal
            item={item}
            show={this.state.show}
            handleHide={this.handleHide}
          />
        )}
        <td onClick={this.handleClick} onKeyDown={this.handleClick} role="gridcell"> {item.code} </td>
        <td onClick={this.handleClick} onKeyDown={this.handleClick} role="gridcell"> {item.description}</td>
      </tr>
    );
  }
}

ChangeReasonRow.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
    code: PropTypes.string,
    description: PropTypes.string,
  }),
};
ChangeReasonRow.defaultProps = {
  item: {},
};

export default ChangeReasonRow;
